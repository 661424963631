import omit from 'lodash/omit';
import { toast } from 'react-toastify';

import TabWrapper from 'components/TabWrapper';
import SearchInput from 'components/search/SearchInput';
import SearchResults from 'components/search/SearchResults';

import TrackerTitle from './TrackerTitle';

import { IUserFood } from 'shared/src/types';
import { useLogFood, useUserFood } from 'shared/src/store';

import useFilterList from 'hooks/useFilterList';
import useModal, { ModalType } from 'hooks/useModal';

import { CUSTOM_LIMITS } from 'constants/searchTabs';
import { TRACKER_TITLES, TABLE_THEAD_SEARCH } from 'constants/index';

const TreckerSearchCustomFoods = () => {
  const { setCachedFood } = useLogFood();
  const { show: showModal } = useModal();
  const {
    data,
    pageInfo,
    removeById,
    onEndReached,
    isFetchingMore,
  } = useUserFood(true, CUSTOM_LIMITS);
  const { setFilter, filteredData } = useFilterList<IUserFood>(data);

  const favorites = filteredData.map(({ name, netCarbs }) => ({
    label: name,
    extra: `${(netCarbs ?? 0).toFixed(2)} g`,
  }));

  const handleClickItem = (index: number) => {
    setCachedFood({
      food: {
        ...omit(filteredData[index], ['id', 'objectId']),
        userFoodId: filteredData[index].objectId,
      },
    });
    showModal(ModalType.trackFood);
  };

  const handleClickEdit = (index: number) => {
    const food = filteredData[index];
    showModal(ModalType.newEntry, { food });
  };

  const handleClickRemove = (index: number) => {
    const food = filteredData[index];

    try {
      removeById(food.id);
      toast.success('Favorite removed successfully');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <TabWrapper>
      <TrackerTitle values={TRACKER_TITLES.FOOD} />

      <SearchInput onSearch={setFilter} type="customFoods" />
      <div className="h-400px">
        <SearchResults
          show={true}
          editButton
          removeButton
          data={favorites}
          onClickMore={onEndReached}
          headers={TABLE_THEAD_SEARCH}
          onClickItem={handleClickItem}
          onClickEdit={handleClickEdit}
          isFetchingMore={isFetchingMore}
          onClickRemove={handleClickRemove}
          showLoadMore={pageInfo.hasNextPage}
        />
      </div>
    </TabWrapper>
  );
};

export default TreckerSearchCustomFoods;
