export default {
  age: {
    minimum: 18,
    maximum: 89,
  },
  feet: {
    minimum: 1,
    maximum: 9,
  },
  inches: {
    minimum: 0,
    maximum: 11,
  },
  weight: {
    minimum: 90,
    maximum: 499,
  },
  height: {
    minimum: 4,
    maximum: 7,
  },
  desiredWeightLost: {
    minimum: 2,
    maximum: 330,
  },
  waistCircumference: {
    minimum: 0,
    maximum: 500,
  },
};
