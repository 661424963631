/* eslint-disable eqeqeq */
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import differenceWith from 'lodash/differenceWith';
import { ApolloCache, DocumentNode, FetchResult } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

import { IObject, TAny } from '../types';
import { areEqual } from './ObjectHelper';

const createLink = (model: TAny[]) => {
  const toAdd: IObject[] = [];
  model.forEach((item) => {
    const obj: IObject = {};
    Object.keys(item).forEach((key) => {
      const value = item[key];
      if (
        !isEmpty(value) ||
        typeof value === 'number' ||
        typeof value === 'boolean'
      ) {
        const isLink = value.id;
        obj[key] = isLink ? { link: value.id } : value;
      }
    });
    toAdd.push(obj);
  });

  return toAdd;
};

const applyAddRemove = (
  key: string,
  cModel: TAny,
  baseModel: TAny,
  isRelational?: boolean,
  sanitizeGraphql?: boolean
) => {
  let toCreate: TAny[] = [];
  const toAdd: string[] = [];
  const toRemove: string[] = [];
  const addDeleteObj: {
    add?: string[];
    remove?: string[];
    createAndAdd?: TAny[];
  } = {};

  if (!isEmpty(baseModel)) {
    const baseValues = sanitizeGraphql
      ? sanitizeGraphqlValues(baseModel[key])
      : baseModel[key];
    const missingDel = differenceWith(
      baseValues,
      cModel[key],
      areEqual
    ) as TAny[];

    const missingAdd = differenceWith(
      cModel[key],
      baseValues,
      areEqual
    ) as TAny[];

    missingDel.forEach(({ id }: { id: string }) => toRemove.push(id));

    if (isRelational) {
      if (missingAdd.length) {
        toCreate = createLink(missingAdd);
      }
    } else {
      missingAdd.forEach(({ id }: { id: string }) => toAdd.push(id));
    }
  } else {
    if (isRelational) {
      toCreate = createLink(cModel[key]);
    } else {
      cModel[key].forEach(({ id }: { id: string }) => toAdd.push(id));
    }
  }

  if (toAdd.length) {
    addDeleteObj.add = toAdd;
  }

  if (toCreate.length) {
    addDeleteObj.createAndAdd = toCreate;
  }

  if (toRemove.length) {
    addDeleteObj.remove = toRemove;
  }

  if (!toAdd.length && !toRemove.length && !toCreate.length) {
    delete cModel[key];
  } else {
    cModel[key] = addDeleteObj;
  }
};

export const sanitizeGraphqlValues = (value: TAny) => {
  let transformedValue = value;
  const url = get(value, `url`, null);
  const name = get(value, `name`, null);
  const edges = get(value, `edges`, null);

  if (edges) {
    transformedValue = edges.map((edge: { node: IObject }) => edge.node);
  }

  if (url && name) {
    transformedValue = [value];
  }

  return transformedValue;
};

export const convertToGraphqlModel = async (
  model: TAny,
  baseModel?: TAny,
  relations: string[] = [],
  { sanitizeGraphql } = { sanitizeGraphql: true }
) => {
  const cModel = cloneDeep(model);
  Object.keys(cModel).forEach((key: string) => {
    const field = cModel[key];

    //LINK
    if (field.id) {
      cModel[key] = { link: field.id };
    }

    const isArray = Array.isArray(field);
    if (isArray) {
      const first = get(field, '[0]', {});
      const isRelational = relations.indexOf(key) !== -1;

      if (first.url && first.name) {
        if (first instanceof File) {
          cModel[key] = { upload: first };
        } else {
          delete cModel[key];
        }
      } else {
        applyAddRemove(key, cModel, baseModel, isRelational, sanitizeGraphql);
      }
    }
  });

  return cModel;
};

export const firstLimitPagination = (keyArgs: TAny) => {
  if (!keyArgs) {
    keyArgs = false;
  }
  return {
    keyArgs,
    read: (existing: IObject, { args }: IObject) => {
      if (!existing) {
        return;
      }
      const nExisting = cloneDeep(existing);
      if (args.first != null) {
        nExisting.edges.length = args.first;
      }

      return nExisting;
    },
  };
};

export const limitRelayPagination = (keyArgs?: TAny) => {
  if (!keyArgs) {
    keyArgs = false;
  }
  return {
    keyArgs,
    merge: function (existing: TAny, incoming: TAny, _a: TAny) {
      const args = _a.args;
      let merged =
        existing && existing.edges
          ? cloneDeep(existing)
          : {
              edges: [],
              count: 0,
              pageInfo: {
                endCursor: '',
                hasNextPage: false,
                hasPreviousPage: false,
                startCursor: '',
              },
            };

      if (args) {
        const offset = args.skip || 0;

        if (!offset) {
          merged = incoming;
          return merged;
        }

        for (let i = 0; i < incoming.edges.length; ++i) {
          merged.edges[offset + i] = incoming.edges[i];
          merged.count++;
        }

        merged.pageInfo = incoming.pageInfo;
      } else {
        merged.edges = [...merged.edges, ...incoming.edges];
        merged.count += incoming.count;
        merged.pageInfo = incoming.pageInfo;
      }

      return merged;
    },
  };
};

export const getTypePolicies: TAny = () => {
  if (process.env.REACT_APP_USE_PAGINATION === 'true') {
    return {
      Query: {
        fields: {
          foods: firstLimitPagination(['skip', 'where']),
          recipes: firstLimitPagination(['skip', 'where']),
          userFoodFavorites: firstLimitPagination(['skip', 'where']),
        },
      },
    };
  }

  return {
    Query: {
      fields: {
        atlasFoods: limitRelayPagination(),
        atlasRecipes: limitRelayPagination(),
        foods: relayStylePagination(['where']),
        recipes: relayStylePagination(['where']),
        userFoods: relayStylePagination(['where']),
        userRecipes: relayStylePagination(['where']),
        userFoodFavorites: relayStylePagination(['where']),
        userFoodGroupings: relayStylePagination(['where']),
      },
    },
  };
};

export const updateCache = (query: DocumentNode, variables: IObject = {}) => (
  cache: ApolloCache<TAny>,
  { data }: FetchResult<TAny, Record<string, TAny>, Record<string, TAny>>
) => {
  const actionName = Object.keys(data)[0];
  const objectName = Object.keys(data[actionName])[0];
  const isCreate = actionName.indexOf('create') === 0;
  const isUpdate = actionName.indexOf('update') === 0;
  const isDelete = actionName.indexOf('delete') === 0;

  const item = get(data, `${actionName}.${objectName}`);

  const readedCache = cache.readQuery<IObject>({
    query,
    variables,
  });

  if (!readedCache || !Object.keys(readedCache).length) {
    return;
  }

  const cachedName = Object.keys(readedCache).filter((name) =>
    name.includes(objectName)
  )[0];

  let countSumValue = 0;
  const newData = cloneDeep(readedCache);

  const cachedCount = get(newData, `[${cachedName}].count`);
  const cachedPageInfo = get(newData, `[${cachedName}].pageInfo`);

  if (newData && newData[cachedName] && newData[cachedName].edges) {
    if (isCreate) {
      countSumValue = 1;
      newData[cachedName].edges.push({ node: item });
    } else if (isUpdate) {
      const index = newData[cachedName].edges.findIndex(
        ({ node }: { node: { id: string } }) => node.id === item.id
      );

      if (index !== -1) {
        newData[cachedName].edges[index] = { node: item };
      }
    } else if (isDelete) {
      countSumValue = -1;
      newData[cachedName].edges = newData[cachedName].edges.filter(
        ({ node }: { node: { id: string } }) => node.id !== item.id
      );
    }
  }

  if (cachedCount) {
    newData[cachedName].count = cachedCount + countSumValue;
  }

  if (cachedPageInfo) {
    newData[cachedName].pageInfo = {
      ...cachedPageInfo,
    };
  }

  cache.writeQuery({
    query,
    variables,
    data: newData,
  });
};
