import React from 'react';
import { TAny } from '../types';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import {
  concat,
  ApolloLink,
  Observable,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';

import Storage from '../utils/Storage';
import { getTypePolicies } from '../utils/GraphqlHelper';

const promiseToObservable = (promise: Promise<void>) =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: getTypePolicies(),
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL || 'https://api.atkins.com/graphql',
});

const authMiddleware = setContext(async () => {
  const token = await Storage.getItem('@user_token');
  return {
    headers: {
      'X-Parse-Application-Id':
        process.env.REACT_APP_PARSE_APP_ID || 'atkins-api',
      'X-Parse-Session-Token': token || '',
    },
  };
});

const onRefreshToken = async () => {
  const token = await Storage.getItem('@user_token');
  if (token) {
    Storage.removeItem('@user_token');
  }
};

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
          if (message.indexOf('Invalid session token') !== -1) {
            return promiseToObservable(onRefreshToken()).flatMap(() =>
              forward(operation)
            );
          }
          console.warn(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
          return null;
        });
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    concat(authMiddleware, httpLink as TAny),
  ]),
  cache,
  connectToDevTools: true,
});

interface IAuthorizedApolloProviderProps {
  children: React.ReactNode;
}

const AuthorizedApolloProvider = ({
  children,
}: IAuthorizedApolloProviderProps) => (
  <ApolloProvider client={client}>
    <>{children}</>
  </ApolloProvider>
);

export default AuthorizedApolloProvider;
