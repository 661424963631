import { gql } from '@apollo/client';
import { SERVING_CONVERSIONS_SCHEMA } from './food';

export const USER_RECIPE = `
  id
  fat
  name
  fiber
  userId
  protein
  objectId
  netCarbs
  calories
  servings @include(if: $detailed)
  directions @include(if: $detailed)
  servingSize @include(if: $detailed)
  servingUnit @include(if: $detailed)
  totalFat @include(if: $detailed)
  createdAt @include(if: $detailed)
  image {
    url
    name
  }
  foods @include(if: $detailed) {
    edges {
      node {
        id
        fat
        name
        fiber
        order
        userId
        protein
        recipeId
        netCarbs
        calories
        servingSize
        servingUnit
        brandName
        food {
          id
          ${SERVING_CONVERSIONS_SCHEMA}
        }         
      }
    }
  }
`;

export const GET_USER_RECIPES_SCHEMA = `
  userRecipes(
    skip: $skip
    last: $last
    first: $first
    after: $after
    order: $order
    where: $where
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    count
    edges {
      node {
        ${USER_RECIPE}
      }
    }
  }
`;

export const GET_USER_RECIPES = gql`
  query userRecipes(
    $skip: Int
    $last: Int
    $first: Int
    $after: String
    $before: String
    $order: [UserRecipeOrder!]
    $where: UserRecipeWhereInput
    $detailed: Boolean = true
    ) {
    ${GET_USER_RECIPES_SCHEMA}
  }
`;

export const CREATE_USER_RECIPE = gql`
  mutation createUserRecipe(
    $detailed: Boolean = true
    $fields: CreateUserRecipeFieldsInput!
  ) {
    createUserRecipe(input: { fields: $fields }) {
      userRecipe {
        ${USER_RECIPE}
      }
    }
  }
`;

export const UPDATE_USER_RECIPE = gql`
  mutation updateUserFood(
    $id: ID!
    $detailed: Boolean = true
    $fields: UpdateUserRecipeFieldsInput!
  ) {
    updateUserRecipe(input: { id: $id, fields: $fields }) {
      userRecipe {
        ${USER_RECIPE}
      }
    }
  }
`;

export const DELETE_USER_RECIPE = gql`
  mutation($id: ID!) {
    deleteUserRecipe(input: { id: $id }) {
      userRecipe {
        id
      }
    }
  }
`;
