import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';

import Card from 'components/ui/Card';
import CardTitle from 'components/ui/CardTitle';
import CardTextfield from 'components/ui/CardTextfield';

import useWeight from 'hooks/useWeight';

import RulerImg from 'assets/icons/ruler.svg';

const myWeight = () => {
  const history = useHistory();
  const {
    gainOrLoseText,
    totalPoundsLost,
    lastWeightInDate,
    lastWeightInValue,
  } = useWeight();

  const handleTrackWeight = () => {
    history.push('tracker/weight');
  };

  const handleTrackMeasurements = () => {
    history.push('tracker/measurements');
  };

  return (
    <Card styles="h-100" innerStyles="d-flex flex-column">
      <CardTitle
        size="small"
        icon={RulerImg}
        title="My weight and measurements"
        subtitle="Weight and measurements."
      />
      <CardTextfield
        title={totalPoundsLost}
        subtitle={gainOrLoseText}
        containerStyles="mb-8 justify-content-center"
      />
      <div className="d-flex flex-column font-weight-bold mb-10">
        <span className="text-muted">Last Weigh-in</span>
        <span className="font-weight-boldest font-size-sm">
          {lastWeightInValue} lbs
        </span>
        <span>{lastWeightInDate}</span>
      </div>
      <Button className="btn btn-block mt-auto" onClick={handleTrackWeight}>
        Track Weight
      </Button>
      <Button
        className="btn btn-block mt-auto"
        onClick={handleTrackMeasurements}>
        Track Measurements
      </Button>
    </Card>
  );
};

export default myWeight;
