import { Link } from 'react-router-dom';

import Button from 'shared/src/components/web/Button';

type TFunc = () => void;
export interface ICardTitleProp {
  icon: string;
  title: string;
  subtitle?: string;

  size?: 'regular' | 'small';
  button?: {
    text: string;
    working?: boolean;
    link: string | TFunc;
    textWorking?: string;
  };
}

const CardTitle = ({
  icon,
  title,
  button,
  subtitle,
  size = 'regular',
}: ICardTitleProp) => {
  const isLinkFunction = typeof button?.link === 'function';

  return (
    <div className="row mb-6">
      <div className={`${button ? 'col-12 col-md-8 col-lg-9' : 'col-12'}`}>
        <div className="row">
          <div className="col-auto">
            <img src={icon} />
          </div>
          <div className="col px-2 d-flex flex-column justify-content-center">
            <h3
              className={`${
                size === 'regular' ? 'h1' : 'font-size-sm'
              } text-primary m-0`}>
              {title}
            </h3>
            {subtitle && (
              <p
                className={`${
                  size === 'regular' ? 'font-size-sm' : 'h5'
                } font-weight-bold text-muted m-0`}>
                {subtitle}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="col-12 col-md-4 col-lg-3 text-right">
        {button && !isLinkFunction && (
          <Link
            className="btn btn-primary px-4 mb-4"
            to={button?.link as string}>
            {button?.text}
          </Link>
        )}
        {button && isLinkFunction && (
          <Button
            text={button?.text}
            textWorking={button?.textWorking}
            working={button?.working}
            onClick={button?.link as TFunc}
          />
        )}
      </div>
    </div>
  );
};

export default CardTitle;
