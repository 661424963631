import { useEffect } from 'react';

import { startOfDay } from 'date-fns';
import DatePicker from 'react-datepicker';

import { connectField, HTMLFieldProps } from 'uniforms';

const today = startOfDay(new Date());

const DateField = ({
  label,
  value,
  required,
  error,
  onChange,
}: HTMLFieldProps<string, HTMLInputElement>) => {
  const dateValue = value ? new Date(value) : today;

  useEffect(() => {
    if (!value || typeof value === 'object') {
      onChange && onChange(today.toISOString());
    }
  }, [value]);

  const handleChange = (date: Date) => {
    onChange && onChange(startOfDay(date).toISOString());
  };

  return (
    <div className={`form-group ${required ? 'required' : ''}`}>
      {label && (
        <label className={`${error ? 'text-danger' : ''}`}>{label}</label>
      )}
      <DatePicker
        selected={dateValue}
        onChange={handleChange}
        className="form-control"
        customInput={<input type="text" />}
      />

      {error && <span className="form-text text-danger">{error.message}</span>}
    </div>
  );
};

export default connectField(DateField);
