import { AutoForm } from 'uniforms-bootstrap4';
import { useQuery } from '@apollo/client';
import { AutoField } from 'uniforms-bootstrap4';

import useUrlParams from 'hooks/useUrlParams';

import useForm from 'shared/src/hooks/useForm';
import { useAssessment } from 'shared/src/store';
import LoadingIndicator from 'shared/src/components/web/LoadingIndicator';

import { SSignup } from 'shared/src/forms/schemas';

import { ISignupSchema } from 'shared/src/types';
import Submit from 'shared/src/forms/fields/web/Submit';

import { ISfmcSubscriber } from 'types';

import { GET_SFMC_QUERY } from 'graphql/sfmcSubscriber';

type TQuery = {
  sfmcSubscriber: ISfmcSubscriber;
};

const AssessmentPersonalInformation = () => {
  const subscriberKey = useUrlParams('Subscriber-Key');
  const { update, next, userData } = useAssessment();

  const {
    data: { sfmcSubscriber: { emailAddress: email } } = {
      sfmcSubscriber: {},
    },
    loading,
  } = useQuery<TQuery>(GET_SFMC_QUERY, {
    skip: !subscriberKey,
    variables: {
      subscriberKey,
      event: 'getsubscriber',
    },
  });

  const { schema } = useForm(SSignup, {
    ...(email && { email }),
    ...userData,
  });

  const handleSubmit = async (values: Partial<ISignupSchema>) => {
    update('userData', values);
    next();
  };

  if (loading) {
    return <LoadingIndicator visible animation />;
  }

  return (
    <AutoForm
      schema={schema}
      onSubmit={handleSubmit}
      showInlineError
      name="signup-form"
      engagement-type="sign up"
      engagement-name="account">
      <div className="row">
        <div className="col-xl-6">
          <AutoField name="firstName" />
        </div>
        <div className="col-xl-6">
          <AutoField name="lastName" />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <AutoField name="username" />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <AutoField name="email" />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <AutoField name="password" />
        </div>
        <div className="col-xl-6">
          <AutoField name="repeatPassword" />
        </div>
      </div>
      <div className="mt-8">
        <Submit text="Continue" buttonStyles="px-8" />
      </div>
    </AutoForm>
  );
};

export default AssessmentPersonalInformation;
