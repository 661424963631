import LogoImg from 'shared/src/assets/logo.png';

export default {
  FOODS: [
    {
      label: 'All Foods',
      value: '1',
    },
    {
      label: 'Atkins acceptable foods only',
      value: '2',
      icon: LogoImg,
    },
  ],
  RECIPES: [
    {
      label: 'Atkins Recipes',
      value: '1',
    },
    {
      label: 'Custom Recipes',
      value: '2',
    },
  ],
};
