import TableRow from './TableRow';

interface TableProps<T> {
  data: T[];
  headers: string[];
  onClickItem?: (item: T) => void;
  onClickDelete?: (index: number) => void;
}

const Table = <T,>({
  data,
  headers,
  onClickItem,
  onClickDelete,
}: TableProps<T>) => {
  return (
    <div className="table-responsive table-borderless">
      <table className="table table-head-custom table-vertical-center">
        <thead>
          <tr>
            {headers.map((value: string, index: number) => {
              let textClass = 'text-left';
              if (index > 0) {
                textClass =
                  index === headers.length - 1 ? 'text-right' : 'text-center';
              }
              return (
                <th key={`table-th-${index}`} className={textClass}>
                  {value}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <TableRow<T>
              data={item}
              index={index}
              onClickItem={onClickItem}
              onClickDelete={onClickDelete}
              key={`table-row-${index}`}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
