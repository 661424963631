import AssessmentFooter from '../AssessmentFooter';

import useAssessment, {
  IQuestion,
} from 'shared/src/store/modules/useAssessment';

import MaleImg from 'assets/icons/male.svg';
import CheckImg from 'assets/icons/check.svg';
import FemaleImg from 'assets/icons/female.svg';

const IMAGES = { male: MaleImg, female: FemaleImg };
const AssessmentGender = ({ options }: IQuestion) => {
  const { back, next, gender, update } = useAssessment();

  const handleClick = (value: string) => () => {
    update('gender', value);
  };
  const handleNext = () => {
    window.dataLayer.push({
      event: 'form_progress',
      form_name: 'signup-form ',
      step: '3',
      engagement_type: 'sign up',
      engagement_name: 'account',
    });
    next();
  };
  return (
    <div>
      <div className="d-flex">
        {options.map(({ label, value }) => {
          const isActive = gender === value;
          return (
            <a
              key={value}
              onClick={handleClick(value)}
              style={{
                ...(isActive && {
                  backgroundColor: 'white',
                }),
                ...(!isActive && {
                  opacity: 0.6,
                }),
              }}
              className="position-relative max-w-216px w-100 d-block border-2 border-primary rounded pt-8 pb-4 mr-8 d-flex flex-column justify-content-between align-items-center">
              {isActive && (
                <img
                  src={CheckImg}
                  alt="Check icon"
                  className="position-absolute top-0 right-0 mt-4 mr-4"
                />
              )}
              <img
                alt={`${value} icon`}
                src={IMAGES[label.toLowerCase() as keyof typeof IMAGES]}
              />
              <div className="text-center mt-6">{label}</div>
            </a>
          );
        })}
      </div>
      <AssessmentFooter onClickBack={back} onClickNext={handleNext} />
    </div>
  );
};

export default AssessmentGender;
