import { gql } from '@apollo/client';

export const RECIPE_COOKING_METHOD_SCHEMA = `
  id
  name
`;

export const RECIPE_COOKING_METHODS_SCHEMA = `
  recipeCookingMethods {
    edges {
      node {
        ${RECIPE_COOKING_METHOD_SCHEMA}
      }
    }
  }
`;

export const GET_RECIPE_COOKING_METHODS_QUERY = gql`
  query getRecipeCookingMethods {
    ${RECIPE_COOKING_METHODS_SCHEMA}
  }
`;
