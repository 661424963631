import { useQuery } from '@apollo/client';
import { Tab, Tabs } from 'react-bootstrap';

import { TAny, IRecipe } from 'shared/src/types';
import { GET_RECIPE_QUERY } from 'shared/src/graphql/recipe';

import { RECIPE_DETAIL_TABS } from 'constants/index';

import ArrowLeftImg from 'assets/icons/arrow-left.svg';
import { useMemo } from 'react';

interface RecipeDetailProps {
  recipe: IRecipe;
  onClickBack: () => void;
}

const RecipeDetail = ({ recipe, onClickBack }: RecipeDetailProps) => {
  const details = recipe;
  const directions = recipe?.directions ?? '';

  const { data } = useQuery(GET_RECIPE_QUERY, {
    variables: {
      id: details?.objectId ?? '',
      recipes: true,
    },
  });

  const ingredients = useMemo(() => {
    let ingredients = (recipe?.foods?.edges.map(({ node }) => node) ?? [])
      .sort((a, b) => a.order - b.order)
      .map(({ servingUnit, servingSize, foodName, food }) => ({
        label: food?.name || foodName || 'food',
        extra: `${servingSize} ${servingUnit}`,
      }));

    const recipeRecipes =
      data?.recipes?.edges?.map(({ node }: TAny) =>
        node?.recipes?.edges?.map(({ node }: TAny) => ({
          extra: `${node?.quantity} servings`,
          label: node?.includedRecipe.name,
        }))
      )[0] ?? [];

    ingredients = [...ingredients, ...recipeRecipes];

    return ingredients;
  }, [recipe, data]);

  return (
    <div>
      <a className="cursor-pointer" onClick={onClickBack}>
        <img className="mr-2" src={ArrowLeftImg} />
        Go back to tracker
      </a>
      <div className="mt-4 d-flex align-items-center">
        {recipe?.image?.url && (
          <img className="mr-2 rounded-lg w-100px" src={recipe?.image?.url} />
        )}
        <div className="ml-4">
          <div className="h3 mb-0">{recipe.name}</div>
          <span>Net Carbs {recipe.netCarbs}g</span>
        </div>
      </div>

      <Tabs
        transition
        id="search-tabs"
        className="border-0 mt-8"
        defaultActiveKey={RECIPE_DETAIL_TABS[0].key}>
        {RECIPE_DETAIL_TABS.map(({ key, component }) => {
          const TabComponent = component;
          let props: TAny = {};

          switch (key) {
            case 'details':
              props = {
                details,
              };
              break;
            case 'ingredients':
              props = {
                ingredients,
              };
              break;
            case 'directions':
              props = {
                directions,
              };
              break;
          }

          return (
            <Tab
              title={key}
              eventKey={key}
              tabClassName="border-0"
              key={`teb-item-${key}`}>
              <div className="bg-light rounded p-8">
                <TabComponent {...props} />
              </div>
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default RecipeDetail;
