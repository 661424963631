import { useState } from 'react';

type Base = {
  id: string;
  name: string;
};

const useFilterList = <T extends Base>(data: T[]) => {
  const [filter, setFilter] = useState('');

  const filteredData = data
    .sort((a: Base, b: Base) => a.name.localeCompare(b.name))
    .filter((item, pos, ary) => {
      return !pos || item.id !== ary[pos - 1].id;
    })
    .filter(
      ({ name }) =>
        filter === '' || name.toLowerCase().includes(filter.toLowerCase())
    );

  return { setFilter, filteredData };
};

export default useFilterList;
