import { useMemo, useState } from 'react';

import pick from 'lodash/pick';
import omit from 'lodash/omit';

import { IFood, IFoodNutritionalValues, TAny } from '../types';

import NUTRITIONAL_VALUES from '../constants/nutritionalValues';

const normalizeFood = (food: Partial<IFood>) => {
  const data: Partial<IFood & { fat: number }> = {
    ...food,
  };
  data.fat = data.fat ?? data.totalFat;

  return data;
};

const useConversions = (food: IFood, servings = 1) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { servingConversions, hasConversions } = useMemo(() => {
    let servingConversions: Partial<IFood>[] =
      food.servingConversions?.edges?.map(
        ({ node }: { node: Partial<IFood> }) => {
          return omit(normalizeFood(node), ['id', 'objectId']);
        }
      ) ?? [];

    if (servingConversions.length <= 1) {
      servingConversions = [
        {
          ...pick(normalizeFood(food), NUTRITIONAL_VALUES.ALL_SERVING),
        },
      ];
    }

    return {
      servingConversions,
      hasConversions: servingConversions.length > 1,
    };
  }, [food]);

  const selectedConversion = useMemo(() => {
    return servingConversions[selectedIndex] ?? {};
  }, [servingConversions, selectedIndex]);

  const convertedValuesByUnit = useMemo(() => {
    type TSub = keyof typeof nutrients;
    const nutrients = {
      ...pick(selectedConversion, NUTRITIONAL_VALUES.ALL),
    } as Partial<IFoodNutritionalValues>;

    if (!hasConversions) {
      Object.keys(nutrients).forEach((key) => {
        const value = (nutrients[key as TSub] as number) ?? 0;
        const newValue =
          (value * servings) / (selectedConversion?.servingSize ?? 1);
        nutrients[key as TSub] = newValue as TAny;
      });

      return nutrients;
    }

    Object.keys(nutrients).forEach((key) => {
      const value = (nutrients[key as TSub] as number) ?? 0;
      const newValue =
        (value * servings) / (selectedConversion.servingSize ?? 1);

      nutrients[key as TSub] = newValue as TAny;
    });

    return nutrients;
  }, [food, servings, hasConversions, selectedConversion]);

  return {
    selectedIndex,
    hasConversions,
    setSelectedIndex,
    servingConversions,
    selectedConversion,
    convertedValuesByUnit,
  };
};

export default useConversions;
