import { gql } from '@apollo/client';

export const RECIPE_KEY_INGREDIENT_SCHEMA = `
  id
  name
  legacyId
`;

export const RECIPE_KEY_INGREDIENTS_SCHEMA = `
  recipeKeyIngredients {
    edges {
      node {
        ${RECIPE_KEY_INGREDIENT_SCHEMA}
      }
    }
  }
`;

export const GET_RECIPE_KEY_INGREDIENTS_QUERY = gql`
  query getRecipeKeyIngredients {
    ${RECIPE_KEY_INGREDIENTS_SCHEMA}
  }
`;
