interface ServingTableItemProps {
  title: string;
  value: string;
}

const ServingTableItem = ({ title, value }: ServingTableItemProps) => {
  return (
    <div className="form-group">
      <label className="nutrition-serving-label">{title}</label>
      <div className="font-size-base d-ib">
        <span className="badge badge-light min-w-80px h3 font-weight-boldest my-2">
          {value}
        </span>
        {title !== 'Calories' && (
          <span className="font-weight-boldest"> g</span>
        )}
      </div>
    </div>
  );
};

export default ServingTableItem;
