import { useState } from 'react';

import pick from 'lodash/pick';
import startOfDay from 'date-fns/startOfDay';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';

import useAuth from '../../hooks/useAuth';
import QUERY_RESULT from '../../constants/queryResult';

import {
  TAny,
  IObject,
  TGraphQLList,
  IMeasurements,
  TGraphQLResponse,
} from '../../types';
import useDate from '../../hooks/useDate';
import useApolloCrud from '../../hooks/useApolloCrudv2';
import useReportVariables from '../../hooks/useReportVariables';
import {
  CREATE_LOG_MEASUREMENT,
  DELETE_LOG_MEASUREMENT,
  UPDATE_LOG_MEASUREMENT,
  GET_LOG_MEASUREMENTS_QUERY,
  GET_ALL_LOG_MEASUREMENTS_QUERY,
  GET_LAST_LOG_MEASUREMENTS_QUERY,
  GET_LOG_MEASUREMENTS_WHERE_QUERY,
} from '../../graphql/logMeasurement';
import { GET_LOGS } from '../../graphql/log';
import { updateCache } from '../../utils/GraphqlHelper';

type TQuery = TGraphQLResponse<'logsMeasurements', TGraphQLList<IMeasurements>>;

const today = startOfDay(new Date());

const useLogMeasurement = (preloadQuery = false) => {
  const { date: currentDate } = useDate();
  const { user } = useAuth();
  const client = useApolloClient();
  const reportVariables = useReportVariables();

  const [queryVariables, setQueryVariables] = useState({
    userId: user.objectId,
    date: today.toISOString(),
  });

  const { query, mutations } = useApolloCrud<TQuery, IMeasurements>({
    skipFirstQuery: !preloadQuery,
    queryNode: GET_LOG_MEASUREMENTS_QUERY,
    createMutationNode: CREATE_LOG_MEASUREMENT,
    removeMutationNode: DELETE_LOG_MEASUREMENT,
    updateMutationNode: UPDATE_LOG_MEASUREMENT,
    pointerName: 'logmeasurements',
    queryOptions: {
      variables: queryVariables,

      nextFetchPolicy: 'cache-first',
      fetchPolicy: 'cache-and-network',
    },
  });

  const lasMeasurementsQuery = useQuery<
    TGraphQLResponse<'logMeasurements', TGraphQLList<IMeasurements>>
  >(GET_LAST_LOG_MEASUREMENTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: user.objectId,
    },
  });

  const [getHistorical, historicalQuery] = useLazyQuery<
    TGraphQLResponse<'logMeasurements', TGraphQLList<IMeasurements>>
  >(GET_ALL_LOG_MEASUREMENTS_QUERY, {
    variables: {
      first: 10000,
      userId: user.objectId,
    },
  });

  const logLastMeasurements = (lasMeasurementsQuery.data?.logMeasurements &&
    lasMeasurementsQuery.data.logMeasurements.edges.map(
      ({ node }) => node
    )[0]) || {
    id: '',
    userId: user.objectId,
    loggedAt: new Date().toDateString(),
  };

  const historicalLogs = historicalQuery.data?.logMeasurements?.edges?.map(
    ({ node }) => node
  );

  const save = async (log: TAny | null, date: Date, fields?: IMeasurements) => {
    if (!log) {
      const { data } = await client.query({
        query: GET_LOG_MEASUREMENTS_QUERY,
        variables: {
          userId: user.objectId,
          date: date.toISOString(),
          ...fields,
        },
      });

      const existingLog = data.logMeasurements?.edges?.[0];
      if (existingLog) {
        log = existingLog.node;
      } else {
        log = {};
      }
    }

    const action = log.id ? mutations.update.call : mutations.create.call;
    let variables: IObject = {
      id: log.id,
      userId: user.objectId,
      loggedAt: date.toISOString(),
      ...(log.id && {
        fields,
      }),
      ...(!log.id && {
        ...fields,
      }),
    };

    if (!log.id) {
      const fields = { ...variables };
      variables = { fields };
    }

    return action({
      variables,
      refetchQueries: [
        {
          query: GET_LAST_LOG_MEASUREMENTS_QUERY,
          variables: {
            userId: user.objectId,
          },
        },
      ],
      update: (query, document) => {
        updateCache(GET_LOGS, {
          userId: user.objectId,
          date: date.toISOString(),
        })(query, document);
        updateCache(GET_LOG_MEASUREMENTS_WHERE_QUERY, reportVariables)(
          query,
          document
        );
        updateCache(GET_ALL_LOG_MEASUREMENTS_QUERY, {
          first: 10000,
          userId: user.objectId,
        })(query, document);
      },
      optimisticResponse: {
        [log.id ? 'updateLogMeasurement' : 'createLogMeasurement']: {
          logMeasurement: {
            ...fields,
            userId: user.objectId,
            loggedAt: date.toISOString(),
            id: log.id || 'OptimisticId' + Math.random(),
          },
        },
      },
    });
  };

  const remove = (id: string) => {
    mutations.remove.call({
      variables: {
        id,
      },
      update: (query, document) => {
        updateCache(GET_LOGS, {
          userId: user.objectId,
          date: currentDate.toISOString(),
        })(query, document);
        updateCache(GET_LOG_MEASUREMENTS_WHERE_QUERY, reportVariables)(
          query,
          document
        );
        updateCache(GET_ALL_LOG_MEASUREMENTS_QUERY, {
          first: 10000,
          userId: user.objectId,
        })(query, document);
      },
      refetchQueries: ['getLastLogMeasurements'],
    });
  };

  return {
    save,
    query,
    remove,
    mutations,
    setQueryVariables,
    historical: {
      ...pick(historicalQuery, QUERY_RESULT),
      get: getHistorical,
      _data: historicalQuery.data,
      data: historicalLogs,
    },
    lastMeasurements: {
      ...pick(lasMeasurementsQuery, QUERY_RESULT),
      _data: lasMeasurementsQuery.data,
      data: logLastMeasurements,
    },
  };
};

export default useLogMeasurement;
