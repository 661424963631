export default {
  FOOD: {
    title: 'What did you eat today?',
    subtitle: 'Add foods or ingredients you want to track below.',
  },

  WEIGHT: {
    title: 'How are you doing today?',
    subtitle: '',
  },

  MEASUREMENTS: {
    title: 'How are you doing today?',
    subtitle: '',
  },

  FITNESS: {
    title: 'How many steps?',
    subtitle: '',
  },

  WATER: {
    title: 'How many cups?',
    subtitle: 'Everytime you drink a cup of water, come here and track it!',
  },
};
