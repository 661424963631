import React, { useMemo } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import globalHook, { Store } from 'use-global-hook';

import { IObject } from '../types';
import UNITS_OF_MASS from '../constants/unitsOfMass';
import UNITS_OF_LENGTH from '../constants/unitsOfLength';
import { GET_ASSESSMENTS_QUERY } from '../graphql/assessment';
import { kgToLb, lbToKg, cmToFeetInches } from '../utils/Conversions';

type TState = {
  age?: string;
  weight?: string;
  gender?: string;
  height?: string;
  diabetes?: string;
  pregnant?: string;
  userData?: IObject;
  weightUnit?: string;
  heightUnit?: string;
  desiredWeightLost?: string;
  desiredWeightLostUnit?: string;
  programPreference?: string;
  waistCircumference?: string;
};

export type TAssessmentsKeys =
  | 'age'
  | 'weight'
  | 'gender'
  | 'height'
  | 'diabetes'
  | 'pregnant'
  | 'userData'
  | 'weightUnit'
  | 'heightUnit'
  | 'desiredWeightLost'
  | 'programPreference'
  | 'waistCircumference'
  | 'desiredWeightLostUnit';

type TAssociatedActions = {
  update: (key: TAssessmentsKeys, value: string | IObject) => void;
};

export interface IAssessmentOption {
  label: string;
  value: string;
  order: number;
}

export interface IAssessmentComponentProps {
  initValue: string;
  initValueUnit?: string;
  onContinue?: () => void;
  options?: IAssessmentOption[];
  setInvalid?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: (key: TAssessmentsKeys, value: string | IObject) => void;
}

const updateState = (
  store: Store<TState, TAssociatedActions>,
  key: TAssessmentsKeys,
  value: string | IObject
) => {
  store.setState({ ...store.state, ...{ [key]: value } });
};

const initialState: TState = {
  age: undefined,
  weight: undefined,
  height: undefined,
  gender: undefined,
  diabetes: undefined,
  pregnant: undefined,
  userData: undefined,
  desiredWeightLost: undefined,
  waistCircumference: undefined,
  programPreference: undefined,
  weightUnit: UNITS_OF_MASS[0].value,
  heightUnit: UNITS_OF_LENGTH[0].value,
  desiredWeightLostUnit: UNITS_OF_MASS[0].value,
};

const globalActions = {
  update: updateState,
};

const useGlobal = globalHook<TState, TAssociatedActions>(
  React,
  initialState,
  globalActions
);

const useAssessment = () => {
  const [state, actions] = useGlobal();
  const { data } = useQuery(GET_ASSESSMENTS_QUERY);
  const assessments = get(data, 'assessments.edges', []);

  const plan = useMemo(() => {
    const height = Number(state.height || 0) / 100;

    const weight =
      state.weightUnit === 'kg'
        ? Number(state.weight)
        : lbToKg(Number(state.weight));

    const isPregnant = state.pregnant === '3';
    const hasDiabetes = state.diabetes === '5';

    const bmi = weight / (height * height);
    const dontKnowWaist = Number(state.waistCircumference) === 0;

    // If the user is diebetic always recommend Atkins 20
    let recommendedPlan;
    let qualifyForAtkins40 = false;
    // Calculate user's BMI

    /*const heightInInches = Number(feet) * 12 + Number(inches);
    let bmi = (weight / Math.pow(heightInInches, 2)) * 703;
    bmi = Math.round(bmi * 10) / 10;*/

    // If no to diabetic / pre-diabetic and pregnant / nursing – waist size will determine program recommendation
    if (state.gender === '2') {
      if (dontKnowWaist) {
        if (Number(bmi) >= 30) {
          qualifyForAtkins40 = false;
        } else {
          if (hasDiabetes) {
            qualifyForAtkins40 = false;
          } else {
            qualifyForAtkins40 = true;
          }
        }
      } else {
        if (Number(state.waistCircumference) >= 40) {
          qualifyForAtkins40 = false;
        } else {
          if (hasDiabetes) {
            qualifyForAtkins40 = false;
          } else {
            qualifyForAtkins40 = true;
          }
        }
      }
    } else {
      if (dontKnowWaist || Number(state.waistCircumference) === 0) {
        if (Number(bmi) >= 30) {
          qualifyForAtkins40 = false;
        } else {
          if (hasDiabetes) {
            qualifyForAtkins40 = false;
          } else {
            qualifyForAtkins40 = true;
          }
        }
      } else {
        if (Number(state.waistCircumference) >= 35) {
          qualifyForAtkins40 = false;
        } else {
          if (hasDiabetes) {
            qualifyForAtkins40 = false;
          } else {
            qualifyForAtkins40 = true;
          }
        }
      }
    }

    if (state.programPreference === '7') {
      // User has chosen Atkins 40, if they qualify based on the prior questions, recommend this plan
      // If they do not qualify, recommend the Atkins 20 with a disclaimer
      if (qualifyForAtkins40) {
        recommendedPlan = 'atkins 40';
      } else {
        recommendedPlan = 'atkins 20';
      }
    } else {
      // User has chosen Atkins 20
      // If they have less than 20lbs to loose and they still qualify for Atkins 40
      // then recommend the Atkins 40 plan.
      if (Number(state.desiredWeightLost) <= 20 && qualifyForAtkins40) {
        recommendedPlan = 'atkins 40';
      } else {
        // User has chosen Atkins 20
        // The only type of user that doesn't qualify at this point
        // is a pregnant user and they have already been redirected
        recommendedPlan = 'atkins 20';
      }
    }

    if (isPregnant) {
      recommendedPlan = 'atkins 100';
    }

    return recommendedPlan;
  }, [state]);

  const getAssessmentData = (userId: string, userObjectId: string) => {
    const assessmentsRef = [
      { name: 'age', value: state.age },
      { name: 'height', value: state.height },
      { name: 'weight', value: state.weight },
      { name: 'gender', value: state.gender },
      { name: 'diabetes', value: state.diabetes },
      { name: 'pregnant', value: state.pregnant },
      { name: 'desiredWeightLost', value: state.desiredWeightLost },
      { name: 'programPreference', value: state.programPreference },
      { name: 'waistCircumference', value: state.waistCircumference },
    ];

    const toSave = [];
    for (const a of assessmentsRef) {
      const assessment = assessments.filter(
        ({ node: { name } }: { node: { name: string } }) => name === a.name
      )[0];

      let value = a.value || 0;
      const unit = get(state, `${a.name}Unit`);
      if (unit) {
        if (unit === 'kg') {
          value = kgToLb(+value).toString();
        }
      }

      if (a.name === 'height') {
        const { feet, inches } = cmToFeetInches(+value);
        value = feet * 12 + inches;
      }

      toSave.push({
        value: String(value),
        userId: userObjectId,
        user: { link: userId },
        assessmentId: assessment.node.objectId,
        assessment: { link: assessment.node.id },
      });
    }

    return {
      assessments: { createAndAdd: toSave },
    };
  };

  return {
    age: state.age,
    gender: state.gender,
    weight: state.weight,
    height: state.height,
    userData: state.userData,
    diabetes: state.diabetes,
    pregnant: state.pregnant,
    weightUnit: state.weightUnit,
    heightUnit: state.heightUnit,
    desiredWeightLost: state.desiredWeightLost,
    programPreference: state.programPreference,
    waistCircumference: state.waistCircumference,
    desiredWeightLostUnit: state.desiredWeightLostUnit,

    update: actions.update,

    plan,
    assessments,
    getAssessmentData,
  };
};

export default useAssessment;
