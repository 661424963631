import { NavLink, useRouteMatch } from 'react-router-dom';

import Card from 'components/ui/Card';
import { TRACKER_TABS } from 'constants/index';

const TrackerNavbar = () => {
  const { url } = useRouteMatch();

  return (
    <Card size="small">
      <nav className="navbar py-0">
        <h3 className="h4 mr-12 mb-sm-0">My Tracker</h3>
        <ul className="nav">
          {TRACKER_TABS.map((name) => (
            <li key={`tracker-tab-${name}`} className="nav-item mr-4">
              <NavLink
                to={`${url}/${name}`}
                className="btn btn-hover-light text-primary text-capitalize px-6"
                activeClassName="active">
                {name}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </Card>
  );
};

export default TrackerNavbar;
