import ReactQuill from 'react-quill';
import { connectField, HTMLFieldProps } from 'uniforms';

const RichText = ({
  value,
  error,
  label,
  disabled,
  required,
  onChange,
  placeholder,
}: HTMLFieldProps<string, HTMLInputElement>) => {
  const handleOnChange = (content: string) => {
    onChange(content);
  };

  return (
    <div
      className={`form-group ${required ? 'required' : ''} ${
        error ? 'is-invalid' : ''
      }`}>
      {label && (
        <label className={`${error ? 'text-danger' : ''}`}>{label}</label>
      )}
      <ReactQuill
        theme="snow"
        readOnly={disabled}
        value={value || ''}
        onChange={handleOnChange}
        placeholder={placeholder}
      />
      {error && <span className="form-text text-danger">{error.message}</span>}
    </div>
  );
};

export default connectField(RichText);
