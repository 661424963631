import soyIcon from 'assets/icons/foods/soy.svg';
import nutsIcon from 'assets/icons/foods/nuts.svg';
import eggsIcon from 'assets/icons/foods/eggs.svg';
import meatIcon from 'assets/icons/foods/meat.svg';
import dairyIcon from 'assets/icons/foods/dairy.svg';
import fruitIcon from 'assets/icons/foods/fruit.svg';
import poultryIcon from 'assets/icons/foods/poultry.svg';
import seafoodIcon from 'assets/icons/foods/seafood.svg';
import shellfishIcon from 'assets/icons/foods/shellfish.svg';
import vegetablesIcon from 'assets/icons/foods/vegetables.svg';
import wheatGlutenIcon from 'assets/icons/foods/wheat-gluten.svg';

export default [
  { icon: dairyIcon, label: 'Dairy', value: 'dairy' },
  { icon: nutsIcon, label: 'Nuts', value: 'nuts' },
  {
    icon: wheatGlutenIcon,
    label: 'Wheat, Gluten',
    value: 'wheatgluten',
  },
  { icon: poultryIcon, label: 'Poultry', value: 'poultry' },
  { icon: seafoodIcon, label: 'Seafood', value: 'seafood' },
  { icon: fruitIcon, label: 'Fruit', value: 'fruit' },
  { icon: eggsIcon, label: 'Egg', value: 'egg' },
  {
    icon: shellfishIcon,
    label: 'Shellfish',
    value: 'shellfish',
  },
  { icon: soyIcon, label: 'Soy, Tofu', value: 'soytofu' },
  { icon: meatIcon, label: 'Meat', value: 'meat' },
  {
    icon: vegetablesIcon,
    label: 'Vegetable',
    value: 'vegetable',
  },
];
