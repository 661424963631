import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { NavLink, useRouteMatch } from 'react-router-dom';

import Card from '../ui/Card';
import FormCheck from '../ui/FormCheck';
import { useEffect } from 'react';

interface SearchFiltersProps {
  values: { value: string; label: string; icon?: string }[];
  onChange: (value: string) => void;
}

const SearchFilters = ({ values, onChange }: SearchFiltersProps) => {
  const { hash } = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const selectedOption = values?.[selectedIndex]?.value;
  const { url } = useRouteMatch();
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const indexFound = values.findIndex(
      ({ value }) => value === event.target.value
    );
    if (values[indexFound].label === 'Custom Recipes') {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    setSelectedIndex(indexFound);
    onChange(event.target.value);
  };
  useEffect(() => {
    if (hash.includes('recipes')) {
      setSelectedIndex(1);
      setShowButton(true);
    }
  }, [hash]);
  return (
    <Card size="small" styles="rounded mb-3">
      <Form className="radio-inline">
        {values.map(({ icon, value, label }, index) => (
          <div className="d-flex mr-4" key={`inline-${value}-${index}`}>
            <FormCheck
              type="radio"
              label={label}
              value={value}
              onChange={handleOnChange}
              checked={selectedOption === value}
              name={`search-filters-${value}-${index}`}
            />
            {icon && (
              <div className="max-w-25px">
                <img src={icon} alt="Atkins logo" className="w-100" />
              </div>
            )}
          </div>
        ))}
        {showButton && (
          <div className="d-flex mr-4 desktop-l-auto">
            <NavLink
              to={`/custom-recipes/form`}
              className="btn btn-dark"
              activeClassName="active">
              Add New
            </NavLink>
          </div>
        )}
      </Form>
    </Card>
  );
};

export default SearchFilters;
