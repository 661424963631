import { gql } from '@apollo/client';

export const PROGRAM_SCHEMA = `
  id
  name
  objectId
  orderId
  phaseId
  goalLow
  goalHigh
  learnMoreUrl
  fullDescription
  parent {
    id
    name
    phaseId
  }
`;

export const PROGRAMS_SCHEMA = `
  programs(order: orderId_ASC)  {
    edges {
      node {
        ${PROGRAM_SCHEMA}
      }
    }
  }
`;

export const GET_PROGRAMS_QUERY = gql`
  query getPrograms($where: ProgramWhereInput) {
    programs(where: $where) {
      edges {
        node {
          ${PROGRAM_SCHEMA}
        }
      }
    }
  }
`;
