import { useEffect, useRef, useState } from 'react';

import { Form, Button } from 'react-bootstrap';

import useSearch from 'hooks/useSearch';

import SearchImg from 'assets/icons/search.svg';

interface SearchInputProps {
  sync?: boolean;
  label?: string;
  type?: string;
  onSearch: (q: string) => void;
}

const SearchInput = ({
  sync,
  onSearch,
  label = 'Search food...',
  type = '',
}: SearchInputProps) => {
  const [t, setT] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const formRef = useRef<HTMLFormElement>();

  const { q, setQ } = useSearch();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setT(q);
      onSearch(q);
    }
  }, [q]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      q: { value: string };
    };

    setQ(target.q.value);
    onSearch(q);
    if (type === 'regularFoods') {
      window.dataLayer.push({
        event: 'view_search_results',
        search_term: target.q.value,
        engagement_type: 'search',
        engagement_name: 'food',
      });
    }
    if (type === 'customFoods') {
      window.dataLayer.push({
        event: 'view_search_results',
        search_term: target.q.value,
        engagement_type: 'search',
        engagement_name: 'custom food',
      });
    }
    if (type === 'searchRecipes' && !sync) {
      window.dataLayer.push({
        event: 'view_search_results',
        search_term: target.q.value,
        engagement_type: 'search',
        engagement_name: 'recipes',
      });
    }
    if (type === 'searchFavorites') {
      window.dataLayer.push({
        event: 'view_search_results',
        search_term: target.q.value,
        engagement_type: 'search',
        engagement_name: 'favourite',
      });
    }
    if (type === 'searchGroupings') {
      window.dataLayer.push({
        event: 'view_search_results',
        search_term: target.q.value,
        engagement_type: 'search',
        engagement_name: 'groupings',
      });
    }
  };

  const handleSync = (e: React.ChangeEvent<HTMLInputElement>) => {
    setT(e.target.value);
    sync && setQ(e.target.value);
    if (type === 'searchFavorites') {
      window.dataLayer.push({
        event: 'view_search_results',
        search_term: e.target.value,
        engagement_type: 'search',
        engagement_name: 'favourite',
      });
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      ref={(e: HTMLFormElement) => (formRef.current = e)}>
      <div className="form-group bg-white rounded mb-4">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text bg-white px-4 rounded">
              <img src={SearchImg} alt="Search icon" />
            </span>
          </div>
          <input
            name="q"
            value={t}
            placeholder={label}
            className="pl-0 pr-4 form-control border-0"
            onChange={handleSync}
          />
          {!sync && (
            <div className="input-group-append">
              <Button className="btn btn-primary rounded" type="submit">
                Submit
              </Button>
            </div>
          )}
        </div>
      </div>
    </Form>
  );
};

export default SearchInput;
