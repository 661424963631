import { useEffect, useRef } from 'react';

import { toast } from 'react-toastify';
import { AutoField } from 'uniforms-bootstrap4';
import { AutoForm, DeepPartial } from 'uniforms';

import Card from 'components/ui/Card';
import CardTitle from 'components/ui/CardTitle';

import useForm from 'shared/src/hooks/useForm';

import { SWeightTrackLoss } from 'forms/schemas';
import { useStartingWeight, useWeightLossGoal } from 'shared/src/store';

import { TAny } from 'shared/src/types';

import AppleImg from 'assets/icons/apple-blue.svg';

const ProfileStartingWeight = () => {
  const formRef = useRef<TAny>(null);
  const startingWeight = useStartingWeight(true);
  const weightLossGoal = useWeightLossGoal(true);

  const loggedAt = new Date(
    startingWeight.query.data?.[0]?.loggedAt ??
      startingWeight.query.data?.[0]?.createdAt ??
      new Date()
  );

  const { schema } = useForm(SWeightTrackLoss, {
    date: loggedAt.toString(),
    weight: +(startingWeight.query.data?.[0]?.value ?? 0),
    lossGoal: +(weightLossGoal.query.data?.[0]?.value ?? 0),
  });

  useEffect(() => {
    formRef?.current?.reset();
  }, [startingWeight.query.data, weightLossGoal.query.data, loggedAt]);

  const isSaving = startingWeight.isSaving || weightLossGoal.isSaving;

  const handleSubmit = async ({
    date,
    weight,
    lossGoal,
  }: DeepPartial<{ date: string; weight: number; lossGoal: number }>) => {
    try {
      await startingWeight.save(weight!.toString(), new Date(date!));
      await weightLossGoal.save(lossGoal!.toString());
      toast.success(
        startingWeight.userAssessmentId ? 'Weight updated' : 'Weight saved'
      );
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-md-9">
        <Card>
          <CardTitle
            title="Weight Settings"
            icon={AppleImg}
            button={{
              working: isSaving,
              text: 'save changes',
              textWorking: 'saving',
              link: () => {
                formRef?.current?.submit();
              },
            }}
          />

          <div className="form--dark form--size-lg">
            <p className="text-primary">
              Your recent start weight will override the original weight
              recorded in your last Atkins assessment.
            </p>
            <AutoForm
              ref={formRef}
              schema={schema}
              showInlineError
              label={false}
              onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <AutoField name="weight" label="My Weight" />
                </div>
                <div className="col-12 col-md-4">
                  <AutoField name="date" label="Start date" />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <AutoField
                    name="lossGoal"
                    label="Enter the number of pounds you want to lose"
                  />
                </div>
              </div>
            </AutoForm>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ProfileStartingWeight;
