import { gql } from '@apollo/client';

export const USER_FOOD = `
  id
  userId
  objectId

  name
  iron
  fiber
  sodium
  protein
  calcium
  calories
  vitaminC
  totalFat
  vitaminA
  glycerin
  netCarbs
  potassium
  totalCarbs
  totalSugars
  cholesterol
  saturatedFat
  sugarAlcohols
  monounsaturatedFat
  polyunsaturatedFat

  servingSize @include(if: $detailed)
  servingUnit @include(if: $detailed)
`;

export const GET_USER_FOODS_SCHEMA = `
  userFoods(
    skip: $skip
    last: $last
    first: $first
    after: $after
    order: $order
    where: $where
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    count
    edges {
      node {
        ${USER_FOOD}
      }
    }
  }
`;

export const GET_USER_FOODS = gql`
  query userFoods(
    $skip: Int
    $last: Int
    $first: Int
    $after: String
    $before: String
    $order: [UserFoodOrder!]
    $where: UserFoodWhereInput
    $detailed: Boolean = true
    ) {
    ${GET_USER_FOODS_SCHEMA}
  }
`;

export const CREATE_USER_FOOD = gql`
  mutation createUserFood(
    $detailed: Boolean = true
    $fields: CreateUserFoodFieldsInput!
  ) {
    createUserFood(input: { fields: $fields }) {
      userFood {
        ${USER_FOOD}
      }
    }
  }
`;

export const UPDATE_USER_FOOD = gql`
  mutation updateUserFood(
    $id: ID!
    $detailed: Boolean = true
    $fields: UpdateUserFoodFieldsInput!
  ) {
    updateUserFood(input: { id: $id, fields: $fields }) {
      userFood {
        ${USER_FOOD}
      }
    }
  }
`;

export const DELETE_USER_FOOD = gql`
  mutation($id: ID!) {
    deleteUserFood(input: { id: $id }) {
      userFood {
        id
      }
    }
  }
`;
