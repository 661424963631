/* eslint-disable eqeqeq */
import Ajv from 'ajv';
import get from 'lodash/get';
import { useMemo } from 'react';
import ajvErrors from 'ajv-errors';
import addFormats from 'ajv-formats';
import cloneDeep from 'lodash/cloneDeep';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';

import { IObject, TAny } from '../types';
import { sanitizeGraphqlValues } from '../utils/GraphqlHelper';

const ajv = new Ajv({
  allErrors: true,
  useDefaults: true,
  $data: true,
  strict: false,
});
ajvErrors(ajv);
addFormats(ajv);

const createValidator = (s: IObject) => {
  const validator = ajv.compile(s);
  return (model: IObject) => {
    const isValid = validator(model);

    if (!isValid) {
      const errors = (validator.errors || []).map((error) => {
        const { keyword, message, params, instancePath, schemaPath } = error;
        if (keyword !== 'errorMessage') {
          return { ...error, dataPath: instancePath };
        }

        const missingProperty = get(
          params,
          'errors[0].params.missingProperty',
          null
        );
        return {
          keyword,
          message,
          dataPath: missingProperty ? `/${missingProperty}` : instancePath,
          schemaPath: missingProperty
            ? `#/properties/${missingProperty}/errorMessage`
            : schemaPath,
        };
      });

      return {
        details: errors,
      };
    }

    return null;
  };
};

const getSchema = (
  schema: TAny,
  initValues: TAny,
  sanitizeGraphql: boolean
) => {
  const retSchema = cloneDeep(schema);

  for (const key of Object.keys(retSchema.properties)) {
    const copy = cloneDeep(retSchema.properties[key]);
    const prevDefault = copy.default != null ? copy.default : undefined;
    try {
      if (initValues[key] != null) {
        retSchema.properties[key].default = sanitizeGraphql
          ? sanitizeGraphqlValues(initValues[key])
          : initValues[key];
      } else {
        retSchema.properties[key].default = prevDefault;
      }
    } catch {
      retSchema.properties[key].default = prevDefault;
    }
  }

  return retSchema;
};

const useForm = (
  baseSchema: IObject,
  initValues: IObject = {},
  { sanitizeGraphql } = { sanitizeGraphql: true }
) => {
  const schema = useMemo(() => {
    const schema = getSchema(baseSchema, initValues, sanitizeGraphql);
    const schemaValidator = createValidator(schema);
    return new JSONSchemaBridge(schema, schemaValidator);
  }, [baseSchema, initValues]);

  return { schema };
};

export default useForm;
