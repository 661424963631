export default [
  {
    label: 'Atkins.com',
    link: 'https://www.atkins.com',
  },
  {
    label: 'Recipes',
    link: 'https://www.atkins.com/recipes',
  },
  {
    label: 'FAQs',
    link: 'https://www.atkins.com/how-it-works/faqs/atkins20-faq',
  },
  {
    label: 'Atkins |YOUR WAY|',
    link: 'https://www.atkins.com/your-way',
    variant: 'yellow',
  },
];
