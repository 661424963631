export const digitsOnly = (
  val: string,
  removeDots = true,
  decimalSeparator = '.'
) => {
  if (removeDots) {
    return val.replace(/[^0-9]/g, '');
  }

  val = val.replace(new RegExp(',', 'g'), decimalSeparator);
  if (val.split(decimalSeparator).length > 2) val = val.replace(/\.+$/, '');

  if (val.length > 1 && val?.[0] === '0' && val?.[1] !== decimalSeparator) {
    val = val.replace('0', '');
  }

  return val.replace(/[^0-9.]/g, '');
};

export const slugify = (string: string) => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(p, (c) => b.charAt(a.indexOf(c)))
    .replace(/&/g, '-and-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};
