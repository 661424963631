import { useMemo } from 'react';

import useAuth from './useAuth';
import useApiConstants from './useApiConstants';

import { TAny, TGroupedProgram } from '../types';

const getPlan = (userProgram: TAny, programs: TAny) => {
  let parent = userProgram?.parent;
  let prev = parent;
  while (parent) {
    const parentObj = programs.filter(({ id }: TAny) => id === parent?.id)[0];
    parent = parentObj.parent;
    if (parent) {
      prev = parent;
    }
  }

  return prev ?? parent ?? userProgram;
};

const usePrograms = () => {
  const { user } = useAuth();
  const { programs } = useApiConstants();

  const { userPlan, userPhase, userPlanRanges } = useMemo(() => {
    const userProgram = programs.filter(
      ({ id }) => id === user.program.id
    )[0] as TAny;

    const plan = getPlan(userProgram, programs);

    return {
      ...(userProgram?.phaseId && {
        userPhase: `Phase ${userProgram.phaseId}`,
      }),
      userPlan: plan?.name ?? 'Atkins 20®',
      userPlanRanges: `${user.program.goalHigh - 2}g NC (Range of ${
        user.program.goalLow
      }-${user.program.goalHigh} g NC)`,
    };
  }, [user]);

  const groupedPrograms: TGroupedProgram[] = useMemo(() => {
    const reduced = (programs
      .sort((a, b) => {
        return a.phaseId - b.phaseId;
      })
      .reduce((result, program) => {
        const _result = result as TAny;
        if (program.parent) {
          const parent = programs.filter(
            ({ id }) => id === program.parent.id
          )[0];

          if (parent.parent) {
            const granparent = programs.filter(
              ({ id }) => id === parent.parent.id
            )[0];

            if (!_result[granparent.name]) {
              _result[granparent.name] = {};
            }

            if (!_result[granparent.name]) {
              _result[granparent.name] = {
                ...parent,
                children: [],
              };
            }

            if (!_result[granparent.name].children) {
              _result[granparent.name].children = [];
            }

            _result[granparent.name].children.push({
              phase: parent,
              ...program,
            });
          } else if (!program.phaseId) {
            if (!_result[parent.name]) {
              _result[parent.name] = {
                children: [],
              };
            }

            if (!_result[parent.name].children) {
              _result[parent.name].children = [];
            }

            _result[parent.name].children.push(program);
          }
        } else {
          if (!_result[program.name]) {
            _result[program.name] = {
              children: [
                {
                  id: program.id,
                  objectId: program.objectId,
                  goalLow: program.goalLow,
                  goalHigh: program.goalHigh,
                },
              ],
            };
          }
        }

        return result;
      }, []) as unknown) as TAny[];

    const keys = Object.keys(reduced);
    return keys.map((key) => {
      const object = reduced[key as keyof typeof reduced];
      object.children = object.children.sort(
        (a: TAny, b: TAny) => a.orderId - b.orderId
      );

      if (object.children?.[1]?.phaseId) {
        object.children.shift();
      }

      return {
        name: key,
        ...object,
      };
    });
  }, [programs]);

  return {
    userPlan,
    programs,
    userPhase,
    userPlanRanges,
    groupedPrograms,
    userProgram: user.program,
  };
};

export default usePrograms;
