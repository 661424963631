import { useEffect, useMemo, useRef, useState } from 'react';

import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import startOfDay from 'date-fns/startOfDay';
import { AutoForm, AutoField } from 'uniforms-bootstrap4';

import RecipeDetail from 'components/recipeDetail/RecipeDetail';

import Submit from 'shared/src/forms/fields/web/Submit';

import { SRecipeTrack } from 'forms/schemas';

import { ModalType } from 'hooks/useModal';

import useForm from 'shared/src/hooks/useForm';
import useConversions from 'shared/src/hooks/useConversions';

import { useLogFood, useRecipe } from 'shared/src/store';
import { TAny, IFoodNutritionalValues } from 'shared/src/types';

import { TRACK_FOOD_HEADER } from 'constants/index';
import Modal, { IModalChildrenProps } from 'components/ui/Modal';

const TrackFoodContent = ({ data, setTitle, hide }: IModalChildrenProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [currentServingSize, setCurrentServingSize] = useState(1);
  const formRef = useRef<TAny>(null);

  const {
    savePreCalculated,
    cachedData: { food },
  } = useLogFood();

  const {
    hasConversions,
    setSelectedIndex,
    servingConversions,
    convertedValuesByUnit,
  } = useConversions(food, currentServingSize);

  const {
    recipe: { get, data: recipe },
  } = useRecipe();

  useEffect(() => {
    if (data?.recipe?.objectId) {
      get({
        id: data?.recipe?.objectId,
      });
    }
  }, [data]);

  useEffect(() => {
    setTitle(title);
  }, []);

  const formSchema = useMemo(() => {
    const schema = cloneDeep(SRecipeTrack);

    if (hasConversions) {
      schema.properties.servingUnit.default =
        servingConversions[0].servingUnit ?? '';
      schema.properties.servingUnit.allowedValues = servingConversions.map(
        ({ servingUnit }) => servingUnit
      ) as string[];

      return schema;
    } else {
      schema.properties.servingUnit.default = food.servingUnit ?? 'serving(s)';
      schema.properties.servingUnit.allowedValues = [
        food.servingUnit ?? 'serving(s)',
      ];
    }

    return schema;
  }, [hasConversions, food.servingUnit]);

  const { schema } = useForm(formSchema, {
    meal: food.meal,
    servingSize: food.servingSize,
    servingUnit: food.servingUnit,
    date: new Date(food.date).toISOString(),
  });

  const isExisting = !!food.id;
  const showRecipe = !!data?.recipe;
  const title = isExisting ? 'Update Tracked Food' : 'Track Food';
  const submitText = isExisting ? 'Update food' : 'Add to food tracker';

  const handleChange = (fieldName: string, value: TAny) => {
    fieldName === 'servingSize' && setCurrentServingSize(value);
    if (hasConversions && fieldName === 'servingUnit') {
      const index = servingConversions.findIndex(
        ({ servingUnit }) => servingUnit === value
      );

      setSelectedIndex(index);
    }
  };

  const handleSubmit = async ({
    date,
    meal,
    favorite,
    servingSize,
    servingUnit,
  }: {
    date: Date;
    meal: string;
    favorite: boolean;
    servingSize: number;
    servingUnit: string;
  }) => {
    try {
      setIsSaving(true);
      const payload = {
        ...food,
        ...convertedValuesByUnit,
        servingSize,
        servingUnit,
      };

      savePreCalculated(startOfDay(new Date(date)), meal, payload, favorite);

      if (favorite && showRecipe) {
        window.dataLayer.push({
          event: 'add_to_wishlist',
          currency: 'USD',
          item_name: food.verboseName || food.name,
          engagement_type: 'add to favourite',
          engagement_name: 'recipes',
        });
      } else if (favorite) {
        window.dataLayer.push({
          event: 'add_to_wishlist',
          currency: 'USD',
          item_name: food.verboseName || food.name,
          engagement_type: 'add to favourite',
          engagement_name: 'food',
        });
      }
      window.dataLayer.push({
        event: 'form_complete',
        form_name: 'food-form',
        engagement_type: 'tracker usage',
        engagement_name: 'food',
      });
      toast.success(food.id ? 'Log updated' : 'Food logged');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    } finally {
      setIsSaving(false);
      data?.callback && data.callback();
      hide();
    }
  };

  return (
    <>
      {showDetail && (
        <RecipeDetail
          recipe={recipe!}
          onClickBack={() => setShowDetail(false)}
        />
      )}

      {!showDetail && (
        <>
          <span className="h3">{food.verboseName || food.name}</span>{' '}
          <small className="text-muted font-italic">{food.brandName}</small>
          <div className="mb-3">
            {TRACK_FOOD_HEADER.map(({ id, label, style, prefix }) => (
              <div key={id}>
                <div className="normal">
                  {label}:{' '}
                  <span className={style}>
                    {(
                      (convertedValuesByUnit[
                        id as keyof IFoodNutritionalValues
                      ] as number) ?? 0
                    ).toFixed(2)}{' '}
                    {prefix ?? 'g'}
                  </span>
                </div>
              </div>
            ))}
          </div>
          {!!showRecipe && (
            <a className="cursor-pointer" onClick={() => setShowDetail(true)}>
              View Recipe
            </a>
          )}
          <div className="form--dark form--size-lg">
            <AutoForm
              ref={formRef}
              schema={schema}
              showInlineError
              onChange={handleChange}
              onSubmit={handleSubmit}>
              <AutoField name="date" />
              <div>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <AutoField name="meal" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <AutoField name="servingSize" label="" />
                  </div>{' '}
                  <div className="col-12 col-md-4">
                    <AutoField name="servingUnit" />
                  </div>
                </div>

                {!data?.hideFavorite && <AutoField name="favorite" />}
              </div>

              <Submit
                text={submitText}
                working={isSaving}
                textWorking="Saving"
              />
            </AutoForm>
          </div>
        </>
      )}
    </>
  );
};

const TrackFood = () => (
  <Modal type={ModalType.trackFood}>
    {(props: IModalChildrenProps) => <TrackFoodContent {...props} />}
  </Modal>
);

export default TrackFood;
