import Cookies from 'js-cookie';

const setCookie = (
  name: string,
  value: string,
  days = 120,
  domain = '.atkins.com'
) => {
  Cookies.set(name, value, { expires: days, domain, path: '/' });
};

const getCookie = (name: string) => {
  return Cookies.get(name);
};

const eraseCookie = (name: string, domain = '.atkins.com') => {
  Cookies.remove(name, { path: '/', domain });
};

export { setCookie, getCookie, eraseCookie };
