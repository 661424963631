import { Button } from 'react-bootstrap';

import Card from 'components/ui/Card';
import TrackerTitle from '../tabs/TrackerTitle';

import useModal, { ModalType } from 'hooks/useModal';

import { TRACKER_TITLES } from 'constants/index';
import CardInner from 'components/ui/CardInner';

import WeightImg from 'assets/bg-weight.png';

const MyWeightNewMeasurements = () => {
  const { show: showModal } = useModal();

  const handleTrackWeight = () => {
    window.dataLayer.push({
      event: 'form_start',
      form_name: 'weight-form',
      engagement_type: 'tracker usage',
      engagement_name: 'weight',
    });
    showModal(ModalType.trackWeight);
  };

  return (
    <Card>
      <CardInner backgroundImage={WeightImg}>
        <TrackerTitle values={TRACKER_TITLES.WEIGHT} />

        <div className="mt-26">
          <Button onClick={handleTrackWeight} className="mr-4">
            Track Weight
          </Button>
        </div>
      </CardInner>
    </Card>
  );
};

export default MyWeightNewMeasurements;
