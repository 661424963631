import Card from 'components/ui/Card';
import CardDataGoals from 'components/ui/CardDataGoals';
import InlineValueItem from 'components/ui/InlineValueItem';

import useWeight from 'hooks/useWeight';

const MyWeightLastWeightIn = () => {
  const {
    goalText,
    goalValue,
    baseWeight,
    gainOrLoseText,
    totalPoundsLost,
    lastWeightInDate,
    lastWeightInValue,
  } = useWeight();

  const v =
    ((baseWeight - +lastWeightInValue) * 100) / (baseWeight - goalValue);

  return (
    <Card>
      <CardDataGoals
        config={{
          gridClass: 'col-md-12',
          subtitle: goalText,
          title: {
            text: gainOrLoseText,
            value: totalPoundsLost,
          },
          percentage: {
            max: parseFloat(goalValue.toFixed(2)),
            min: baseWeight,
            value: Math.max(Math.min(v, 100), 0),
          },
        }}
      />
      <InlineValueItem
        title="Last weigh-in"
        value={lastWeightInValue}
        subtitle={lastWeightInDate}
      />
    </Card>
  );
};

export default MyWeightLastWeightIn;
