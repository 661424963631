import { useRef } from 'react';

import { toast } from 'react-toastify';
import { AutoField } from 'uniforms-bootstrap4';
import { AutoForm, DeepPartial } from 'uniforms';

import Card from 'components/ui/Card';
import CardTitle from 'components/ui/CardTitle';

import useForm from 'shared/src/hooks/useForm';
import { useDietaryExclusions } from 'shared/src/store';

import { SDietaryExclusionsSettings } from 'shared/src/forms/schemas';
import { TAny, IDietaryExclusionsSettingsSchema } from 'shared/src/types';

import { DIETARY_EXCLUSIONS } from 'constants/index';

import DietaryImg from 'assets/icons/dietary-blue.svg';

const DIETARY_EXCLUSIONS_COLS = [
  DIETARY_EXCLUSIONS.filter((_, index) => index <= 5),
  DIETARY_EXCLUSIONS.filter((_, index) => index > 5),
];

const ProfileDietaryExclusions = () => {
  const formRef = useRef<TAny>(null);
  const { save, isSaving, exclusions } = useDietaryExclusions(true);
  const { schema } = useForm(SDietaryExclusionsSettings, exclusions);

  const handleSubmit = async (
    values: DeepPartial<IDietaryExclusionsSettingsSchema>
  ) => {
    try {
      await save(values as IDietaryExclusionsSettingsSchema);
      toast.success('Dietary Exclusions Saved');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <Card>
      <CardTitle
        title="Dietary Exclusions"
        icon={DietaryImg}
        button={{
          working: isSaving,
          text: 'save changes',
          textWorking: 'saving',
          link: () => {
            formRef?.current?.submit();
          },
        }}
      />

      <AutoForm
        label={false}
        ref={formRef}
        schema={schema}
        showInlineError
        onSubmit={handleSubmit}>
        <div className="row form--no-margin">
          {DIETARY_EXCLUSIONS_COLS.map((items, row) => (
            <div
              className="col-12 col-md-6"
              key={`dietary-exclusions-row-${row}`}>
              {items.map(({ value, label, icon }, index) => (
                <div
                  key={`dietary-exclusions-item-${value}`}
                  className={`d-flex align-items-center p-4 rounded ${
                    !(index % 2) ? 'bg-gray-100' : ''
                  }`}>
                  <img src={icon} className="mr-4" />
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <label className="m-0">{label}</label>
                    <AutoField name={value} />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </AutoForm>
    </Card>
  );
};

export default ProfileDietaryExclusions;
