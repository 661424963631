export interface ICardProps {
  styles?: string;
  innerStyles?: string;
  children: React.ReactNode;
  size?: 'regular' | 'small' | 'minimal';
}

const Card = ({
  children,
  styles = '',
  size = 'regular',
  innerStyles = '',
}: ICardProps) => (
  <div className={`card card--${size} ${styles}`}>
    <div className={`card-body ${innerStyles}`}>{children}</div>
  </div>
);

export default Card;
