import { useEffect, useMemo } from 'react';

import format from 'date-fns/format';

import Card from 'components/ui/Card';
import Table from 'components/ui/Table';
import CardTitle from 'components/ui/CardTitle';

import useModal, { ModalType } from 'hooks/useModal';

import { useLogWeight } from 'shared/src/store';

import RulerImg from 'assets/icons/folk.svg';
import { toast } from 'react-toastify';

interface Data {
  date: string;
  entry: string;
  measurement: string;
  gainOrLose: string | React.ReactNode;
}

const GainOrLose = ({
  last,
  unit,
  value,
}: {
  unit: string;
  value: number;
  last: boolean;
}) => {
  const isUp = value > 0;
  if (last || value === 0) {
    return <span>-</span>;
  }

  return (
    <div>
      {/*<img className="mr-6" src={isUp ? ArrowUpImg : ArrowDownImg} />*/}
      <span className={`h3 ${isUp ? 'text-danger' : 'text-success-alt'}`}>
        {isUp ? '+' : '-'}
        {Math.abs(value ?? 0).toFixed(2)} {unit}
      </span>
    </div>
  );
};

const headers = [
  'recipe name',
  'net carbs',
  'protein',
  'fat',
  'fiber',
  'calories',
];

const CustomRecipeList = () => {
  const { show: showModal } = useModal();
  const {
    remove,
    historical: { get, data: logs },
  } = useLogWeight(true);

  useEffect(() => {
    get();
  }, []);

  const data = useMemo(() => {
    const weightLogs =
      logs
        ?.sort(
          (a, b) =>
            new Date(b.loggedAt).getTime() - new Date(a.loggedAt).getTime()
        )
        .map((log, index) => {
          const nextLog = logs[index + 1] ?? {
            weight: 0,
          };

          const last = index === logs.length - 1;
          const value = log.weight - nextLog.weight;

          return {
            measurement: 'Weight',
            date: format(new Date(log.loggedAt), 'MM/dd/yyyy'),
            entry: `${(log.weight ?? 0).toFixed(2)} ${log.units}`,
            gainOrLose: (
              <GainOrLose unit={log.units ?? ''} value={value} last={last} />
            ),
          };
        }) ?? [];
    return [];
    //return weightLogs;
  }, [logs]);

  const handleItem = (item: Data) => {
    const dateItem = item.date;

    const log = logs?.filter(
      ({ loggedAt }) => dateItem === format(new Date(loggedAt), 'MM/dd/yyyy')
    )[0];

    showModal(ModalType.trackWeight, { log });
  };

  const handleDelete = async (index: number) => {
    showModal(ModalType.Confirmation, {
      callback: async (status: boolean) => {
        if (!status) {
          return;
        }

        try {
          await remove(logs![index].id);
          toast.success('Log removed successfully');
        } catch {
          toast.error('Ups, something went wrong');
        }
      },
    });
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <Card>
          <CardTitle
            icon={RulerImg}
            title="Custom recipes"
            subtitle="Search, view and edit your recipes"
            button={{
              text: 'CREATE NEW CUSTOM RECIPE',
              textWorking: 'saving',
              link: '/custom-recipes/form',
            }}
          />
        </Card>
      </div>
      <div className="col-12">
        <div>
          <Table<Data>
            data={data}
            headers={headers}
            onClickItem={handleItem}
            onClickDelete={handleDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomRecipeList;
