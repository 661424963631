import React from 'react';

import CardInner from './ui/CardInner';

import FoodImg from 'assets/bg-foods.png';

interface ITabWrapperProps {
  styles?: string;
  children: React.ReactNode;
}

const TabWrapper = ({ children, styles = 'rounded-lg' }: ITabWrapperProps) => {
  return (
    <CardInner backgroundImage={FoodImg} styles={styles}>
      <div className="row">
        <div className="col-12">{children}</div>
      </div>
    </CardInner>
  );
};

export default TabWrapper;
