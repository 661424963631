import { SubmitField } from 'uniforms-bootstrap4';

import Spinner from 'react-bootstrap/Spinner';

interface IProps {
  text?: string;
  working?: boolean;
  textWorking?: string;
}

const Submit = ({
  working = false,
  text = 'Save',
  textWorking = 'Saving',
}: IProps) => {
  const textValue = working ? textWorking : text;
  const containerClasses = working ? 'uniforms-submit--working' : '';
  const loader = working ? (
    <Spinner
      size="sm"
      animation="border"
      className="uniforms-submit__icon mr-2"
    />
  ) : null;

  return (
    <div className={`uniforms-submit ${containerClasses}`}>
      {loader}
      <SubmitField
        inputClassName="btn btn-primary btn-block"
        className="uniforms-submit__field"
        value={textValue}
        disabled={working}
      />
    </div>
  );
};

export default Submit;
