import { gql } from '@apollo/client';
import { SERVING_CONVERSIONS_SCHEMA } from './food';

export const USER_FOOD_TRACKED = `
  id
  fat
  meal
  fiber
  foodId
  userId
  protein
  recipeId
  loggedAt
  foodName
  netCarbs
  calories
  brandName
  verboseName
  food {
    ${SERVING_CONVERSIONS_SCHEMA}
  }
  servingSize
  servingUnit
  userRecipeId
  isAtkinsRecipe
  originalServingSize
  isUserDefinedRecipe
`;

export const USER_FOOD_TRACKEDS_SCHEMA = `
  userFoodTrackeds(where: { userId: { equalTo: $userId }, loggedAt: {
    equalTo: $date
  }}) {
    edges {
      node {
        ${USER_FOOD_TRACKED}
      }
    }
  }
`;

export const USER_FOOD_TRACKEDS_DAY_SCHEMA = `
  userFoodTrackeds(where: { userId: { equalTo: $userId }, loggedAt: {
    greaterThanOrEqualTo: $dateStart, lessThanOrEqualTo: $dateEnd
  }}) {
    edges {
      node {
        ${USER_FOOD_TRACKED}
      }
    }
  }
`;

export const USER_FOOD_TRACKEDS_WHERE_SCHEMA = `
  userFoodTrackeds(where: $where) {
    edges {
      node {
        ${USER_FOOD_TRACKED}
      }
    }
  }
`;

export const USER_FOOD_TRACKEDS_WHERE_SCHEMA_ORDERED = `
  userFoodTrackeds(
    order: [loggedAt_ASC]
    where: $where
  ) {
    edges {
      node {
        ${USER_FOOD_TRACKED}
      }
    }
  }
`;

export const GET_USER_FOOD_TRACKEDS_WHERE_QUERY = gql`
  query getUserFoodTrackeds($where: UserFoodTrackedWhereInput!) {
    ${USER_FOOD_TRACKEDS_WHERE_SCHEMA}
  }
`;

export const GET_USER_FOOD_TRACKEDS_WHERE_QUERY_ORDERED = gql`
  query getUserFoodTrackeds($where: UserFoodTrackedWhereInput!) {
    ${USER_FOOD_TRACKEDS_WHERE_SCHEMA_ORDERED}
  }
`;

export const GET_USER_FOOD_TRACKEDS_QUERY = gql`
  query getUserFoodTrackeds($userId: String!, $date: Date!) {
    ${USER_FOOD_TRACKEDS_SCHEMA}
  }
`;

export const GET_USER_FOOD_TRACKEDS_QUERY_DAY = gql`
  query getUserFoodTrackeds($userId: String!, $dateStart: Date!, $dateEnd: Date!) {
    ${USER_FOOD_TRACKEDS_DAY_SCHEMA}
  }
`;

export const CREATE_USER_FOOD_TRACKED = gql`
  mutation createUserFoodTracked($fields: CreateUserFoodTrackedFieldsInput!) {
    createUserFoodTracked(input: { fields: $fields }) {
      userFoodTracked {
        ${USER_FOOD_TRACKED}
      }
    }
  }
`;

export const UPDATE_USER_FOOD_TRACKED = gql`
  mutation updateUserFoodTracked($id: ID!, $fields: UpdateUserFoodTrackedFieldsInput!) {
    updateUserFoodTracked(input: { id: $id, fields: $fields }) {
      userFoodTracked {
        ${USER_FOOD_TRACKED}
      }
    }
  }
`;

export const DELETE_USER_FOOD_TRACKED = gql`
  mutation($id: ID!) {
    deleteUserFoodTracked(input: { id: $id }) {
      userFoodTracked {
        id
      }
    }
  }
`;
