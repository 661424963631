import { Button } from 'react-bootstrap';

import Card from 'components/ui/Card';
import TrackerTitle from '../tabs/TrackerTitle';

import useModal, { ModalType } from 'hooks/useModal';

import { TRACKER_TITLES } from 'constants/index';
import CardInner from 'components/ui/CardInner';

import WeightImg from 'assets/bg-weight.png';

const MyWeightNewMeasurements = () => {
  const { show: showModal } = useModal();

  const handleTrackMeasurements = () => {
    window.dataLayer.push({
      event: 'form_start',
      form_name: 'track-measurement-form',
      engagement_type: 'tracker usage',
      engagement_name: 'measurement',
    });
    showModal(ModalType.trackMeasurements);
  };

  return (
    <Card>
      <CardInner backgroundImage={WeightImg}>
        <TrackerTitle values={TRACKER_TITLES.MEASUREMENTS} />

        <div className="mt-26">
          <Button onClick={handleTrackMeasurements}>Track Measurements</Button>
        </div>
      </CardInner>
    </Card>
  );
};

export default MyWeightNewMeasurements;
