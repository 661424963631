import { useEffect } from 'react';

import InlineValueItem from './ui/InlineValueItem';
import CardDataGoals from './ui/CardDataGoals';

import { useLogFood } from 'shared/src/store';

import useModal, { ModalType } from 'hooks/useModal';

import useDate from 'shared/src/hooks/useDate';
import useDailyNutrients from 'shared/src/hooks/useDailyNutrients';

import { TDailyNutrients } from 'shared/src/types';

import { MACROS } from 'constants/index';

import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

const Macros = ({
  data: { macros },
  variant,
}: {
  data: TDailyNutrients;
  variant: string;
}) => {
  const gridClass = variant === 'portrait' ? 'col-md-12' : 'col-md';

  return (
    <div className="row">
      {MACROS.map((name) => (
        <InlineValueItem
          title={name}
          containerStyles={gridClass}
          key={`daily-nutrient-${name}`}
          value={macros[name as keyof typeof macros].value?.toString() ?? ''}
          subtitle={
            macros[name as keyof typeof macros].dailyGoal?.toString() ?? ''
          }
        />
      ))}
    </div>
  );
};

export interface DailyNutritionValuesProps {
  variant?: 'landscape' | 'portrait';
}

const DailyNutritionValues = ({
  variant = 'landscape',
}: DailyNutritionValuesProps) => {
  const { date } = useDate();
  const {
    query: { data, refetch },
  } = useLogFood(true);

  useEffect(() => {
    const dateStart = startOfDay(date);
    const dateEnd = endOfDay(date);
    refetch({
      dateStart,
      dateEnd,
    });
  }, [date]);

  const { show: showModal } = useModal();
  const computedMacros = useDailyNutrients(data);

  const isProtrait = variant === 'portrait';
  const containerClass = isProtrait ? 'd-flex flex-column-reverse' : '';
  const gridClass = isProtrait ? 'col-md-12' : 'col-md-4';
  const gridContainerClass = isProtrait ? 'row mb-6' : 'row mb-0';

  const link = isProtrait
    ? {
        text: 'How Are Atkins Net Carbs Calculated?',
        callback: () => showModal(ModalType.atkinsNetCarbs),
      }
    : undefined;

  return (
    <div className={containerClass}>
      <Macros data={computedMacros} variant={variant} />
      <CardDataGoals
        config={{
          link,
          gridClass,
          gridContainerClass,
          subtitle: computedMacros.carbs.goal,
          title: {
            text: 'Net Carbs Consumed',
            value: computedMacros.carbs.consumed,
          },
          percentage: {
            min: computedMacros.carbs.ncLow,
            max: computedMacros.carbs.ncHight,
            value: computedMacros.carbs.percentage,
          },
        }}
      />
    </div>
  );
};

export default DailyNutritionValues;
