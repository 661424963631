import { useState } from 'react';

import omit from 'lodash/omit';

import useAuth from '../../hooks/useAuth';

import useApolloCrud from '../../hooks/useApolloCrudv2';
import { TAny, IGrouping, TGraphQLList, TGraphQLResponse } from '../../types';
import {
  CREATE_USER_FOOD_GROUPING,
  UPDATE_USER_FOOD_GROUPING,
  DELETE_USER_FOOD_GROUPING,
  GET_USER_FOOD_GROUPINGS_WHERE,
} from '../../graphql/userFoodGrouping';

type TQuery = TGraphQLResponse<'userFoodGroupings', TGraphQLList<IGrouping>>;

const useFoodGrouping = (preloadQuery = false, limit = 14) => {
  const { user } = useAuth();
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const { query, mutations } = useApolloCrud<TQuery, IGrouping>({
    skipFirstQuery: !preloadQuery,
    queryNode: GET_USER_FOOD_GROUPINGS_WHERE,
    createMutationNode: CREATE_USER_FOOD_GROUPING,
    updateMutationNode: UPDATE_USER_FOOD_GROUPING,
    removeMutationNode: DELETE_USER_FOOD_GROUPING,
    pointerName: 'userFoodGroupings',
    queryOptions: {
      variables: {
        first: limit,
        order: ['groupName_ASC'],
        where: {
          userId: { equalTo: user.objectId },
        },
      },

      nextFetchPolicy: 'cache-first',
      fetchPolicy: 'cache-and-network',
    },
  });

  const save = async (group: Partial<TAny>) => {
    const action = group.id ? mutations.update.call : mutations.create.call;

    const { data } = await action({
      variables: {
        ...(group.id && { id: group.id }),
        fields: omit(group, ['id']),
      },
    });

    const name = Object.keys(data)[0];
    return data[name].userFoodGrouping;
  };

  const removeById = async (id: string) => {
    if (mutations.remove.loading) {
      return;
    }

    return mutations.remove.call({
      variables: {
        id,
      },
      optimisticResponse: {
        deleteUserFoodGrouping: {
          userFoodGroupings: {
            id,
          },
        },
      },
    });
  };

  const search = async (term?: string, first = limit) => {
    let termWhere = {};
    if (term && typeof term === 'string') {
      if (term.trim() !== '') {
        termWhere = {
          groupName: { text: { search: { term } } },
        };
      }
    }

    return mutations.get.call({
      first,
      order: ['groupName_ASC'],
      where: {
        ...termWhere,
        userId: { equalTo: user.objectId },
      },
    });
  };

  const onEndReached = async () => {
    if (isFetchingMore || !query.pageInfo.hasNextPage) {
      return;
    }

    setIsFetchingMore(true);

    query.fetchMore &&
      (await query.fetchMore({
        variables: {
          after: query.pageInfo.endCursor,
        },
      }));

    setIsFetchingMore(false);
  };

  return {
    ...query,
    save,
    search,
    mutations,
    removeById,
    onEndReached,
    isFetchingMore,
  };
};

export default useFoodGrouping;
