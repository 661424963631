import Card from './ui/Card';

interface ProTipProps {
  text: string;
  title?: string;
  button?: {
    label: string;
    callback: () => void;
  };
  backgroundColor?: string;
}

const ProTip = ({
  text,
  button,
  title = 'PRO TIP',
  backgroundColor = 'bg-white-o-100',
}: ProTipProps) => {
  return (
    <Card size="small" styles={backgroundColor}>
      <div>
        {title !== '' && <h6 className="text-primary">{title}</h6>}
        <span className="text-primary">{text}</span>
      </div>
      {button && (
        <a
          className="btn btn-link text-dark text-decoration-underline p-0 mt-7"
          onClick={button.callback}>
          {button.label}
        </a>
      )}
    </Card>
  );
};

export default ProTip;
