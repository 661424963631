import pick from 'lodash/pick';
import { useMutation } from '@apollo/client';

import useAuth from '../../hooks/useAuth';
import useUserAssessment from '../../hooks/useUserAssessment';

import { UPDATE_MUTATION } from '../../graphql/user';

import { IUser } from '../../types';

const useUser = () => {
  const {
    user,
    setUser,
    resetPassword: sendResetPassword,
    isLoadingResetPasswordMutation: isResettingPassword,
  } = useAuth();
  const [mutateUpdateUser, { loading: isSaving }] = useMutation(
    UPDATE_MUTATION
  );
  const { genderAssesment, updateGender } = useUserAssessment();

  const userEditable = pick(user, [
    'id',
    'city',
    'email',
    'address',
    'zipcode',
    'country',
    'lastName',
    'firstName',
  ]) as IUser;

  const resetPassword = async () => {
    return sendResetPassword(user.email);
  };

  const save = async (values: Partial<IUser>, gender?: string) => {
    const newValues = {
      ...values,
      zipcode: values?.zipcode?.toString() ?? '',
    } as IUser;

    try {
      if (gender) {
        await updateGender({
          variables: {
            id: genderAssesment.objectId,
            fields: {
              value: gender,
            },
          },
          refetchQueries: ['userAssessments'],
        });
      }

      await mutateUpdateUser({
        variables: newValues,
      });

      setUser({
        ...user,
        ...newValues,
      });
    } catch (error) {
      const { message } = error as Error;
      throw new Error(message);
    }
  };

  return { save, isSaving, userEditable, resetPassword, isResettingPassword };
};

export default useUser;
