import AssessmentChoice from 'components/assessment/steps/AssessmentChoice';
import AssessmentHeight from 'components/assessment/steps/AssessmentHeight';
import AssessmentGender from 'components/assessment/steps/AssessmentGender';
import AssessmentNumeric from 'components/assessment/steps/AssessmentNumeric';

export default {
  age: AssessmentNumeric,
  height: AssessmentHeight,
  gender: AssessmentGender,
  weight: AssessmentNumeric,
  diabetes: AssessmentChoice,
  pregnant: AssessmentChoice,
  programPreference: AssessmentChoice,
  desiredWeightLost: AssessmentNumeric,
  waistCircumference: AssessmentNumeric,
};
