import { useState } from 'react';

import omit from 'lodash/omit';

import useAuth from '../../hooks/useAuth';

import useApolloCrud from '../../hooks/useApolloCrudv2';
import { IUserFood, TGraphQLList, TGraphQLResponse } from '../../types';
import {
  GET_USER_FOODS,
  CREATE_USER_FOOD,
  UPDATE_USER_FOOD,
  DELETE_USER_FOOD,
} from '../../graphql/userFood';

type TQuery = TGraphQLResponse<'userFoods', TGraphQLList<IUserFood>>;
const useUserFood = (preloadQuery = false, limit = 14) => {
  const { user } = useAuth();
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const { query, mutations } = useApolloCrud<TQuery, IUserFood>({
    skipFirstQuery: !preloadQuery,
    queryNode: GET_USER_FOODS,
    createMutationNode: CREATE_USER_FOOD,
    updateMutationNode: UPDATE_USER_FOOD,
    removeMutationNode: DELETE_USER_FOOD,
    pointerName: 'userFoods',
    queryOptions: {
      variables: {
        first: limit,
        order: ['name_ASC'],
        where: {
          userId: { equalTo: user.objectId },
        },
      },
      nextFetchPolicy: 'cache-first',
      fetchPolicy: 'cache-and-network',
    },
  });

  const onEndReached = async () => {
    if (isFetchingMore || !query.pageInfo?.hasNextPage) {
      return;
    }

    setIsFetchingMore(true);

    await query.fetchMore({
      variables: {
        after: query.pageInfo!.endCursor,
      },
    });

    setIsFetchingMore(false);
  };

  const search = async (term?: string, first = limit) => {
    let termWhere = {};
    if (term && typeof term === 'string') {
      if (term.trim() !== '') {
        termWhere = {
          name: { text: { search: { term } } },
        };
      }
    }

    return mutations.get.call({
      first,
      order: ['name_ASC'],
      where: {
        ...termWhere,
        userId: { equalTo: user.objectId },
      },
    });
  };

  const removeById = async (id: string) => {
    if (mutations.remove.loading) {
      return;
    }

    return mutations.remove.call({
      variables: {
        id,
      },
      optimisticResponse: {
        deleteUserFood: {
          userFood: {
            id,
          },
        },
      },
    });
  };

  const save = async (food: Partial<IUserFood>) => {
    const action = food.id ? mutations.update.call : mutations.create.call;

    const { data } = await action({
      variables: {
        ...(food.id && { id: food.id }),
        fields: omit(food, ['id']),
      },
    });

    const name = Object.keys(data)[0];
    return data[name].userFood;
  };

  return {
    ...query,
    search,
    save,
    mutations,
    removeById,
    onEndReached,
    isFetchingMore,
  };
};

export default useUserFood;
