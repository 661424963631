import { gql } from '@apollo/client';

export const LOG_WATER_SCHEMA = `
  id
  cups
  userId
  loggedAt
`;

export const LOG_WATERS_WHERE_SCHEMA = `
  logWaters(where: $where) {
    edges {
      node {
        ${LOG_WATER_SCHEMA}
      }
    }
  }
`;

export const LOG_WATERS_SCHEMA = `
  logWaters(where: { userId: { equalTo: $userId }, loggedAt: {
    equalTo: $date
  }}) {
    edges {
      node {
        ${LOG_WATER_SCHEMA}
      }
    }
  }
`;

export const GET_LOG_WATERS_QUERY = gql`
  query getLogWaters($userId: String!, $date: Date!) {
    ${LOG_WATERS_SCHEMA}
  }
`;

export const GET_LOG_WATERS_WHERE_QUERY = gql`
  query getLogWaters($where: LogWaterWhereInput!) {
    ${LOG_WATERS_WHERE_SCHEMA}
  }
`;

export const CREATE_LOG_WATER = gql`
  mutation createLogWater($fields: CreateLogWaterFieldsInput!) {
    createLogWater(input: { fields: $fields }) {
      logWater {
        ${LOG_WATER_SCHEMA}
      }
    }
  }
`;

export const UPDATE_LOG_WATER = gql`
  mutation updateLogWater($id: ID!, $cups: Float!) {
    updateLogWater(input: { id: $id, fields: { cups: $cups } }) {
      logWater {
        ${LOG_WATER_SCHEMA}
      }
    }
  }
`;
