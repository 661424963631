import Card from 'components/ui/Card';
import CardInner from 'components/ui/CardInner';
import CardTitle from 'components/ui/CardTitle';
import WaterCupCounter from 'components/WaterCupCounter';
import TrackerTitle from 'components/tracker/tabs/TrackerTitle';

import ProTip from 'components/ProTip';

import { TRACKER_TITLES } from 'constants/index';

import WaterImg from 'assets/bg-water.png';
import BottleImg from 'assets/icons/bottle-blue.svg';

const TrackerWater = () => {
  return (
    <>
      <div className="row">
        <div className="col-xl-8">
          <Card>
            <CardTitle
              title="My water"
              icon={BottleImg}
              subtitle="Track how many cups of water you drink daily."
            />

            <CardInner backgroundImage={WaterImg}>
              <TrackerTitle values={TRACKER_TITLES.WATER} />

              <div className="max-w-300px">
                <ProTip text="We recommend at least 8 cups a day." />
                <WaterCupCounter variant="light" />
              </div>
            </CardInner>
          </Card>
        </div>
      </div>
    </>
  );
};

export default TrackerWater;
