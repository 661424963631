import { toast } from 'react-toastify';

import Card from 'components/ui/Card';
import CardTitle from 'components/ui/CardTitle';

import Button from 'shared/src/components/web/Button';

import { useUser } from 'shared/src/store';

import DietaryImg from 'assets/icons/dietary-blue.svg';

const ProfileChangePassword = () => {
  const { resetPassword, isResettingPassword } = useUser();

  const handleResetPassword = async () => {
    try {
      await resetPassword();
      toast.success('Email sent');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <Card>
          <CardTitle title="Change Password" icon={DietaryImg} />

          <div className="p-4 bg-gray-100 rounded">
            <div>
              <div className="text-primary mb-1">
                <strong>RESET PASSWORD</strong>
              </div>
              <div className="text-primary mb-6">
                We will send an email to your email address associated with this
                account with a link to reset your password.
              </div>
            </div>
            <Button
              textWorking="Sending"
              text="Send password reset email"
              working={isResettingPassword}
              onClick={handleResetPassword}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ProfileChangePassword;
