interface CardTextfieldProps {
  title: string;
  subtitle: string;
  containerStyles?: string;
  size?: 'regular' | 'small';
  variant?: 'regular' | 'light';
}

const CardTextfield = ({
  title,
  subtitle,
  size = 'regular',
  variant = 'regular',
  containerStyles = '',
}: CardTextfieldProps) => {
  const whiteStyle = variant === 'light' ? 'bg-white' : '';
  const containerClass = size === 'small' ? ' badge--small' : '';

  return (
    <div className={`d-flex ${containerStyles}`}>
      <div
        className={`badge badge-light${containerClass} text-primary py-4 px-8 ${whiteStyle}`}>
        <div className="font-weight-boldest text-48px">{title}</div>
        <div>{subtitle}</div>
      </div>
    </div>
  );
};

export default CardTextfield;
