import { gql } from '@apollo/client';

export const SERVING_UNIT_SCHEMA = `
  id
  name
`;

export const SERVING_UNITS_SCHEMA = `
  servingUnits {
    edges {
      node {
        ${SERVING_UNIT_SCHEMA}
      }
    }
  }
`;

export const GET_SERVING_UNITS_QUERY = gql`
  query getServingUnits {
    ${SERVING_UNIT_SCHEMA}
  }
`;
