import React from 'react';
import globalHook, { Store } from 'use-global-hook';

type State = {
  q: string;
};

type Actions = {
  setQ: (term: string) => void;
};

const initialState = {
  q: '',
};

const globalActions = {
  setQ: (store: Store<State, Actions>, term: string) => {
    store.setState({
      q: term,
    });
  },
};

const useGlobal = globalHook<State, Actions>(
  React,
  initialState,
  globalActions
);

const useSearch = () => {
  const [state, actions] = useGlobal();

  return {
    q: state.q,
    setQ: actions.setQ,
  };
};

export default useSearch;
