import { useCallback, useEffect, useState } from 'react';

import get from 'lodash/get';
import pick from 'lodash/pick';
import { toGlobalId } from 'graphql-relay';
import { useLazyQuery, useQuery } from '@apollo/client';

import useAuth from '../../hooks/useAuth';
import useApiConstants from '../../hooks/useApiConstants';

import {
  TAny,
  IRecipe,
  IObject,
  TGraphQLList,
  IFavoriteFood,
  TGraphQLResponse,
} from '../../types';

import {
  GET_RECIPE_QUERY,
  GET_RECIPES_QUERY,
  GET_ATLAS_RECIPES_QUERY,
} from '../../graphql/recipe';
import { GET_USER_FOOD_FAVORITES } from '../../graphql/userFoodFavorite';
import { GET_USER_KEY_INGREDIENT_EXCLUSION } from '../../graphql/UserKeyIngredientExclusion';

import QUERY_RESULT from '../../constants/queryResult';
import PUBLISH_FILTER from '../../constants/publishFilter';

export type ICollection = 'search' | 'featureds' | 'favorites';

interface IRecipeById {
  id?: string;
  legacyId?: number;
}

const phasesConversions = {
  '1': ['1'],
  '2': ['1', '2'],
  '3': ['1', '2', '3'],
  '4': [],
};

const useRecipe = (preloadCollections?: ICollection[], limit = 14) => {
  const { user } = useAuth();
  const [searchPage, setSearchPage] = useState(0);
  const [isLoading, setisLoading] = useState(true);
  const [favoritesPage, setFavoritesPage] = useState(0);
  const [featuredsPage, setFeaturedsPage] = useState(0);
  const [isFetchingMoreFavs, setisFetchingMoreFavs] = useState(false);
  const [isFetchingMoreFeats, setisFetchingMoreFeats] = useState(false);
  const [isFetchingMoreSearch, setIsFetchingMoreSearch] = useState(false);

  /* TODO: Move into a new Store */
  const { phases } = useApiConstants();

  const phaseLegacyId = user.program?.phaseId ?? '1';

  let phasesIds: TAny;
  try {
    const phasesConversionsFound = get(phasesConversions, phaseLegacyId);
    phasesIds = phases
      .filter(
        ({ legacyId }) =>
          phasesConversionsFound.indexOf(legacyId.toString()) !== -1
      )
      .map(({ objectId }) => objectId);

    const is40100 = user.program.name.toLowerCase().indexOf('atkins') !== -1;

    if (is40100) {
      phasesIds = null;
    }
  } catch {
    phasesIds = null;
  }

  const { data: userExclusions } = useQuery(GET_USER_KEY_INGREDIENT_EXCLUSION, {
    variables: {
      userId: user.objectId,
    },
  });

  const exclusions = get(
    userExclusions,
    'userKeyIngredientExclusions.edges',
    []
  ).map(({ node }: TAny) => node.exclusion);
  /* END TODO */

  const [getRecipes, recipesData] = useLazyQuery<
    TGraphQLResponse<'atlasRecipes', TGraphQLList<IRecipe>>
  >(GET_ATLAS_RECIPES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      skip: 0,
      first: limit,
      ...PUBLISH_FILTER,
    },
  });

  const [getFeatureds, featData] = useLazyQuery<
    TGraphQLResponse<'recipes', TGraphQLList<IRecipe>>
  >(GET_RECIPES_QUERY, {
    variables: {
      skip: 0,
      first: limit,
      detailed: false,
      where: { featured: { equalTo: true } },
    },
  });

  const [getFavorites, favData] = useLazyQuery<
    TGraphQLResponse<'userFoodFavorites', TGraphQLList<IFavoriteFood>>
  >(GET_USER_FOOD_FAVORITES, {
    variables: {
      skip: 0,
      first: limit,
      order: ['name_ASC'],
      where: {
        recipeId: { greaterThan: '1' },
        userId: { equalTo: user.objectId },
      },
    },
  });

  const [_getRecipeById, byIdData] = useLazyQuery<
    TGraphQLResponse<'recipes', TGraphQLList<IRecipe>>
  >(GET_RECIPE_QUERY);

  useEffect(() => {
    (async () => {
      if (preloadCollections) {
        for (const collection of preloadCollections) {
          switch (collection) {
            case 'search':
              await getRecipes();
              break;
            case 'featureds':
              await getFeatureds();
              break;
            case 'favorites':
              await getFavorites();
              break;
          }
        }
        setisLoading(false);
      }
    })();
  }, []);

  const recipes = recipesData.data?.atlasRecipes.edges.map(({ node }) => node);
  const featureds = featData.data?.recipes.edges.map(({ node }) => node);
  const favorites = favData.data?.userFoodFavorites.edges.map(({ node }) => ({
    ...node,
    id: toGlobalId('Recipe', node.recipeId),
    objectId: node.recipeId,
  }));

  const searchPageInfo = recipesData.data?.atlasRecipes.pageInfo;
  const featuredsPageInfo = featData.data?.recipes.pageInfo;
  const favoritesPageInfo = favData.data?.userFoodFavorites.pageInfo;

  const searchCount = recipesData.data?.atlasRecipes.count;
  const featuredsCount = featData.data?.recipes.count;
  const favoritesCount = favData.data?.userFoodFavorites.count;

  const recipe = byIdData.data?.recipes?.edges?.map(({ node }) => node)[0];

  const isFetchingMore =
    isFetchingMoreFavs || isFetchingMoreFeats || isFetchingMoreSearch;

  const getSearchVariables = useCallback(
    (q = '', skip = 0) => {
      let keyIngredients: IObject = {
        keyIngredients: {
          have: {
            legacyId: { notIn: exclusions },
          },
        },
      };

      if (!exclusions.length) {
        keyIngredients = {};
      }

      let phaseWhere = {};
      if (phasesIds && phasesIds.length) {
        phaseWhere = {
          phase: { have: { id: { in: phasesIds } } },
        };
      }

      return {
        variables: {
          skip,
          first: limit,
          detailed: false,
          where: {
            name: { text: { search: { term: q } } },
            ...phaseWhere,
            ...keyIngredients,
            ...PUBLISH_FILTER,
          },
        },
      };
    },
    [exclusions, phasesIds]
  );

  const onEndReached = async (item: 'search' | 'featureds' | 'favorites') => {
    let page = favoritesPage;
    let setPage = setFavoritesPage;
    let pageInfo = favoritesPageInfo;
    let fetchMore = favData.fetchMore!;
    let isFetchingMore = isFetchingMoreFavs;
    let setIsFetchingMore = setisFetchingMoreFavs;

    switch (item) {
      case 'search':
        page = searchPage;
        setPage = setSearchPage;
        pageInfo = searchPageInfo;
        fetchMore = recipesData.fetchMore!;
        isFetchingMore = isFetchingMoreSearch;
        setIsFetchingMore = setIsFetchingMoreSearch;
        break;
      case 'featureds':
        page = featuredsPage;
        setPage = setFeaturedsPage;
        pageInfo = featuredsPageInfo;
        fetchMore = featData.fetchMore!;
        isFetchingMore = isFetchingMoreFeats;
        setIsFetchingMore = setisFetchingMoreFeats;
    }

    if (isFetchingMore || !pageInfo?.hasNextPage) {
      return;
    }

    const newPage = page + 1;
    setPage(newPage);
    setIsFetchingMore(true);

    await fetchMore({
      variables: {
        skip: newPage * limit,
      },
    });

    setIsFetchingMore(false);
  };

  const getRecipeById = async ({ id, legacyId }: IRecipeById) => {
    if (!id && !legacyId) {
      return;
    }

    const variables: IRecipeById = {};

    id && (variables.id = id);
    legacyId && (variables.legacyId = legacyId);

    return _getRecipeById({
      variables,
    });
  };

  return {
    recipe: {
      ...pick(byIdData, QUERY_RESULT),
      get: getRecipeById,
      data: recipe,
    },
    featureds: {
      ...pick(featData, QUERY_RESULT),
      get: getFeatureds,
      data: featureds || [],
      count: featuredsCount,
      pageInfo: featuredsPageInfo,
    },
    favorites: {
      ...pick(favData, QUERY_RESULT),
      get: getFavorites,
      data: favorites || [],
      count: favoritesCount,
      pageInfo: favoritesPageInfo,
    },
    search: {
      ...pick(recipesData, QUERY_RESULT),
      get: getRecipes,
      data: recipes || [],
      count: searchCount,
      pageInfo: searchPageInfo,
    },
    onEndReached,
    isFetchingMore,
    getSearchVariables,
    exclusions,
    pages: {
      searchPage,
      favoritesPage,
      featuredsPage,

      setSearchPage,
      setFavoritesPage,
      setFeaturedsPage,
    },
    isAutoLoadLoading: preloadCollections && isLoading,
  };
};

export default useRecipe;
