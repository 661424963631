import { gql } from '@apollo/client';

export const NUTRITIONAL_RANGE_SCHEMA = `
  id
  men
  women
  program {
    id
    objectId
  }
  objectId
`;

export const NUTRITIONAL_RANGES_SCHEMA = `
  nutritionalRanges {
    edges {
      node {
        ${NUTRITIONAL_RANGE_SCHEMA}
      }
    }
  }
`;

export const GET_NUTRITIONAL_RANGES_QUERY = gql`
  query getNutritionalRanges {
    ${NUTRITIONAL_RANGES_SCHEMA}
  }
`;
