import { useState } from 'react';

import pick from 'lodash/pick';
import { useQuery } from '@apollo/client';
import startOfDay from 'date-fns/startOfDay';

import useAuth from '../../hooks/useAuth';

import { GET_LOGS } from '../../graphql/log';
import useApolloCrud from '../../hooks/useApolloCrudv2';
import useReportVariables from '../../hooks/useReportVariables';

import { updateCache } from '../../utils/GraphqlHelper';
import { TAny, ILogWater, TGraphQLList, TGraphQLResponse } from '../../types';
import {
  CREATE_LOG_WATER,
  UPDATE_LOG_WATER,
  GET_LOG_WATERS_QUERY,
  GET_LOG_WATERS_WHERE_QUERY,
} from '../../graphql/logWater';

import QUERY_RESULT from '../../constants/queryResult';

type TQuery = TGraphQLResponse<'logWaters', TGraphQLList<ILogWater>>;

const today = startOfDay(new Date());

const useLogWater = (preloadQuery = false) => {
  const { user } = useAuth();
  const reportVariables = useReportVariables();

  const [queryVariables, setQueryVariables] = useState({
    userId: user.objectId,
    date: today.toISOString(),
  });

  const { query, mutations } = useApolloCrud<TQuery, ILogWater>({
    skipFirstQuery: !preloadQuery,
    queryNode: GET_LOG_WATERS_QUERY,
    createMutationNode: CREATE_LOG_WATER,
    updateMutationNode: UPDATE_LOG_WATER,
    pointerName: 'logWaters',
    queryOptions: {
      variables: queryVariables,

      nextFetchPolicy: 'cache-first',
      fetchPolicy: 'cache-and-network',
    },
  });

  const yearHistorical = useQuery<TQuery>(GET_LOG_WATERS_WHERE_QUERY, {
    variables: reportVariables,
  });

  const yearHistoricalData = yearHistorical?.data?.logWaters?.edges?.map(
    ({ node }) => node
  );

  const save = async (log: TAny, cups: number, date: Date) => {
    const variables = {
      userId: user.objectId,
      date: date.toISOString(),
    };

    if (log.id) {
      return mutations.update.call({
        variables: {
          id: log.id,
          cups,
        },
        update: (query, document) => {
          updateCache(GET_LOGS, variables)(query, document);
          updateCache(GET_LOG_WATERS_QUERY, variables)(query, document);
          updateCache(GET_LOG_WATERS_WHERE_QUERY, reportVariables)(
            query,
            document
          );
        },
        optimisticResponse: {
          updateLogWater: {
            logWater: {
              ...log,
              cups,
            },
          },
        },
      });
    }

    return mutations.create.call({
      variables: {
        fields: {
          userId: user.objectId,
          loggedAt: date.toISOString(),
          cups,
        },
      },
      update: (cache, qdata) => {
        updateCache(GET_LOGS, variables)(cache, qdata);
        updateCache(GET_LOG_WATERS_QUERY, variables)(cache, qdata);
      },
      optimisticResponse: {
        createLogWater: {
          logWater: {
            cups,
            userId: user.objectId,
            loggedAt: date.toISOString(),
          },
        },
      },
    });
  };

  return {
    save,
    query,
    mutations,
    setQueryVariables,

    yearHistorical: {
      ...pick(yearHistorical, QUERY_RESULT),
      _data: yearHistorical?.data,
      data: yearHistoricalData,
    },
  };
};

export default useLogWater;
