import get from 'lodash/get';
import { IObject } from '../types';

export const getFirstDeep = (base: IObject, howDeep = 0) => {
  try {
    let nextValue: string[] = Object.keys(base);
    let foundCollection = nextValue[0];
    let lastName = foundCollection;
    howDeep = howDeep - 1;

    for (let i = 0; i < howDeep; i++) {
      const value = get(base, foundCollection);
      lastName = nextValue[0];
      nextValue = Object.keys(value);

      if (Array.isArray(nextValue) && nextValue.length) {
        foundCollection += `.${nextValue[0]}`;
      }
    }

    return {
      value: get(base, foundCollection),
      name: lastName,
    };
  } catch {
    return { value: null, name: '' };
  }
};

export const hasObjectChild = (base: IObject) => {
  let hasChild = false;
  Object.keys(base).forEach((key) => {
    if (typeof base[key] === 'object') {
      hasChild = true;
    }
  });

  return hasChild;
};

export const areEqual = (a: IObject, b: IObject) => {
  return JSON.stringify(a) === JSON.stringify(b);
};
