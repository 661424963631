import ReactDOM from 'react-dom';
import { StrictMode } from 'react';

import 'shared/src/metronic/theme/plugins/flaticon/flaticon.css';
import 'shared/src/metronic/theme/plugins/flaticon2/flaticon.css';
import 'shared/src/metronic/theme/plugins/keenthemes-icons/font/ki.css';

import App from './App';

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);
