import get from 'lodash/get';
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import { PHASES_SCHEMA } from '../graphql/phase';
import { PROGRAMS_SCHEMA } from '../graphql/program';
import { DIFFICULTIES_SCHEMA } from '../graphql/difficulty';
import { FOOD_CATEGORIES_SCHEMA } from '../graphql/category';
import { RECIPE_STYLES_SCHEMA } from '../graphql/recipeStyle';
import { SERVING_UNITS_SCHEMA } from '../graphql/servingUnit';
import { RECIPE_COURSES_SCHEMA } from '../graphql/recipeCourse';
import { RECIPE_STATUSES_SCHEMA } from '../graphql/recipeStatus';
import { RECIPE_DISH_TYPES_SCHEMA } from '../graphql/recipeDishType';
import { PUBLISHING_STATUSES_SCHEMA } from '../graphql/publishingStatus';
import { RECIPE_SPECIALITIES_SCHEMA } from '../graphql/recipeSpeciality';
import { NUTRITIONAL_RANGES_SCHEMA } from '../graphql/nutritionalRanges';
import { RECIPE_COOKING_METHODS_SCHEMA } from '../graphql/recipeCookingMethod';
import { RECIPE_KEY_INGREDIENTS_SCHEMA } from '../graphql/recipeKeyIngredient';

import { IApiConstant, IObject, TAny } from '../types';

const IDS = [
  'phases',
  'programs',
  'difficulties',
  'servingUnits',
  'recipeStyles',
  'recipeCourses',
  'foodCategories',
  'recipeStatuses',
  'recipeDishTypes',
  'nutritionalRanges',
  'publishingStatuses',
  'recipeSpecialities',
  'recipeKeyIngredients',
  'recipeCookingMethods',
];

export const GET_ALL_CONSTANTS = gql`
  query getAllConstants {
    ${PHASES_SCHEMA}
    ${PROGRAMS_SCHEMA}
    ${DIFFICULTIES_SCHEMA}
    ${RECIPE_STYLES_SCHEMA}
    ${SERVING_UNITS_SCHEMA}
    ${RECIPE_COURSES_SCHEMA}
    ${FOOD_CATEGORIES_SCHEMA}
    ${RECIPE_STATUSES_SCHEMA}
    ${RECIPE_DISH_TYPES_SCHEMA}
    ${NUTRITIONAL_RANGES_SCHEMA}
    ${PUBLISHING_STATUSES_SCHEMA}
    ${RECIPE_SPECIALITIES_SCHEMA}
    ${RECIPE_COOKING_METHODS_SCHEMA}
    ${RECIPE_KEY_INGREDIENTS_SCHEMA}
  }
`;

interface IData {
  id: string;
  name: string;
  [key: string]: TAny;
}
export interface IQueryData {
  node: IData;
}
interface IApiConstants {
  phases: IData[];
  programs: IData[];
  difficulties: IData[];
  recipeStyles: IData[];
  servingUnits: IData[];
  recipeCourses: IData[];
  foodCategories: IData[];
  recipeStatuses: IData[];
  recipeDishTypes: IData[];
  nutritionalRanges: IData[];
  publishingStatuses: IData[];
  recipeSpecialities: IData[];
  recipeKeyIngredients: IData[];
  recipeCookingMethods: IData[];

  phasesIds: string[];
  programsIds: string[];
  difficultiesIds: string[];
  recipeStylesIds: string[];
  servingUnitsIds: string[];
  recipeCoursesIds: string[];
  foodCategoriesIds: string[];
  recipeStatusesIds: string[];
  recipeDishTypesIds: string[];
  nutritionalRangesIds: string[];
  publishingStatusesIds: string[];
  recipeSpecialitiesIds: string[];
  recipeKeyIngredientsIds: string[];
  recipeCookingMethodsIds: string[];
}

interface IUseApiConstantsResult extends IApiConstants {
  loading: boolean;
}

const useApiConstants = (): IUseApiConstantsResult => {
  const { data, loading } = useQuery(GET_ALL_CONSTANTS, {
    fetchPolicy: 'cache-first',
  });

  const constants = useMemo(() => {
    const collection: IObject = {};

    IDS.forEach((id) => {
      const edges = get(data, `${id}.edges`, []).map(
        (item: IQueryData) => item.node
      ) as IApiConstant[];

      collection[id as keyof IApiConstants] = edges;
      collection[`${id}Ids`] = edges.map(({ id }) => id);
    });

    return collection as IApiConstants;
  }, [data]);

  return {
    loading,
    ...constants,
  };
};

export default useApiConstants;
