import twitterIcon from 'assets/icons/twitter.svg';
import youtubeIcon from 'assets/icons/youtube.svg';
import facebookIcon from 'assets/icons/facebook.svg';
import pinterestIcon from 'assets/icons/pinterest.svg';

export default [
  {
    label: 'facebook',
    icon: facebookIcon,
    link: 'https://www.facebook.com/AtkinsNutritionals?mcid=hpFacebook',
  },
  {
    label: 'twitter',
    icon: twitterIcon,
    link: 'https://twitter.com/atkinsinsider?mcid=hpTwitter',
  },
  {
    label: 'pinterest',
    icon: pinterestIcon,
    link: 'https://www.pinterest.es/atkinsnutritionals/_created/',
  },
  {
    label: 'youtube',
    icon: youtubeIcon,
    link: 'https://www.youtube.com/user/AtkinsNutritionals',
  },
];
