import { Form, FormCheckProps } from 'react-bootstrap';

const FormCheck = ({ type, label, ...props }: FormCheckProps) => (
  <Form.Check.Label className={`${type} ${type}-lg`}>
    <Form.Check.Input {...props} />
    <Form.Text as="span"></Form.Text>
    {label}
  </Form.Check.Label>
);

export default FormCheck;
