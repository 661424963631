import { gql } from '@apollo/client';

export const LOG_WEIGHT_SCHEMA = `
  id
  units
  userId
  weight
  objectId
  loggedAt
`;

export const LOG_ALL_WEIGHTS_SCHEMA = `
  logWeights(first: $first, where: { userId: { equalTo: $userId }}) {
    edges {
      node {
        ${LOG_WEIGHT_SCHEMA}
      }
    }
  }
`;

export const GET_ALL_LOG_WEIGHTS_QUERY = gql`
  query getLogWeights($userId: String!, $first: Int!) {
    ${LOG_ALL_WEIGHTS_SCHEMA}
  }
`;

export const LOG_WEIGHTS_SCHEMA = `
  logWeights(where: { userId: { equalTo: $userId }, loggedAt: {
    equalTo: $date
  }}) {
    edges {
      node {
        ${LOG_WEIGHT_SCHEMA}
      }
    }
  }
`;

export const LAST_LOG_WEIGHTS_SCHEMA = `
  logWeights(
    first: 1
    order: [loggedAt_DESC]
    where: { userId: { equalTo: $userId } }
  ) {
    edges {
      node {
        ${LOG_WEIGHT_SCHEMA}
      }
    }
  }
`;

export const FIRST_LOG_WEIGHTS_SCHEMA = `
  logWeights(
    first: 1
    order: [loggedAt_ASC]
    where: { userId: { equalTo: $userId } }
  ) {
    edges {
      node {
        ${LOG_WEIGHT_SCHEMA}
      }
    }
  }
`;

export const LOG_WEIGHTS_WHERE_SCHEMA = `
  logWeights(where: $where) {
    edges {
      node {
        ${LOG_WEIGHT_SCHEMA}
      }
    }
  }
`;

export const GET_LOG_WEIGHTS_QUERY = gql`
  query getLogWeights($userId: String!, $date: Date!) {
    ${LOG_WEIGHTS_SCHEMA}
  }
`;

export const GET_LAST_LOG_WEIGHTS_QUERY = gql`
  query getLastLogWeights($userId: String!) {
    ${LAST_LOG_WEIGHTS_SCHEMA}
  }
`;

export const GET_FIRST_LOG_WEIGHTS_QUERY = gql`
  query getLastLogWeights($userId: String!) {
    ${FIRST_LOG_WEIGHTS_SCHEMA}
  }
`;

export const GET_LOG_WEIGHTS_WHERE_QUERY = gql`
  query getLogWeights($where: LogWeightWhereInput!) {
    ${LOG_WEIGHTS_WHERE_SCHEMA}
  }
`;

export const LOG_WEIGHTS_WHERE_SCHEMA_ORDERED = `
  logWeights(
    order: [loggedAt_ASC]
    where: $where
  ) {
    edges {
      node {
        ${LOG_WEIGHT_SCHEMA}
      }
    }
  }
`;

export const GET_LOG_WEIGHTS_WHERE_QUERY_ORDERED = gql`
  query getLogWeights($where: LogWeightWhereInput!) {
    ${LOG_WEIGHTS_WHERE_SCHEMA_ORDERED}
  }
`;

export const CREATE_LOG_WEIGHT = gql`
  mutation createLogWeight($fields: CreateLogWeightFieldsInput!) {
    createLogWeight(input: { fields: $fields }) {
      logWeight {
        ${LOG_WEIGHT_SCHEMA}
      }
    }
  }
`;

export const DELETE_LOG_WEIGHT = gql`
  mutation($id: ID!) {
    deleteLogWeight(input: { id: $id }) {
      logWeight {
        id
      }
    }
  }
`;

export const UPDATE_LOG_WEIGHT = gql`
  mutation updateLogWeight($id: ID!, $weight: Float!, $units: String!) {
    updateLogWeight(input: { id: $id, fields: { weight: $weight, units: $units } }) {
      logWeight {
        ${LOG_WEIGHT_SCHEMA}
      }
    }
  }
`;
