import { gql } from '@apollo/client';

export const USER_KEY_INGREDIENT_EXCLUSION = `
  id
  userId
  exclusion
`;

export const GET_USER_KEY_INGREDIENT_EXCLUSION_SCHEMA = `
  userKeyIngredientExclusions(where: { userId: { equalTo: $userId } } ) {
    edges {
      node {
        ${USER_KEY_INGREDIENT_EXCLUSION}
      }
    }
  }
`;

export const GET_USER_KEY_INGREDIENT_EXCLUSION = gql`
  query getUserKeyIngredientExclusions(
    $userId: String!
  ) {
    ${GET_USER_KEY_INGREDIENT_EXCLUSION_SCHEMA}
  }
`;

export const CREATE_USER_KEY_INGREDIENT_EXCLUSION = gql`
  mutation createUserKeyIngredientExclusion(
    $fields: CreateUserKeyIngredientExclusionFieldsInput!
  ) {
    createUserKeyIngredientExclusion(input: { fields: $fields }) {
      userKeyIngredientExclusion {
        ${USER_KEY_INGREDIENT_EXCLUSION}
      }
    }
  }
`;

export const DELETE_USER_KEY_INGREDIENT_EXCLUSION = gql`
  mutation($id: ID!) {
    deleteUserKeyIngredientExclusion(input: { id: $id }) {
      userKeyIngredientExclusion {
        id
      }
    }
  }
`;
