import { gql } from '@apollo/client';

export const USER_FOOD_GROUPING_FOOD_SCHEMA = `
  id
  fat
  fiber
  userId
  mealId
  groupId
  protein
  objectId
  foodName
  netCarbs
  calories
  servingSize
  servingUnit
  `;

export const USER_FOOD_GROUPING_SCHEMA = `
  id
  userId
  mealId
  objectId
  groupName
  foods {
    edges {
      node {
        ${USER_FOOD_GROUPING_FOOD_SCHEMA}
      }
    }
  }
`;

export const USER_FOOD_GROUPINGS_SCHEMA = `
  userFoodGroupings(where: { userId: { equalTo: $userId }}) {
    edges {
      node {
        ${USER_FOOD_GROUPING_SCHEMA}
      }
    }
  }
`;

export const USER_FOOD_GROUPINGS_WHERE_SCHEMA = `
  userFoodGroupings(
    skip: $skip
    last: $last
    first: $first
    after: $after
    order: $order
    where: $where
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    count
    edges {
      node {
        ${USER_FOOD_GROUPING_SCHEMA}
      }
    }
  }
`;

export const GET_USER_FOOD_GROUPINGS_WHERE = gql`
  query getUserFoodGroupings(
    $skip: Int
    $last: Int
    $first: Int
    $after: String
    $before: String
    $order: [UserFoodGroupingOrder!]
    $where: UserFoodGroupingWhereInput
    ) {
    ${USER_FOOD_GROUPINGS_WHERE_SCHEMA}
  }
`;

export const GET_USER_FOOD_GROUPINGS_QUERY = gql`
  query getUserFoodGroupings($userId: String!) {
    ${USER_FOOD_GROUPINGS_SCHEMA}
  }
`;

export const CREATE_USER_FOOD_GROUPING = gql`
  mutation createUserFoodGrouping($fields: CreateUserFoodGroupingFieldsInput!) {
    createUserFoodGrouping(input: { fields: $fields }) {
      userFoodGrouping {
        ${USER_FOOD_GROUPING_SCHEMA}
      }
    }
  }
`;

export const UPDATE_USER_FOOD_GROUPING = gql`
  mutation updateUserFoodGrouping($id: ID!, $fields: UpdateUserFoodGroupingFieldsInput!) {
    updateUserFoodGrouping(input: { id: $id, fields: $fields }) {
      userFoodGrouping {
        ${USER_FOOD_GROUPING_SCHEMA}
      }
    }
  }
`;

export const DELETE_USER_FOOD_GROUPING = gql`
  mutation userFoodGroupings($id: ID!) {
    deleteUserFoodGrouping(input: { id: $id }) {
      userFoodGrouping {
        id
      }
    }
  }
`;
