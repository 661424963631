export default [
  {
    id: 'netCarbs',
    label: 'Net Carbs',
    style: 'text-primary',
  },
  {
    id: 'protein',
    label: 'Protein',
    style: 'text-muted',
  },
  {
    id: 'fat',
    label: 'Fat',
    style: 'text-muted',
  },
  {
    id: 'calories',
    label: 'Calories',
    style: 'text-muted',
    prefix: '',
  },
  {
    id: 'fiber',
    label: 'Fiber',
    style: 'text-muted',
  },
];
