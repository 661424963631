import { useRef } from 'react';

import Button from 'react-bootstrap/Button';
import { CSSTransition } from 'react-transition-group';

import Card from 'components/ui/Card';
import ListStatus from 'components/ListStatus';

import SearchResultsItem, { SearchResultsItemType } from './SearchResultsItem';

interface SearchResultsProps {
  show?: boolean;
  loading?: boolean;
  headers: string[];
  showLoadMore?: boolean;
  isFetchingMore?: boolean;
  onClickMore?: () => void;
  data: SearchResultsItemType[];
  onClickItem?: (index: number) => void;
  onClickEdit?: (index: number) => void;
  onClickRemove?: (index: number) => void;
  onClickTrack?: (index: number) => void;
  onRef?: (ref: HTMLDivElement | null) => void;

  editButton?: boolean;
  removeButton?: boolean;
  trackButton?: boolean;
}

const SearchResults = ({
  show,
  data,
  onRef,
  headers,
  editButton,
  onClickMore,
  onClickItem,
  onClickEdit,
  showLoadMore,
  removeButton,
  trackButton,
  onClickRemove,
  onClickTrack,
  isFetchingMore,
  loading = false,
}: SearchResultsProps) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const headerMargin =
    editButton || removeButton
      ? editButton && removeButton && trackButton
        ? 'mr-36'
        : editButton && removeButton && !trackButton
        ? 'mr-22'
        : 'mr-10'
      : '';
  return (
    <CSSTransition
      in={show}
      timeout={300}
      unmountOnExit
      nodeRef={nodeRef}
      classNames="alert">
      <div
        className="position-relative"
        ref={(e) => {
          nodeRef.current = e;
          onRef && onRef(e);
        }}>
        <div className="w-100 position-absolute zindex-1 shadow-sm rounded">
          <Card styles="m-0 rounded" innerStyles="p-0">
            <div className="h-400px" data-simplebar>
              <div className="sticky bg-white pt-4 px-8 zindex-1">
                <div className="mb-3 font-size-xxs text-primary">
                  <strong>Showing {data.length} results</strong>
                </div>
                <div
                  className={`d-flex flex-row justify-content-between ${headerMargin}`}>
                  {headers.map((value: string, index: number) => (
                    <span
                      className="text-uppercase"
                      key={`search-result-item-${index}`}>
                      {value}
                    </span>
                  ))}
                </div>
              </div>
              <div className="my-4">
                <ListStatus loading={loading}>
                  {data.map((item, index) => (
                    <SearchResultsItem
                      key={index}
                      data={item}
                      index={index}
                      editButton={editButton}
                      onClickItem={onClickItem}
                      onClickEdit={onClickEdit}
                      removeButton={removeButton}
                      trackButton={trackButton}
                      onClickRemove={onClickRemove}
                      onClickTrack={onClickTrack}
                    />
                  ))}
                </ListStatus>
                {!isFetchingMore && showLoadMore && (
                  <div className="d-flex justify-content-center">
                    <Button size="sm" variant="control" onClick={onClickMore}>
                      Load More
                    </Button>
                  </div>
                )}

                {isFetchingMore && (
                  <div className="d-flex justify-content-center my-4">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </CSSTransition>
  );
};

export default SearchResults;
