import { IRecipe } from 'shared/src/types';
import { RECIPE_DETAIL_NUTRITIONAL_VALUES } from 'constants/index';

import StyleImg from 'assets/icons/style.svg';
import PhaseImg from 'assets/icons/phase.svg';
import PrepTimeImg from 'assets/icons/prep-time.svg';
import CookTimeImg from 'assets/icons/cook-time.svg';
import DifficultyImg from 'assets/icons/difficulty.svg';

interface RecipeDetailDetailsProps {
  details?: Partial<IRecipe>;
}

const RecipeDetailDetails = ({ details }: RecipeDetailDetailsProps) => (
  <div className="mt-8">
    <div className="d-flex justify-content-between">
      <div className="d-flex flex-column align-items-center">
        <img src={PrepTimeImg} alt="prep time icon" />
        <div className="h4 mb-0 mt-3">Prep Time</div>
        <div>{details?.preparationTime ?? 0} min</div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <img src={CookTimeImg} alt="prep time icon" />
        <div className="h4 mb-0 mt-3">Cook Time</div>
        <div>{details?.cookingTime ?? 0} min</div>
      </div>

      <div className="d-flex flex-column align-items-center">
        <img src={StyleImg} alt="prep time icon" />
        <div className="h4 mb-0 mt-3">Style</div>
        <div>
          {details?.styles?.edges.map(({ node: { id, name } }, index) => (
            <div key={`style-item-${id}-${index}`}>{name}</div>
          ))}
        </div>
      </div>

      {details?.difficulty?.name && (
        <div className="d-flex flex-column align-items-center">
          <img src={DifficultyImg} alt="prep time icon" />
          <div className="h4 mb-0 mt-3">Difficulty</div>
          <div>{details?.difficulty?.name}</div>
        </div>
      )}

      {details?.phase?.name && (
        <div className="d-flex flex-column align-items-center">
          <img src={PhaseImg} alt="prep time icon" />
          <div className="h4 mb-0 mt-3">Phase</div>
          <div>{details?.phase?.name}</div>
        </div>
      )}
    </div>
    <div className="row my-8">
      {RECIPE_DETAIL_NUTRITIONAL_VALUES.map(({ key, label, unit }) => (
        <div key={key} className="col-md-3">
          <div className="bg-white rounded-lg py-4 text-primary mb-4 mb-md-0">
            <div className="text-center h3">
              <span className="h2">
                {(
                  (details?.[key as keyof typeof details] as number) ?? 0
                ).toFixed(1)}
              </span>
              {unit}
            </div>
            <div className="text-center">{label}</div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default RecipeDetailDetails;
