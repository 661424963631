import { Ref } from 'react';
import xor from 'lodash/xor';
import classnames from 'classnames';
import { connectField, HTMLFieldProps } from 'uniforms';
const escape = (x: string) => btoa(encodeURIComponent(x)).replace(/=+$/, '');

export type SelectFieldProps = HTMLFieldProps<
  string | string[] | number,
  HTMLDivElement,
  {
    allowedValues?: string[];
    checkboxes?: boolean;
    disableItem?: (value: string) => boolean;
    inline?: boolean;
    inputClassName?: string;
    inputRef?: Ref<HTMLSelectElement>;
    transform?: (value: string) => string;
  }
>;

function Select({
  id,
  name,
  error,
  label,
  value,
  inline,
  disabled,
  inputRef,
  onChange,
  readOnly,
  required,
  fieldType,
  transform,
  checkboxes,
  disableItem,
  placeholder,
  allowedValues,
  inputClassName,
}: SelectFieldProps) {
  const multiple = fieldType === Array;
  const isNumber = fieldType === Number;

  let content = (
    <select
      className={classnames(inputClassName, 'c-select form-control', {
        'is-invalid': error,
      })}
      disabled={disabled}
      id={id}
      multiple={multiple}
      name={name}
      onChange={(event) => {
        if (!readOnly) {
          const item = event.target.value;
          if (multiple && Array.isArray(value)) {
            const clear = event.target.selectedIndex === -1;
            onChange(clear ? [] : xor([item], value));
          } else {
            onChange(
              item !== '' ? (isNumber ? parseInt(item) : item) : undefined
            );
          }
        }
      }}
      ref={inputRef}
      value={String(value) ?? ''}>
      {(!!placeholder || !required || value === undefined) && !multiple && (
        <option value="" disabled={required} hidden={required}>
          {placeholder || label}
        </option>
      )}

      {allowedValues?.map((allowedValue) => (
        <option
          disabled={disableItem?.(allowedValue)}
          key={`select-field-allowed-${allowedValue}`}
          value={allowedValue}>
          {transform ? transform(allowedValue) : allowedValue}
        </option>
      ))}
    </select>
  );

  if (checkboxes) {
    content = (
      <>
        {allowedValues?.map((item) => (
          <div
            key={`select-field-allowed-boxes-${item}`}
            className={classnames(
              inputClassName,
              `checkbox${inline ? '-inline' : ''}`
            )}>
            <label htmlFor={`${id}-${escape(item)}`}>
              <input
                checked={
                  multiple && Array.isArray(value)
                    ? value?.includes(item)
                    : value === item
                }
                disabled={disableItem?.(item) || disabled}
                id={`${id}-${escape(item)}`}
                name={name}
                onChange={() => {
                  if (!readOnly && Array.isArray(value)) {
                    onChange(multiple ? xor([item], value) : item);
                  }
                }}
                type="checkbox"
              />
              {transform ? transform(item) : item}
            </label>
          </div>
        ))}
      </>
    );
  }

  return (
    <div
      className={`form-group ${required ? 'required' : ''} ${
        error ? 'is-invalid' : ''
      }`}>
      {label && (
        <label className={`${error ? 'text-danger' : ''}`}>{label}</label>
      )}
      {content}
      {error && <span className="form-text text-danger">{error.message}</span>}
    </div>
  );
}

export default connectField(Select, { kind: 'leaf' });
