import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from 'shared/src/hooks/useAuth';

const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return <Redirect to="/" />;
};

export default Logout;
