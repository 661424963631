import LinkImg from 'assets/icons/link.svg';
import UserImg from 'assets/icons/user.svg';
import ShopImg from 'assets/icons/shop.svg';
import AppleImg from 'assets/icons/apple.svg';
import StartImg from 'assets/icons/start.svg';
import LogoutImg from 'assets/icons/logout.svg';
import LockOutlineImg from 'assets/icons/lock-outline.svg';
import DietaryExclusionsImg from 'assets/icons/dietary-exclusions.svg';

export const TABS = [
  {
    value: 'personal-information',
    label: 'Personal Information',
    icon: UserImg,
  },
  {
    value: 'dietary-exclusions',
    label: 'Dietary Exclusions',
    icon: DietaryExclusionsImg,
  },
  { value: 'change-password', label: 'Change Password', icon: LockOutlineImg },
  { value: 'weight-settings', label: 'Weight Settings', icon: AppleImg },
];

export const LINKS = [
  {
    value:
      'https://www.atkins.com/how-it-works?utm_source=community&utm_medium=navigation&utm_campaign=how_it_works',
    label: 'How to start Atkins',
    icon: StartImg,
    iconLink: LinkImg,
  },
  {
    value:
      'https://shop.atkins.com/?_gl=1*1qxl37f*ga4_ga*NTAxMzAyNDkzLjE2Mzc1NzQyNTY.*ga4_ga_TQW9XM2G1P*MTYzNzkxODIxMi4xMS4xLjE2Mzc5MTg1OTQuNTg.',
    label: 'Shop Atkins',
    icon: ShopImg,
    iconLink: LinkImg,
  },
  {
    value: '/logout',
    label: 'Logout',
    icon: LogoutImg,
  },
];
