import { gql } from '@apollo/client';

export const FOOD_CATEGORY_SCHEMA = `
  id
  name
  objectId
`;

export const FOOD_CATEGORIES_SCHEMA = `
  foodCategories {
    edges {
      node {
        ${FOOD_CATEGORY_SCHEMA}
      }
    }
  }
`;

export const GET_FOOD_CATEGORIES_QUERY = gql`
  query getFoodCategories {
    ${FOOD_CATEGORIES_SCHEMA}
  }
`;
