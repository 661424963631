import { Modal as BModal, Button } from 'react-bootstrap';

import { ModalType } from 'hooks/useModal';

import Modal, { IModalChildrenProps } from 'components/ui/Modal';

const ConfirmationModalContent = ({ hide, data }: IModalChildrenProps) => {
  const handleAction = (status: boolean) => {
    data?.callback && data.callback(status);
    hide();
  };

  return (
    <div>
      <div className="h3 py-4">Are you sure you want to delete this entry?</div>
      <BModal.Footer>
        <Button
          variant="control"
          className="px-12"
          onClick={() => handleAction(false)}>
          No
        </Button>
        <Button
          variant="danger"
          className="px-12"
          onClick={() => handleAction(true)}>
          Yes
        </Button>
      </BModal.Footer>
    </div>
  );
};

const ConfirmationModal = () => (
  <Modal type={ModalType.Confirmation} title="Confirm Delete" centered>
    {(props: IModalChildrenProps) => <ConfirmationModalContent {...props} />}
  </Modal>
);

export default ConfirmationModal;
