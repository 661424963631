import get from 'lodash/get';
import numeral from 'numeral';
import { useMemo } from 'react';

import useAuth from './useAuth';

import useUserAssessment from './useUserAssessment';

import { IObject, TDailyNutrients } from '../types';

import CALORIES from '../constants/calories';
import useApiConstants from './useApiConstants';

type TByMeal = {
  lunch: number;
  dinner: number;
  amsnack: number;
  pmsnack: number;
  breakfast: number;
};

const useDailyNutrients = (logs?: IObject[]): TDailyNutrients => {
  const { user } = useAuth();
  const { baseGender, genderAssesment } = useUserAssessment();
  const { nutritionalRanges } = useApiConstants();

  const currentRange = nutritionalRanges.filter(
    ({ program }) => program.objectId === user.program.objectId
  )[0];

  const computedMacros = useMemo(() => {
    const isFemale = baseGender === 'female';

    let RMR = isFemale ? CALORIES.female : CALORIES.male;
    let RMR_MAX = isFemale ? CALORIES.femaleMax : CALORIES.maleMax;
    let NC_HIGH = get(user, 'program.goalHigh', 22);
    let NC = NC_HIGH - 2;
    let NC_LOW = NC - 2;

    const NET_CARBS_CALORIES = NC_HIGH * 4;
    const PROTEIN_CALORIES = 0.3 * RMR_MAX;
    let PROTEIN_GRAMS = PROTEIN_CALORIES / 4;

    const NET_CARBS_CALORIES_LOW = NC_LOW * 4;
    const PROTEIN_CALORIES_LOW = 0.2 * RMR;
    let PROTEIN_GRAMS_LOW = PROTEIN_CALORIES_LOW / 4;

    const FAT_CALORIES =
      RMR_MAX - (PROTEIN_CALORIES_LOW + NET_CARBS_CALORIES_LOW);
    let FAT_GRAMS = FAT_CALORIES / 9;

    const FAT_CALORIES_LOW = RMR - (PROTEIN_CALORIES + NET_CARBS_CALORIES);
    let FAT_GRAMS_LOW = FAT_CALORIES_LOW / 9;

    let FIBER_GRAMS = 40;
    let FIBER_GRAMS_LOW = 18;

    try {
      const ranges = currentRange[isFemale ? 'women' : 'men'];

      RMR = ranges.calories.low;
      RMR_MAX = ranges.calories.high;

      NC_HIGH = ranges.netCarbs.high;
      NC = NC_HIGH - 2;
      NC_LOW = ranges.netCarbs.low;

      PROTEIN_GRAMS = ranges.protein.high;
      PROTEIN_GRAMS_LOW = ranges.protein.low;

      FAT_GRAMS = ranges.fat.high;
      FAT_GRAMS_LOW = ranges.fat.low;

      FIBER_GRAMS = ranges.fiber.high;
      FIBER_GRAMS_LOW = ranges.fiber.low;
    } catch {
      //
    }

    const GOAL_DAILY = `Daily goal: ${RMR} - ${RMR_MAX}`;

    let totalFat = 0;
    let totalFiber = 0;
    let totalProtein = 0;
    let totalCalories = 0;
    let totalNetCarbs = 0;

    const byMeal: TByMeal = {
      lunch: 0,
      dinner: 0,
      amsnack: 0,
      pmsnack: 0,
      breakfast: 0,
    };

    logs &&
      logs.forEach(({ fat, meal, fiber, protein, calories, netCarbs }) => {
        totalFat += fat || 0;
        totalFiber += fiber || 0;
        totalProtein += protein || 0;
        totalCalories += calories || 0;
        totalNetCarbs += netCarbs || 0;

        byMeal[meal as keyof TByMeal] =
          (byMeal[meal as keyof TByMeal] || 0) + netCarbs || 0;
      });

    const meals = {
      lunch: '',
      dinner: '',
      amsnack: '',
      pmsnack: '',
      breakfast: '',
    };

    Object.keys(byMeal).map((key) => {
      meals[key as keyof TByMeal] = numeral(
        byMeal[key as keyof TByMeal]
      ).format('0.00');
    });

    let carbsGoalText = '';
    if (totalNetCarbs > NC_HIGH) {
      carbsGoalText = '— You are over your daily net carb goal.';
    }
    if (totalNetCarbs < NC_LOW) {
      carbsGoalText = '— You are under your daily net carb goal.';
    }
    if (totalNetCarbs >= NC_LOW && totalNetCarbs <= NC_HIGH) {
      carbsGoalText = '— You are within your daily net carb goal.';
    }

    let percentage = (totalNetCarbs * 100) / NC_HIGH;
    percentage = percentage > 100 ? 100 : percentage;

    return {
      RMR,
      carbs: {
        meals,
        percentage,
        goal: carbsGoalText,
        consumed: totalNetCarbs.toFixed(1),
        netCarbs: totalNetCarbs.toFixed(1),
        ncLow: NC_LOW,
        ncHight: NC_HIGH,
        netCarbsHigh: NET_CARBS_CALORIES.toFixed(),
        netCarbsLow: NET_CARBS_CALORIES_LOW.toFixed(),
      },
      macros: {
        protein: {
          title: 'Protein',
          high: PROTEIN_GRAMS,
          low: PROTEIN_GRAMS_LOW,
          goal: `Goal: ${PROTEIN_GRAMS_LOW.toFixed()} — ${PROTEIN_GRAMS.toFixed()}`,
          dailyGoal: `Daily goal: ${PROTEIN_GRAMS_LOW.toFixed()} — ${PROTEIN_GRAMS.toFixed()}`,
          value: totalProtein.toFixed() + ' g',
        },
        fat: {
          title: 'Fat',
          high: FAT_GRAMS,
          low: FAT_GRAMS_LOW,
          goal: `Goal: ${FAT_GRAMS_LOW.toFixed()} — ${FAT_GRAMS.toFixed()}`,
          dailyGoal: `Daily goal: ${FAT_GRAMS_LOW.toFixed()} — ${FAT_GRAMS.toFixed()}`,
          value: totalFat.toFixed() + ' g',
        },
        fiber: {
          title: 'Fiber',
          high: FIBER_GRAMS,
          low: FIBER_GRAMS_LOW,
          goal: `Goal: ${FIBER_GRAMS_LOW} — ${FIBER_GRAMS}`,
          dailyGoal: `Daily goal: ${FIBER_GRAMS_LOW} — ${FIBER_GRAMS}`,
          value: totalFiber.toFixed() + ' g',
        },
        calories: {
          title: 'Calories',
          dailyGoal: GOAL_DAILY,
          goal: `Goal: ${RMR.toFixed()}`,
          value: totalCalories.toFixed(),
        },
      },
    };
  }, [user, logs, baseGender, genderAssesment, currentRange]);

  return computedMacros;
};

export default useDailyNutrients;
