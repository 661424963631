import { useEffect } from 'react';

import startOfDay from 'date-fns/startOfDay';
import { useLazyQuery } from '@apollo/client';

import { ILogStep, ILogWater, ILogWeight, ITrackedFood } from '../../types';

import useAuth from '../../hooks/useAuth';
import { GET_LOGS } from '../../graphql/log';

const today = startOfDay(new Date());

const useLogs = (preloadQuery = false) => {
  const { user } = useAuth();
  const [getLogs, { data, loading, refetch }] = useLazyQuery(GET_LOGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      userId: user.objectId,
      date: today.toISOString(),
    },
  });

  useEffect(() => {
    preloadQuery && getLogs();
  }, []);

  const logFoods = data?.userFoodTrackeds?.edges.map(
    ({ node }: { node: ITrackedFood }) => node
  );

  const logWater = data?.logWaters?.edges.map(
    ({ node }: { node: ILogWater }) => node
  )[0] ?? {
    cups: 0,
  };

  const logWeight = data?.logWeights?.edges.map(
    ({ node }: { node: ILogWeight }) => node
  )[0] ?? {
    weight: 0,
  };

  const logStep = data?.logSteps?.edges.map(
    ({ node }: { node: ILogStep }) => node
  )[0] ?? {
    steps: 0,
  };

  return {
    logStep,
    getLogs,
    loading,
    refetch,
    logWater,
    logFoods,
    logWeight,
  };
};

export default useLogs;
