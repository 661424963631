import { gql } from '@apollo/client';

export const LOG_STEP_SCHEMA = `
  id
  userId
  steps
  loggedAt
`;

export const LOG_STEPS_SCHEMA = `
  logSteps(where: { userId: { equalTo: $userId }, loggedAt: {
    equalTo: $date
  }}) {
    edges {
      node {
        ${LOG_STEP_SCHEMA}
      }
    }
  }
`;

export const LOG_STEP_WHERE_SCHEMA = `
  logSteps(where: $where) {
    edges {
      node {
        ${LOG_STEP_SCHEMA}
      }
    }
  }
`;

export const GET_LOG_STEPS_QUERY = gql`
  query getLogSteps($userId: String!, $date: Date!) {
    ${LOG_STEPS_SCHEMA}
  }
`;

export const GET_LOG_WHERE_QUERY = gql`
  query getLogSteps($where: LogStepWhereInput!) {
    ${LOG_STEP_WHERE_SCHEMA}
  }
`;

export const CREATE_LOG_STEP = gql`
  mutation createLogStep($fields: CreateLogStepFieldsInput!) {
    createLogStep(input: { fields: $fields }) {
      logStep {
        ${LOG_STEP_SCHEMA}
      }
    }
  }
`;

export const UPDATE_LOG_STEP = gql`
  mutation updateLogStep($id: ID!, $steps: Float!) {
    updateLogStep(input: { id: $id, fields: { steps: $steps } }) {
      logStep {
        ${LOG_STEP_SCHEMA}
      }
    }
  }
`;
