import { gql } from '@apollo/client';
import { SERVING_CONVERSIONS_SCHEMA } from './food';

export const USER_FOOD_FAVORITE = `
  id
  fat
  name
  fiber
  foodId
  userId
  protein
  disabled
  objectId
  netCarbs
  calories
  recipeId
  brandName
  verboseName
  userRecipeId
  food {
    ${SERVING_CONVERSIONS_SCHEMA}
  }
  servingSize @include(if: $detailed)
  servingUnit @include(if: $detailed)
`;

export const GET_USER_FOODS_FAVORITES_SCHEMA = `
  userFoodFavorites(where: { userId: { equalTo: $userId } } ) {
    edges {
      node {
        ${USER_FOOD_FAVORITE}
      }
    }
  }
`;

export const GET_USER_FOOD_FAVORITES_SCHEMA = `
  userFoodFavorites(
    skip: $skip
    last: $last
    first: $first
    after: $after
    order: $order
    where: $where
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    count
    edges {
      node {
        ${USER_FOOD_FAVORITE}
      }
    }
  }
`;

export const GET_USER_FOOD_FAVORITES = gql`
  query userFoodFavorites(
    $skip: Int
    $last: Int
    $first: Int
    $after: String
    $before: String
    $order: [UserFoodFavoriteOrder!]
    $where: UserFoodFavoriteWhereInput
    $detailed: Boolean = true
    ) {
    ${GET_USER_FOOD_FAVORITES_SCHEMA}
  }
`;

export const GET_USER_FOOD_FAVORITES_BY_RECIPE_SCHEMA = `
  userFoodFavorites(where: { userId: { equalTo: $userId }, recipeId: { equalTo: $recipeId } } ) {
    edges {
      node {
        ${USER_FOOD_FAVORITE}
      }
    }
  }
`;

export const GET_USER_FOOD_BY_RECIPE_FAVORITES = gql`
  query userFoodFavorite(
    $userId: String!
    $recipeId: String!
    $detailed: Boolean = true
  ) {
    ${GET_USER_FOOD_FAVORITES_BY_RECIPE_SCHEMA}
  }
`;

export const GET_USER_FOOD_FAVORITE = gql`
  query userFoodFavorite(
    $id: ID!
    $detailed: Boolean = true
  ) {
    userFoodFavorite(id: $id) {
      ${USER_FOOD_FAVORITE}
    }
  }
`;

export const CREATE_USER_FOOD_FAVORITE = gql`
  mutation createUserFoodFavorite(
    $detailed: Boolean = true
    $fields: CreateUserFoodFavoriteFieldsInput!
  ) {
    createUserFoodFavorite(input: { fields: $fields }) {
      userFoodFavorite {
        ${USER_FOOD_FAVORITE}
      }
    }
  }
`;

export const UPDATE_USER_FOOD_FAVORITE = gql`
  mutation updateUserFoodFavorite(
    $id: ID!
    $detailed: Boolean = true
    $fields: UpdateUserFoodFavoriteFieldsInput!
  ) {
    updateUserFoodFavorite(input: { id: $id, fields: $fields }) {
      userFoodFavorite {
        ${USER_FOOD_FAVORITE}
      }
    }
  }
`;

export const DELETE_USER_FOOD_FAVORITE = gql`
  mutation($id: ID!) {
    deleteUserFoodFavorite(input: { id: $id }) {
      userFoodFavorite {
        id
        recipeId
      }
    }
  }
`;
