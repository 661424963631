import CustomRecipeList from 'components/customRecipe/CustomRecipeList';

const CustomRecipeIndex = () => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <CustomRecipeList />
        </div>
      </div>
    </>
  );
};

export default CustomRecipeIndex;
