import { gql } from '@apollo/client';

export const DIFFICULTY_SCHEMA = `
  id
  name
  objectId
`;

export const DIFFICULTIES_SCHEMA = `
  difficulties {
    edges {
      node {
        ${DIFFICULTY_SCHEMA}
      }
    }
  }
`;

export const GET_DIFFICULTIES_QUERY = gql`
  query getDifficulties {
    ${DIFFICULTIES_SCHEMA}
  }
`;
