import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { AutoForm, AutoFields } from 'uniforms-bootstrap4';

import Header from 'components/ui/Header';
import Divider from 'components/ui/Divider';

import Submit from 'shared/src/forms/fields/web/Submit';

import useAuth from 'shared/src/hooks/useAuth';
import useForm from 'shared/src/hooks/useForm';

import { SLogin } from 'shared/src/forms/schemas';

const Login = () => {
  const { login, isLoadingLoginMutation: isWorking } = useAuth();
  const { schema } = useForm(SLogin);

  const handleOnSubmit = async (data: {
    username: string;
    password: string;
  }) => {
    try {
      await login(data);
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <div className="col-md-6">
      <Header
        title="The new |Atkins| experience"
        subtitle="Please sign in to continue."
      />
      <div className="text-left theme-form form--size-lg">
        <AutoForm
          showInlineError
          schema={schema}
          onSubmit={handleOnSubmit}
          name="login-form"
          engagement-type="auth"
          engagement-name="login">
          <AutoFields />
          <Link
            to="/auth/forgot-password"
            className="d-block text-right btn-link btn-lg text-capitalize mb-8 p-0">
            <u>Forgot password?</u>
          </Link>
          <Submit working={isWorking} text="Log in" textWorking="Working" />
          <Divider text="Or" />
          <Link to="/auth/signup" className="btn btn-outline-primary btn-block">
            Sign Up
          </Link>
        </AutoForm>
      </div>
      <p className="text-center h4 font-weight-bold text-muted mt-6">
        (Pssst... Joining Atkins Carb Tracker is FREE 😉)
      </p>
    </div>
  );
};

export default Login;
