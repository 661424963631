import { gql } from '@apollo/client';

export const PHASE_SCHEMA = `
  id
  name
  objectId
  legacyId
`;

export const PHASES_SCHEMA = `
  phases {
    edges {
      node {
        ${PHASE_SCHEMA}
      }
    }
  }
`;

export const GET_PHASES_QUERY = gql`
  query getPhases {
    ${PHASES_SCHEMA}
  }
`;
