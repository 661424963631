const MEALS = [
  { name: 'Breakfast' },
  { name: 'AM Snack' },
  { name: 'Lunch' },
  { name: 'PM Snack' },
  { name: 'Dinner' },
];

export const MEALS_PLAIN = MEALS.map(({ name }) => name);

export default MEALS;
