import get from 'lodash/get';
import map from 'lodash/map';
import { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import useAssessment from './useAssessment';
import useAuth from './useAuth';
import {
  GET_USER_ASSESSMENTS_QUERY,
  UPDATE_USER_ASSESSMENT_MUTATION,
} from '../graphql/assessment';

import { updateCache } from '../utils/GraphqlHelper';

const useUserAssessment = () => {
  const { user } = useAuth();
  const { assessments } = useAssessment();
  const { data } = useQuery(GET_USER_ASSESSMENTS_QUERY, {
    variables: {
      userId: user.objectId,
    },
  });

  const [updateGender] = useMutation(UPDATE_USER_ASSESSMENT_MUTATION, {
    update: (query, document) => {
      updateCache(GET_USER_ASSESSMENTS_QUERY, {
        userId: user.objectId,
      })(query, document);
    },
  });
  const userAssessments = map(get(data, 'userAssessments.edges'), 'node');

  const mappedUserAssessments = userAssessments.map((uassessment) => {
    const found = assessments.filter(
      ({ node: { objectId } }: { node: { objectId: string } }) =>
        objectId === uassessment.assessmentId
    )[0];
    return {
      ...uassessment,
      name: found ? found.node.name : '',
    };
  });

  const { baseWeight, baseWeightObj } = useMemo(() => {
    const baseWeightObj = userAssessments.filter(
      ({ assessmentId }) => assessmentId === '3'
    )[0];
    const baseWeight = get(baseWeightObj, 'value', 0);

    return { baseWeight, baseWeightObj };
  }, [userAssessments]);

  const { baseGender, genderAssesment } = useMemo(() => {
    let baseGender = get(
      userAssessments.filter(({ assessmentId }) => assessmentId === '2')[0],
      'value',
      0
    );

    const genderAssesment = userAssessments.filter(
      ({ assessmentId }) => assessmentId === '2'
    )[0];

    if (baseGender === '2') {
      baseGender = 'male';
    } else {
      baseGender = 'female';
    }

    return { baseGender, genderAssesment };
  }, [userAssessments]);

  return {
    updateGender,
    genderAssesment,
    baseGender,
    baseWeight,
    baseWeightObj,
    userAssessments: mappedUserAssessments,
  };
};

export default useUserAssessment;
