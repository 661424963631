import { useState } from 'react';

import omit from 'lodash/omit';

import useAuth from '../../hooks/useAuth';

import useApolloCrud from '../../hooks/useApolloCrudv2';
import { TAny, IUserRecipe, TGraphQLList, TGraphQLResponse } from '../../types';

import {
  GET_USER_RECIPES,
  CREATE_USER_RECIPE,
  UPDATE_USER_RECIPE,
  DELETE_USER_RECIPE,
} from '../../graphql/userRecipe';

type TQuery = TGraphQLResponse<'userRecipes', TGraphQLList<IUserRecipe>>;
const useUserRecipe = (preloadQuery = false, limit = 14) => {
  const { user } = useAuth();
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const { query, mutations } = useApolloCrud<TQuery, IUserRecipe>({
    skipFirstQuery: !preloadQuery,
    queryNode: GET_USER_RECIPES,
    createMutationNode: CREATE_USER_RECIPE,
    updateMutationNode: UPDATE_USER_RECIPE,
    removeMutationNode: DELETE_USER_RECIPE,
    pointerName: 'userRecipes',
    queryOptions: {
      variables: {
        first: limit,
        detailed: true,
        order: ['name_ASC'],
        where: { userId: { equalTo: user.objectId } },
      },
      nextFetchPolicy: 'cache-first',
      fetchPolicy: 'cache-and-network',
    },
  });

  const onEndReached = async () => {
    if (isFetchingMore || !query.pageInfo?.hasNextPage) {
      return;
    }

    setIsFetchingMore(true);

    await query.fetchMore({
      variables: {
        after: query.pageInfo!.endCursor,
      },
    });

    setIsFetchingMore(false);
  };

  const search = async (term?: string, first = limit) => {
    let termWhere = {};
    if (term && typeof term === 'string') {
      if (term.trim() !== '') {
        termWhere = {
          name: { text: { search: { term } } },
        };
      }
    }

    return mutations.get.call({
      first,
      detailed: true,
      order: ['name_ASC'],
      where: {
        ...termWhere,
        userId: { equalTo: user.objectId },
      },
    });
  };

  const removeById = async (id: string) => {
    if (mutations.remove.loading) {
      return;
    }

    return mutations.remove.call({
      variables: {
        id,
      },
      optimisticResponse: {
        deleteUserRecipe: {
          userRecipe: {
            id,
          },
        },
      },
    });
  };

  const save = async (recipe: Partial<TAny>) => {
    const action = recipe.id ? mutations.update.call : mutations.create.call;

    const { data } = await action({
      variables: {
        ...(recipe.id && { id: recipe.id }),
        fields: omit(recipe, ['id']),
      },
    });

    const name = Object.keys(data)[0];
    return data[name].userRecipe;
  };

  return {
    ...query,
    search,
    save,
    mutations,
    removeById,
    onEndReached,
    isFetchingMore,
  };
};

export default useUserRecipe;
