/* eslint-disable eqeqeq */
import { toast } from 'react-toastify';
import { AutoForm, AutoField } from 'uniforms-bootstrap4';

import Modal, { IModalChildrenProps } from 'components/ui/Modal';

import { SWeightMeasurementsTrack } from 'forms/schemas';

import { ModalType } from 'hooks/useModal';

import useForm from 'shared/src/hooks/useForm';
import useDate from 'shared/src/hooks/useDate';
import { useLogMeasurement } from 'shared/src/store';
import Submit from 'shared/src/forms/fields/web/Submit';

import FIELDS from 'constants/measurementsFields';
import { IMeasurements } from 'shared/src/types';

interface IFormItemProps {
  name: string;
  unit?: string;
  label?: string;
  lastEntry?: string | number;
}

const FormItem = ({ name, label, unit, lastEntry }: IFormItemProps) => (
  <div className="row">
    <div className="col-6">
      <label className="m-0">
        <strong>{label}</strong>
      </label>
      <div>
        <small>
          {lastEntry != null && (
            <strong className="text-muted">
              Last entry: {lastEntry} {unit}
            </strong>
          )}
        </small>
      </div>
    </div>
    <div className="col-6">
      <AutoField name={name} />
    </div>
  </div>
);

const TrackMeasurementsContent = ({ data, hide }: IModalChildrenProps) => {
  const { date } = useDate();
  const {
    save,
    lastMeasurements: { data: lastMeasurements },
  } = useLogMeasurement(true);

  const { log } = data ?? {};

  const { schema } = useForm(SWeightMeasurementsTrack, {
    date: date.toISOString(),
    ...(log && {
      ...log,
      date: log.loggedAt ?? log.cratedAt ?? date.toISOString(),
    }),
  });

  const handleSubmit = ({
    date,
    ...fields
  }: {
    date: string;
  } & IMeasurements) => {
    if (new Date(date).getTime() > new Date().getTime()) {
      toast.error('Error: Date cannot be in the future');
      return;
    }
    try {
      save(log, new Date(date), fields);
      window.dataLayer.push({
        event: 'form_complete',
        form_name: 'track-measurement-form',
        engagement_type: 'tracker usage',
        engagement_name: 'measurement',
      });
      toast.success('Measurements logged successfully');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    } finally {
      hide();
    }
  };

  return (
    <div className="form--dark">
      <AutoForm
        schema={schema}
        showInlineError
        onSubmit={handleSubmit}
        label={false}>
        <div className="row">
          <div className="col-12 col-md-6">
            <h3 className="text-primary mb-6">Measurements</h3>

            {FIELDS.map((props) => (
              <FormItem
                key={`form-item-${props.name}`}
                {...props}
                lastEntry={
                  props.name === 'date'
                    ? undefined
                    : lastMeasurements[
                        props.name as keyof typeof lastMeasurements
                      ] ?? 0
                }
              />
            ))}
          </div>
        </div>
        <div className="mt-6">
          <Submit text="Save measurements" textWorking="Saving" />
        </div>
      </AutoForm>
    </div>
  );
};

const TrackMeasurements = () => (
  <Modal
    type={ModalType.trackMeasurements}
    title="Track your Measurements"
    subtitle="Enter your measurements and track progress towards your goals.">
    {(props: IModalChildrenProps) => <TrackMeasurementsContent {...props} />}
  </Modal>
);

export default TrackMeasurements;
