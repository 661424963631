import { useMemo } from 'react';

interface TextHighlighterProps {
  children: string;
  highlight: {
    delimiter: string;
  };
  variant?: 'regular' | 'small';
}

const TextHighlighter = ({
  children,
  highlight,
  variant = 'regular',
}: TextHighlighterProps) => {
  const { text, parts } = useMemo(() => {
    const text = children.substring(
      children.indexOf(highlight.delimiter) + 1,
      children.lastIndexOf(highlight.delimiter)
    );
    const parts = children.split(
      highlight.delimiter + text + highlight.delimiter
    );

    return { text, parts };
  }, [children]);

  const containerClass = variant === 'regular' ? 'h1' : '';
  const textClass =
    variant === 'regular' ? 'text-primary' : 'font-weight-boldest';

  return (
    <span className={containerClass}>
      {parts[0]}
      <span className={textClass}>{text}</span>
      {parts[1]}
    </span>
  );
};

export default TextHighlighter;
