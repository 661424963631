export const ALL = [
  'fat',
  'iron',
  'fiber',
  'sodium',
  'protein',
  'calcium',
  'glycerin',
  'vitaminC',
  'vitaminA',
  'calories',
  'netCarbs',
  'potassium',
  'totalCarbs',
  'totalSugars',
  'cholesterol',
  'saturatedFat',
  'sugarAlcohols',
  'monounsaturatedFat',
  'polyunsaturatedFat',
];

export const ALL_SERVING = [...ALL, 'servingUnit', 'servingSize'];

export const SHORT = ['fat', 'fiber', 'protein', 'calories', 'netCarbs'];

export const SHORT_SERVING = [...SHORT, 'servingUnit', 'servingSize'];

export default {
  ALL,
  ALL_SERVING,
  SHORT,
  SHORT_SERVING,
};
