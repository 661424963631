interface RecipeDetailDirectionsProps {
  directions?: string;
}

const RecipeDetailDirections = ({
  directions,
}: RecipeDetailDirectionsProps) => (
  <div className="bg-white p-4">
    <div dangerouslySetInnerHTML={{ __html: directions ?? '' }} />
  </div>
);

export default RecipeDetailDirections;
