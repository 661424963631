export default [
  { label: 'Search', value: 'foods' },
  { label: 'favorites ❤', value: 'favorites' },
  { label: 'Custom foods', value: 'custom-foods' },
  { label: 'groupings', value: 'grouping' },
  { label: 'recipes', value: 'recipes' },
];

export const CUSTOM_LIMITS = 100;
export const CUSTOM_LIMITS_FAVORITES = 500;
