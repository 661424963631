interface ICardDataGoals {
  config: {
    subtitle: string;
    gridClass?: string;
    gridContainerClass?: string;
    link?: {
      text: string;
      callback: () => void;
    };
    title: { value: string; text: string };
    percentage: { min: number; max: number; value: number };
  };
}

const CardDataGoals = ({
  config: {
    link,
    title,
    subtitle,
    percentage,
    gridClass = '',
    gridContainerClass = 'row mb-6',
  },
}: ICardDataGoals) => {
  return (
    <div className="row">
      <div className="col">
        <div className="row align-items-end mb-4">
          <div className="min-w-40px ml-4">
            <span className="font-weight-boldest line-height-xs text-primary text-48px">
              {title.value}
            </span>
          </div>
          <div className="col-md">
            <span className="text-dark-50 font-weight-bold text-uppercase">
              {title.text}
            </span>
          </div>
        </div>
        <div className={gridContainerClass}>
          <div className={gridClass}>
            <div className="d-flex align-items-center w-100 mb-2">
              <div className="mr-2">
                <span className="font-size-sm font-weight-bold text-muted">
                  {percentage.min}
                </span>
              </div>
              <div className="progress w-100 h-15px">
                <div
                  className="progress-bar bg-primary"
                  role="progressbar"
                  style={{
                    width: `${percentage.value}%`,
                  }}></div>
              </div>
              <div className="ml-2">
                <span className="font-size-sm font-weight-bold text-muted">
                  {percentage.max}
                </span>
              </div>
            </div>

            {link && (
              <a
                className="d-block text-right my-6 text-decoration-underline"
                onClick={link.callback}>
                {link.text}
              </a>
            )}
          </div>
          <div className="col-md">
            <span>{subtitle}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDataGoals;
