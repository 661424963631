import React from 'react';

import cloneDeep from 'lodash/cloneDeep';
import globalHook, { Store } from 'use-global-hook';

import { IObject } from 'shared/src/types';

import NewEntry from 'modals/NewEntry';
import TrackFood from 'modals/TrackFood';
import TrackSteps from 'modals/TrackSteps';
import ErrorModal from 'modals/ErrorModal';
import TrackWeight from 'modals/TrackWeight';
import ManagePlans from 'modals/ManagePlans';
import Confirmation from 'modals/Confirmation';
import TrackGrouping from 'modals/TrackGrouping';
import AssessmentPlan from 'modals/AssessmentPlan';
import AtkinsNetCarbs from 'modals/AtkinsNetCarbs';
import TrackMeasurements from 'modals/TrackMeasurements';

export const MODAL_COMPONENTS = [
  NewEntry,
  TrackFood,
  ErrorModal,
  TrackSteps,
  TrackWeight,
  ManagePlans,
  Confirmation,
  TrackGrouping,
  AssessmentPlan,
  AtkinsNetCarbs,
  AtkinsNetCarbs,
  TrackMeasurements,
];

export enum ModalType {
  newEntry = 'newEntry',
  trackFood = 'trackFood',
  errorModal = 'errorModal',
  trackSteps = 'trackSteps',
  trackWeight = 'trackWeight',
  managePlans = 'managePlans',
  Confirmation = 'Confirmation',
  trackGrouping = 'trackGrouping',
  assessmentPlan = 'assessmentPlan',
  atkinsNetCarbs = 'atkinsNetCarbs',
  trackMeasurements = 'trackMeasurements',
}

type StateObj = {
  data?: IObject;
  visible: boolean;
};

type State = {
  [key in ModalType]: StateObj;
};

type Values = {
  data?: IObject;
  type: ModalType;
  visible: boolean;
};

type Actions = {
  updateStore: (values: Values) => void;
};

const updateStore = (store: Store<State, Actions>, values: Values) => {
  const baseStore = cloneDeep(store.state);

  baseStore[values.type] = {
    data: values.data,
    visible: values.visible,
  };

  store.setState(baseStore);
};

const enumKeys = Object.values(ModalType);
const initialState = enumKeys.reduce(
  (accumulative, key) => ({
    ...accumulative,
    [key]: {
      visible: false,
    },
  }),
  {}
) as State;

const globalActions = {
  updateStore,
};

const useGlobal = globalHook<State, Actions>(
  React,
  initialState,
  globalActions
);

const useModal = () => {
  const [state, actions] = useGlobal();

  const show = (type: ModalType, data?: IObject) => {
    actions.updateStore({
      type,
      data,
      visible: true,
    });
  };

  const hide = (type: ModalType) => {
    actions.updateStore({
      type,
      visible: false,
    });
  };

  return {
    show,
    hide,
    ...state,
    ...actions,
  };
};

export default useModal;
