import React from 'react';
import TextHighlighter from 'components/TextHighlighter';

interface IHeaderProps {
  title: string;
  subtitle: string;
}

const Header = ({ title, subtitle }: IHeaderProps) => {
  return (
    <div className="mb-10">
      <TextHighlighter highlight={{ delimiter: '|' }}>{title}</TextHighlighter>
      <p className="h3 font-weight-bold text-muted">{subtitle}</p>
    </div>
  );
};

export default Header;
