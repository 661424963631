import { useEffect, useMemo, useRef } from 'react';

import { AutoForm } from 'uniforms';
import { AutoFields } from 'uniforms-bootstrap4';

import AssessmentFooter from '../AssessmentFooter';

import useForm from 'shared/src/hooks/useForm';
import useAssessment, {
  IQuestion,
  TAssessmentsKeys,
} from 'shared/src/store/modules/useAssessment';

import { TAny } from 'shared/src/types';
import { SNumeric, getSchema } from 'shared/src/forms/schemas';

import NumInput from 'shared/src/forms/fields/web/NumInput';

import VALIDATIONS from 'shared/src/constants/assessmentValidations';

import { ASSESSMENT_PLACEHOLDERS } from 'constants/index';

const AssessmentNumeric = ({ name }: IQuestion) => {
  const formRef = useRef<TAny>(null);
  const { update, next, back, step, ...store } = useAssessment();

  const Schema = useMemo(
    () =>
      getSchema(
        SNumeric,
        {
          [name]: {
            ...(VALIDATIONS[name as keyof typeof VALIDATIONS] && {
              ...VALIDATIONS[name as keyof typeof VALIDATIONS],
            }),
            label: '',
            placeholder:
              ASSESSMENT_PLACEHOLDERS[
                name as keyof typeof ASSESSMENT_PLACEHOLDERS
              ],
            uniforms: {
              component: NumInput,
            },
          },
        },
        [
          {
            from: 'numericField',
            to: name,
          },
        ]
      ),
    [name]
  );

  const { schema } = useForm(Schema, {
    [name]: store[name as keyof typeof store],
  });

  useEffect(() => {
    formRef?.current?.reset();
  }, [schema]);

  const handleSubmit = async (fields: Partial<{ [key: string]: number }>) => {
    const fieldName = Object.keys(fields)[0];
    let componentStep = 0;
    if (name === 'weight') {
      componentStep = 2;
    }
    if (name === 'age') {
      componentStep = 4;
    }

    if (name === 'waistCircumference' || name === 'desiredWeightLost') {
      componentStep = step + 1;
    }
    window.dataLayer.push({
      event: 'form_progress',
      form_name: 'signup-form ',
      step: componentStep,
      engagement_type: 'sign up',
      engagement_name: 'account',
    });
    update(name as TAssessmentsKeys, fields[fieldName]!);
    next();
  };

  const handleNext = () => {
    formRef?.current?.submit();
  };

  return (
    <div>
      <AutoForm
        ref={formRef}
        schema={schema}
        onSubmit={handleSubmit}
        engagement-type="auth"
        engagement-name="account">
        <div className="form--primary form--centered form--size-jumbo form--warning-off max-w-180px">
          <AutoFields />
        </div>
      </AutoForm>
      <AssessmentFooter onClickBack={back} onClickNext={handleNext} />
    </div>
  );
};

export default AssessmentNumeric;
