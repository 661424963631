import { connectField, HTMLFieldProps } from 'uniforms';

import BaseInput, { INumbInputProps } from '../../../components/web/NumInput';

const NumInput = ({
  label,
  required,
  ...props
}: HTMLFieldProps<number, HTMLInputElement, INumbInputProps>) => {
  const { error, className } = props;

  return (
    <div
      className={`form-group ${required ? 'required' : ''} ${
        error ? 'is-invalid' : ''
      } ${className || ''}`}>
      {label && (
        <label className={`${error ? 'text-danger' : ''}`}>{label}</label>
      )}

      <BaseInput {...props} placeholder={props?.field?.placeholder} />
      {error && <span className="form-text text-danger">{error.message}</span>}
    </div>
  );
};

export default connectField(NumInput);
