import { Tab, Tabs } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Card from 'components/ui/Card';

import { SEARCH_TABS, TRACKER_FOOD_TABS } from 'constants/index';

interface ITrackerSearch {
  setValue: React.Dispatch<React.SetStateAction<number>>;
  values: number;
}

const TrackerSearch = ({ setValue, values }: ITrackerSearch) => {
  const { hash } = useLocation();
  let defaultTab;
  if (hash.includes('recipes')) {
    defaultTab = SEARCH_TABS[4].value;
  } else {
    defaultTab = SEARCH_TABS[0].value;
  }
  return (
    <Card>
      <Tabs
        transition
        id="search-tabs"
        className="border-0"
        defaultActiveKey={defaultTab}>
        {SEARCH_TABS.map(({ label, value }) => {
          const TabComponent = TRACKER_FOOD_TABS.filter(
            ({ key }) => key === value
          )[0].component;
          return (
            <Tab
              title={label}
              eventKey={value}
              tabClassName="border-0"
              key={`teb-item-${value}`}>
              <TabComponent setValue={setValue} values={values} />
            </Tab>
          );
        })}
      </Tabs>
    </Card>
  );
};

export default TrackerSearch;
