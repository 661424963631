import { SelectField } from 'uniforms-bootstrap4';

import NumInput from 'shared/src/forms/fields/web/NumInput';
import DateField from 'shared/src/forms/fields/web/DateField';
import RichText from './fields/RichText';
import DropzoneUpload from './fields/DropzoneUpload';
import Ingredients from './fields/Ingredients';
import SelectFieldServing from './fields/SelectFieldServing';
import ServingTable from './fields/ServingTable';

import { getSchema } from 'shared/src/forms/schemas';

import { ERRORS } from 'shared/src/constants';
import { MEALS_PLAIN } from 'shared/src/constants/meals';
import ASSESSMENT_VALIDATIONS from 'shared/src/constants/assessmentValidations';
import TOTAL_SERVING_OPTIONS from 'constants/totalServingOptions';

const feetValidation = { ...ASSESSMENT_VALIDATIONS.feet };
const inchesValidation = { ...ASSESSMENT_VALIDATIONS.inches };

const numSchemeConfig = {
  minimum: 0,
  uniforms: { component: NumInput },
};

export const SRecipeTrack = {
  title: 'New food recipe track form',
  type: 'object',
  properties: {
    date: {
      type: 'string',
      uniforms: { component: DateField },
    },
    servingSize: {
      default: 1,
      type: 'number',
      label: 'Quantity',
      uniforms: { component: NumInput },
    },
    servingUnit: {
      label: '',
      default: 'serving(s)',
      allowedValues: ['serving(s)'],
      uniforms: { component: SelectField },
    },
    meal: {
      type: 'string',
      default: MEALS_PLAIN[0],
      allowedValues: MEALS_PLAIN,
      uniforms: { component: SelectField },
    },
    favorite: {
      type: 'boolean',
      default: false,
      label: 'Add this food to My favorites',
    },
  },
  required: ['meal', 'servingUnit', 'servingSize'],
};

export const SQuickEntry = {
  title: 'Quickfood form',
  type: 'object',
  properties: {
    name: {
      type: 'string',
      minLength: 4,
    },
    date: {
      type: 'string',
      uniforms: { component: DateField },
    },
    meal: {
      type: 'string',
      default: MEALS_PLAIN[0],
      allowedValues: MEALS_PLAIN,
      uniforms: { component: SelectField },
    },
    netCarbs: {
      type: 'number',
      ...numSchemeConfig,
      className: 'inline-input',
      label: 'Net Carbs (g)',
    },
    fiber: {
      type: 'number',
      ...numSchemeConfig,
      className: 'inline-input',
      label: 'Fiber (g)',
    },
    calories: {
      type: 'number',
      ...numSchemeConfig,
      className: 'inline-input',
    },
    fat: {
      type: 'number',
      ...numSchemeConfig,
      className: 'inline-input',
      label: 'Fat (g)',
    },
    protein: {
      type: 'number',
      ...numSchemeConfig,
      className: 'inline-input',
      label: 'Protein (g)',
    },
    favorite: {
      type: 'boolean',
      default: false,
      label: 'Add this food to My favorites',
    },
  },
  required: ['name', 'date', 'meal', 'netCarbs'],
};

export const SCustomFood = {
  title: 'Quickfood form',
  type: 'object',
  properties: {
    name: {
      type: 'string',
      minLength: 4,
    },
    date: {
      type: 'string',
      uniforms: { component: DateField },
    },
    meal: {
      type: 'string',
      default: MEALS_PLAIN[0],
      allowedValues: MEALS_PLAIN,
      uniforms: { component: SelectField },
    },
    netCarbs: {
      type: 'number',
      ...numSchemeConfig,
      className: 'inline-input',
      label: 'Net Carbs (g)',
    },
    fiber: {
      type: 'number',
      ...numSchemeConfig,
      className: 'inline-input',
      label: 'Fiber (g)',
    },
    calories: {
      type: 'number',
      ...numSchemeConfig,
      className: 'inline-input',
    },
    fat: {
      type: 'number',
      ...numSchemeConfig,
      className: 'inline-input',
      label: 'Fat (g)',
    },
    protein: {
      type: 'number',
      ...numSchemeConfig,
      className: 'inline-input',
      label: 'Protein (g)',
    },
    iron: {
      type: 'number',
      className: 'inline-input',
      label: 'Iron (mg)',
      ...numSchemeConfig,
    },
    sodium: {
      type: 'number',
      className: 'inline-input',
      label: 'Sodium (mg)',
      ...numSchemeConfig,
    },
    calcium: {
      type: 'number',
      className: 'inline-input',
      label: 'Calcium (mg)',
      ...numSchemeConfig,
    },
    vitaminC: {
      type: 'number',
      className: 'inline-input',
      label: 'Vitamin c (mg)',
      ...numSchemeConfig,
    },
    vitaminA: {
      type: 'number',
      className: 'inline-input',
      label: 'Vitamin a (mg)',
      ...numSchemeConfig,
    },
    glycerin: {
      type: 'number',
      className: 'inline-input',
      label: 'Glycerin (g)',
      ...numSchemeConfig,
    },
    potassium: {
      type: 'number',
      className: 'inline-input',
      label: 'Potassium (g)',
      ...numSchemeConfig,
    },
    totalCarbs: {
      type: 'number',
      className: 'inline-input',
      label: 'Total Carbs (g)',
      ...numSchemeConfig,
    },
    totalSugars: {
      type: 'number',
      className: 'inline-input',
      label: 'Total sugars (g)',
      ...numSchemeConfig,
    },
    cholesterol: {
      type: 'number',
      className: 'inline-input',
      label: 'Cholesterol (g)',
      ...numSchemeConfig,
    },
    saturatedFat: {
      type: 'number',
      className: 'inline-input',
      label: 'Saturated fat (g)',
      ...numSchemeConfig,
    },
    sugarAlcohols: {
      type: 'number',
      className: 'inline-input',
      label: 'Sugar alcohols (g)',
      ...numSchemeConfig,
    },
    monounsaturatedFat: {
      type: 'number',
      className: 'inline-input',
      label: 'Monounsaturated fat (g)',
      ...numSchemeConfig,
    },
    polyunsaturatedFat: {
      type: 'number',
      className: 'inline-input',
      label: 'Polyunsaturated fat (g)',
      ...numSchemeConfig,
    },
    favorite: {
      className: 'pt-16',
      type: 'boolean',
      default: false,
      label: 'Add this food to My favorites',
    },
  },
  required: [
    'name',
    'fat',
    'date',
    'meal',
    'fiber',
    'protein',
    'calories',
    'netCarbs',
  ],
};

export const SHeight = {
  title: 'Assessment Height',
  type: 'object',
  properties: {
    feet: {
      ...feetValidation,
      default: 1,
      type: 'number',
      placeholder: "1'",
      uniforms: {
        component: NumInput,
      },
    },
    inches: {
      ...inchesValidation,
      placeholder: "0''",
      type: 'number',
      uniforms: {
        component: NumInput,
      },
    },
  },
  errorMessage: {
    required: {
      feet: ERRORS.required,
      inches: ERRORS.required,
    },
  },
  required: ['feet', 'inches'],
};

export const SBasicTrack = {
  title: 'Basic track form',
  type: 'object',
  properties: {
    date: {
      type: 'string',
      uniforms: { component: DateField },
    },
    meal: {
      type: 'string',
      default: MEALS_PLAIN[0],
      allowedValues: MEALS_PLAIN,
      uniforms: { component: SelectField },
    },
  },
  required: ['date', 'meal'],
};

export const SValueTrack = {
  title: 'New value track form',
  type: 'object',
  properties: {
    date: {
      type: 'string',
      uniforms: { component: DateField },
    },
    value: {
      default: 1,
      minimum: 1,
      type: 'number',
      label: 'Quantity',
      uniforms: { component: NumInput },
    },
  },
  required: ['date', 'value'],
};

export const SStepTrack = getSchema(
  SValueTrack,
  {
    steps: {
      label: 'Steps',
      uniforms: {
        removeDots: true,
      },
    },
  },
  [
    {
      from: 'value',
      to: 'steps',
    },
  ]
);

export const SWeightTrack = getSchema(
  SValueTrack,
  {
    weight: {
      label: 'Weight',
    },
  },
  [
    {
      from: 'value',
      to: 'weight',
    },
  ]
);

export const SWeightTrackLoss = getSchema(
  SValueTrack,
  {
    weight: {
      label: 'Weight',
    },
    lossGoal: {
      default: 1,
      minimum: 1,
      type: 'number',
      label: 'Quantity',
      uniforms: { component: NumInput },
    },
  },
  [
    {
      from: 'value',
      to: 'weight',
    },
  ],
  ['lossGoal']
);

export const SWeightMeasurementsTrack = {
  title: 'Measurements track form',
  type: 'object',
  properties: {
    date: {
      type: 'string',
      uniforms: { component: DateField },
    },
    neck: {
      minimum: 0,
      type: 'number',
      placeholder: '0 in',
      uniforms: { component: NumInput },
    },
    leftArm: {
      minimum: 0,
      type: 'number',
      placeholder: '0 in',
      uniforms: { component: NumInput },
    },
    rightArm: {
      type: 'number',
      placeholder: '0 in',
      uniforms: { component: NumInput },
    },
    chest: {
      minimum: 0,
      type: 'number',
      placeholder: '0 in',
      uniforms: { component: NumInput },
    },
    waist: {
      minimum: 0,
      type: 'number',
      placeholder: '0 in',
      uniforms: { component: NumInput },
    },
    hips: {
      minimum: 0,
      type: 'number',
      placeholder: '0 in',
      uniforms: { component: NumInput },
    },
    leftThigh: {
      minimum: 0,
      type: 'number',
      placeholder: '0 in',
      uniforms: { component: NumInput },
    },
    rightThigh: {
      minimum: 0,
      type: 'number',
      placeholder: '0 in',
      uniforms: { component: NumInput },
    },
    leftCalf: {
      minimum: 0,
      type: 'number',
      placeholder: '0 in',
      uniforms: { component: NumInput },
    },
    rightCalf: {
      minimum: 0,
      type: 'number',
      placeholder: '0 in',
      uniforms: { component: NumInput },
    },
  },
  required: ['date'],
};

export const SCustomRecipeForm = {
  title: 'Custom Recipe form',
  type: 'object',
  properties: {
    directions: {
      type: 'string',
      label: 'Directions',
      placeholder: 'Type Cooking Instructions for this recipe',
      uniforms: {
        component: RichText,
      },
    },
    name: {
      type: 'string',
      label: 'Recipe Name',
      placeholder: 'Enter recipe name here',
    },
    image: {
      type: 'array',
      label: '',
      items: {
        type: 'object',
      },
      uniforms: { component: DropzoneUpload },
    },
    foods: {
      type: 'array',
      label: 'Ingredients',
      items: {
        type: 'object',
      },
      placeholder: 'Add new Ingredient',
      uniforms: { component: Ingredients },
    },
    recipes: {
      type: 'array',
      label: 'Recipes',
      items: {
        type: 'object',
      },
      placeholder: 'Add new Recipe',
      uniforms: { component: Ingredients, type: 'recipes' },
    },
    servings: {
      type: 'number',
      label: 'Number of servings per recipe',
      options: TOTAL_SERVING_OPTIONS,
      default: TOTAL_SERVING_OPTIONS[0].value,
      uniforms: { component: SelectFieldServing },
    },
    servingTable: {
      label: '',
      required: [],
      type: 'object',
      properties: {
        fiber: {
          type: 'number',
          label: 'Fiber',
          placeholder: 0.0,
        },
      },
      uniforms: { component: ServingTable },
    },
  },
  errorMessage: {
    required: {
      name: ERRORS.required,
    },
  },
  required: ['name'],
};
