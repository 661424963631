import Spinner from 'react-bootstrap/Spinner';
import BButton, { ButtonProps } from 'react-bootstrap/Button';

interface IProps extends ButtonProps {
  text?: string;
  working?: boolean;
  textWorking?: string;
}

const Button = ({
  working = false,
  text = 'Save',
  textWorking = 'Saving',
  ...props
}: IProps) => {
  const textValue = working ? textWorking : text;
  const loader = working ? (
    <Spinner size="sm" animation="border" className="mr-2" />
  ) : null;

  return (
    <BButton {...props} disabled={working}>
      {loader}
      {textValue}
    </BButton>
  );
};

export default Button;
