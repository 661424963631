export default [
  {
    name: 'Pound',
    value: 'lb',
  },
  {
    name: 'Kilogram',
    value: 'kg',
  },
];
