import endOfYear from 'date-fns/endOfYear';
import startOfDay from 'date-fns/startOfDay';
import startOfYear from 'date-fns/startOfYear';

import useAuth from './useAuth';

const today = startOfDay(new Date());
const historicalEndDate = endOfYear(today).toISOString();
const historicalStartDate = startOfYear(today).toISOString();

const useReportVariables = () => {
  const { user } = useAuth();

  return {
    where: {
      userId: {
        equalTo: user.objectId,
      },
      AND: [
        {
          loggedAt: {
            greaterThanOrEqualTo: historicalStartDate,
          },
        },
        {
          loggedAt: {
            lessThanOrEqualTo: historicalEndDate,
          },
        },
      ],
    },
  };
};

export default useReportVariables;
