import Card from 'components/ui/Card';
import ProTip from 'components/ProTip';
import CardTitle from 'components/ui/CardTitle';
import WaterCupCounter from 'components/WaterCupCounter';

import bottleIcon from 'assets/icons/bottle.svg';

const MyWater = () => (
  <Card styles="h-100">
    <CardTitle
      size="small"
      title="My water"
      icon={bottleIcon}
      subtitle="Cups of water you drink daily."
    />

    <ProTip text="We recommend at least 8 cups a day." />

    <WaterCupCounter style="justify-content-center" />
  </Card>
);

export default MyWater;
