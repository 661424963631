interface IDividerProps {
  text: string;
}

const Divider = ({ text }: IDividerProps) => {
  return (
    <div className="d-flex align-items-center py-10">
      <hr className="flex-grow-1" />
      <div className="mx-5">
        <span className="text-muted font-weight-bold h4">{text}</span>
      </div>
      <hr className="flex-grow-1" />
    </div>
  );
};

export default Divider;
