import { useEffect, useState } from 'react';

import pick from 'lodash/pick';
import {
  useLazyQuery,
  QueryLazyOptions,
  OperationVariables,
  WatchQueryFetchPolicy,
} from '@apollo/client';

import { GET_ATLAS_FOODS_QUERY } from '../../graphql/food';
import {
  IFood,
  TGraphQLList,
  TGraphQLResponse,
  TGraphQLPageInfo,
} from '../../types';

import { digitsOnly } from '../../utils/String';

import QUERY_RESULT from '../../constants/queryResult';
import PUBLISH_FILTER from '../../constants/publishFilter';

const queryOptions: {
  variables: OperationVariables;
  fetchPolicy?: WatchQueryFetchPolicy;
  nextFetchPolicy?: WatchQueryFetchPolicy;
} = {
  nextFetchPolicy: 'cache-first',
  fetchPolicy: 'cache-and-network',
  variables: {
    where: {
      ...PUBLISH_FILTER,
    },
  },
};

export enum TFilterBy {
  all = 0,
  atkinsAcceptable = 1,
  products = 2,
  upc = 3,
}

const useFood = (preloadQuery = false, limit = 14) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const [getFoods, foodsData] = useLazyQuery<
    TGraphQLResponse<'atlasFoods', TGraphQLList<IFood>>
  >(GET_ATLAS_FOODS_QUERY, queryOptions);

  useEffect(() => {
    preloadQuery && getFoods();
  }, []);

  const foods =
    foodsData?.data?.atlasFoods?.edges.map(({ node }) => node) ?? [];
  const foodsPageInfo =
    foodsData?.data?.atlasFoods?.pageInfo ?? ({} as TGraphQLPageInfo);
  const foodsCount = foodsData?.data?.atlasFoods?.count ?? 0;

  const search = (term: string, byFilter: TFilterBy = 0) => {
    const options: QueryLazyOptions<OperationVariables> = {
      variables: {
        first: limit,
        detailed: false,
        where: {
          ...PUBLISH_FILTER,
          name: { text: { search: { term } } },
        },
      },
    };

    switch (byFilter) {
      case 1:
        options.variables!.where.isAtkinsAcceptable = { equalTo: true };
        break;
      case 2:
        options.variables!.where.category = {
          have: { id: { in: ['7AMA45GDLB'] } },
        };
        break;
      case 3: {
        let upc = digitsOnly(term);
        if (upc.length > 12) {
          upc = upc.substring(upc.length - 12);
        }
        options.variables!.where = { upcCode: { equalTo: upc } };
      }
    }

    return getFoods(options);
  };

  const onEndReached = async () => {
    if (isFetchingMore || !foodsPageInfo?.hasNextPage) {
      return;
    }

    setIsFetchingMore(true);
    setSelectedPage(selectedPage + 1);

    await foodsData.fetchMore!({
      variables: {
        skip: limit * selectedPage,
      },
    });

    setIsFetchingMore(false);
  };

  return {
    search,
    ...pick(foodsData, QUERY_RESULT),
    data: foods,
    onEndReached,
    get: getFoods,
    isFetchingMore,
    count: foodsCount,
    pageInfo: foodsPageInfo,
  };
};

export default useFood;
