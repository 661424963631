export default [
  {
    label: 'Dashboard',
    to: '/dashboard',
  },
  {
    label: 'Tracker',
    to: '/tracker',
  },
  {
    label: 'Community',
    to: { pathname: 'https://community.atkins.com' },
    target: '_top',
  },
  {
    label: 'How to start Atkins',
    to: {
      pathname:
        'https://www.atkins.com/how-it-works?utm_source=community&utm_medium=navigation&utm_campaign=how_it_works',
    },
    target: '_blank',
  },
];
