import { gql } from '@apollo/client';

export const RECIPE_STATUS_SCHEMA = `
  id
  name
  objectId
`;

export const RECIPE_STATUSES_SCHEMA = `
  recipeStatuses {
    edges {
      node {
        ${RECIPE_STATUS_SCHEMA}
      }
    }
  }
`;

export const GET_RECIPE_STATUSES_QUERY = gql`
  query getRecipeStatuses {
    ${RECIPE_STATUSES_SCHEMA}
  }
`;
