import AssessmentFooter from '../AssessmentFooter';

import useAssessment, {
  IQuestion,
  TAssessmentsKeys,
} from 'shared/src/store/modules/useAssessment';
import { FormCheck } from 'react-bootstrap';

const AssessmentChoice = ({ name, options }: IQuestion) => {
  const { back, next, update, step, ...store } = useAssessment();

  const selected = store[name as keyof typeof store];
  const isYesNo = name === 'diabetes' || name === 'pregnant';

  const handleClick = (value: string) => () => {
    update(name as TAssessmentsKeys, value);
  };
  const handleNext = () => {
    const componentStep = step + 1;
    if (componentStep < 9) {
      window.dataLayer.push({
        event: 'form_progress',
        form_name: 'signup-form',
        step: step + 1,
        engagement_type: 'sign up',
        engagement_name: 'account',
      });
    }
    next();
  };

  return (
    <div>
      <div>
        {options.map(({ label, value }) => {
          const isActive = selected === value;
          return (
            <div key={value}>
              <a
                onClick={handleClick(value)}
                style={{
                  flex: 0,
                  ...(isActive && {
                    backgroundColor: 'white',
                  }),
                }}
                className={`rounded border border-primary p-6 min-w-180px mb-6 ${
                  isActive ? 'text-primary' : ''
                } ${isYesNo ? 'd-inline-block ' : 'd-block'}`}>
                <FormCheck
                  type="radio"
                  label={label}
                  checked={isActive}
                  className="d-inline"
                  onChange={() => {
                    //
                  }}
                />
              </a>
            </div>
          );
        })}
      </div>
      <AssessmentFooter onClickBack={back} onClickNext={handleNext} />
    </div>
  );
};

export default AssessmentChoice;
