import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { AutoField } from 'uniforms-bootstrap4';
import { AutoForm, DeepPartial } from 'uniforms';

import Card from 'components/ui/Card';
import CardTitle from 'components/ui/CardTitle';

import useForm from 'shared/src/hooks/useForm';
import useUser from 'shared/src/store/modules/useUser';

import { IUser, TAny } from 'shared/src/types';
import { SUserEdit } from 'shared/src/forms/schemas';
import useUserAssessment from 'shared/src/hooks/useUserAssessment';

import UserImg from 'assets/icons/user-blue.svg';

const ProfilePersonalInformation = () => {
  const formRef = useRef<TAny>(null);
  const { save, isSaving, userEditable } = useUser();
  const { schema } = useForm(SUserEdit, userEditable);

  const { genderAssesment } = useUserAssessment();

  const [gender, setGender] = useState(genderAssesment?.value ?? '1');

  useEffect(() => {
    setGender(genderAssesment?.value ?? '1');
  }, [genderAssesment]);

  const handleSubmit = async (values: DeepPartial<IUser>) => {
    try {
      await save(values as Partial<IUser>, gender);
      toast.success('Personal Information Saved');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <Card>
      <CardTitle
        title="Personal Information"
        icon={UserImg}
        button={{
          working: isSaving,
          text: 'save changes',
          textWorking: 'saving',
          link: () => {
            formRef?.current?.submit();
          },
        }}
      />

      <AutoForm
        ref={formRef}
        schema={schema}
        showInlineError
        onSubmit={handleSubmit}>
        <div className="form--dark form--size-lg">
          <div className="row">
            <div className="col-12 col-md-6">
              <AutoField name="firstName" />
            </div>
            <div className="col-12 col-md-6">
              <AutoField name="lastName" />
            </div>
          </div>

          <div className="row">
            <div className="col col-md-6">
              <AutoField name="email" />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4">
              <AutoField name="country" />
            </div>
            <div className="col-12 col-md-4">
              <AutoField name="city" />
            </div>
            <div className="col-12 col-md-4">
              <AutoField name="zipcode" />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <AutoField name="address" />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <label>Gender</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}>
                  <option value="1">Female</option>
                  <option value="2">Male</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </AutoForm>
    </Card>
  );
};

export default ProfilePersonalInformation;
