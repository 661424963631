import Button from 'react-bootstrap/Button';
import { NavLink, useRouteMatch } from 'react-router-dom';

import Card from 'components/ui/Card';

import useModal, { ModalType } from 'hooks/useModal';

import useAuth from 'shared/src/hooks/useAuth';
import usePrograms from 'shared/src/hooks/usePrograms';

import { TABS, LINKS } from 'constants/profile';

import FolkImg from 'assets/icons/folk-blue.svg';

const ProfileNavbar = () => {
  const { show } = useModal();
  const { logout } = useAuth();
  const { url } = useRouteMatch();
  const { userPlan, userPhase, userPlanRanges } = usePrograms();

  const handleLogout = async () => {
    await logout();
    window.location.replace(
      'https://www.atkins.com/plans-and-groups?logout=true'
    );
  };

  const handleManagePlan = () => {
    show(ModalType.managePlans);
  };

  const handleLinks = (value: string) => (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (value.indexOf('/logout') !== -1) {
      e.preventDefault();
      handleLogout();
    }
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 col-md-5">
          <Card styles="bg-primary">
            <div className="d-flex flex-row align-items-center">
              <img src={FolkImg} className="mr-4" />
              <div>
                <div className="h1 text-white">{userPlan}</div>
                <div className="h5 text-white">{userPhase}</div>
                <div className="h5 text-white">{userPlanRanges}</div>
              </div>
              <Button
                variant="light"
                onClick={handleManagePlan}
                className="ml-auto">
                Manage my plan
              </Button>
            </div>
          </Card>
        </div>
        <div className="col-12 col-md-7">
          <ul className="nav justify-content-md-end">
            {LINKS.map(({ value, label, icon, iconLink }) => (
              <li key={`tracker-tab-${value}`} className="nav-item mr-4">
                <a
                  onClick={handleLinks(value)}
                  href={value}
                  target="blank"
                  className="btn btn-hover-light text-dark-50">
                  <img src={icon} />
                  <span className="ml-2">{label}</span>
                  {iconLink && <img src={iconLink} className="ml-2" />}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Card size="small">
        <nav className="navbar py-0">
          <h3 className="h4 mr-12 mb-sm-0">Your Profile</h3>
          <ul className="nav">
            {TABS.map(({ value, label, icon }) => (
              <li key={`tracker-tab-${value}`} className="nav-item mr-4">
                <NavLink
                  to={`${url}/${value}`}
                  className="btn btn-hover-light text-dark-50"
                  activeClassName="active">
                  <img src={icon} />
                  <span className="ml-2">{label}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </Card>
    </>
  );
};

export default ProfileNavbar;
