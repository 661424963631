import React, { useMemo, useEffect } from 'react';

import pick from 'lodash/pick';
import globalHook, { Store } from 'use-global-hook';
import {
  gql,
  useQuery,
  useMutation,
  ApolloCache,
  FetchResult,
  QueryHookOptions,
  MutationHookOptions,
  OperationVariables,
  FetchMoreQueryOptions,
  FetchMoreOptions,
} from '@apollo/client';

import wait from '../utils/Wait';
import { getFirstDeep } from '../utils/ObjectHelper';
import { updateCache } from '../utils/GraphqlHelper';
import { IObject, TAny, TApolloNode } from '../types';

import QUERY_RESULT from '../constants/queryResult';

export interface IUseApolloCrudProps {
  queryNode?: TApolloNode;
  skipFirstQuery?: boolean;
  syncInternalCache?: boolean;
  pointerName?: string | string[];
  queryOptions?: QueryHookOptions;
  createMutationNode?: TApolloNode;
  updateMutationNode?: TApolloNode;
  removeMutationNode?: TApolloNode;
  createMutationOptions?: MutationHookOptions;
  updateMutationOptions?: MutationHookOptions;
  removeMutationOptions?: MutationHookOptions;
  createMutationCustomUpdate?: (
    cache: ApolloCache<TAny>,
    { data }: FetchResult<TAny, Record<string, TAny>, Record<string, TAny>>
  ) => void;
  updateMutationCustomUpdate?: (
    cache: ApolloCache<TAny>,
    { data }: FetchResult<TAny, Record<string, TAny>, Record<string, TAny>>
  ) => void;
  removeMutationCustomUpdate?: (
    cache: ApolloCache<TAny>,
    { data }: FetchResult<TAny, Record<string, TAny>, Record<string, TAny>>
  ) => void;
  cachePointers?: {
    node?: TApolloNode;
    variables?: IObject;
    internal?: boolean;
  };
}

const PLACEHOLDER_QUERY = gql`
  query {
    placeholder
  }
`;

const PLACEHOLDER_MUTATION = gql`
  mutation {
    placeholder
  }
`;

type TCachedState = {
  cachedQuery: TApolloNode | null;
  cachedVariables: IObject | null;
};

export type TCachedAssociatedActions = {
  updateCachedData: (
    cachedQuery: TApolloNode,
    cachedVariables: IObject
  ) => void;
};

const updateCachedData = (
  store: Store<TCachedState, TCachedAssociatedActions>,
  cachedQuery: TApolloNode,
  cachedVariables: IObject
) => {
  store.setState({ cachedQuery, cachedVariables });
};

const initialState: TCachedState = {
  cachedQuery: null,
  cachedVariables: null,
};

const globalActions = {
  updateCachedData,
};

const useGlobal = globalHook<TCachedState, TCachedAssociatedActions>(
  React,
  initialState,
  globalActions
);

const getDataAndCount = (data: TAny, name: string, asOne = false) => {
  const count = data?.[name]?.count ?? 0;
  const pageInfo = data?.[name]?.pageInfo ?? {};
  const node = (data?.[name]?.edges ?? []).map(({ node }: TAny) => node);

  return [asOne ? node[0] : node, { ...pageInfo, count }];
};

let skip = true;
const useApolloCrud = <S = undefined, T = undefined>({
  queryNode,
  pointerName,
  skipFirstQuery,
  syncInternalCache,
  queryOptions = {},
  cachePointers = {},
  createMutationNode,
  updateMutationNode,
  removeMutationNode,
  createMutationOptions = {},
  updateMutationOptions = {},
  removeMutationOptions = {},
  createMutationCustomUpdate,
  updateMutationCustomUpdate,
  removeMutationCustomUpdate,
}: IUseApolloCrudProps) => {
  const [cachedState, cachedActions] = useGlobal();
  const baseQueryNode = queryNode || PLACEHOLDER_QUERY;
  let baseCachePointers = {
    node: baseQueryNode,
    variables: queryOptions.variables || {},
    ...cachePointers,
  };

  if (cachePointers.internal) {
    baseCachePointers = {
      node: cachedState.cachedQuery || baseCachePointers.node,
      variables: cachedState.cachedVariables || baseCachePointers.variables,
    };
  }

  if (skipFirstQuery) {
    queryOptions.skip = true;
  }

  const queryResults = useQuery<S>(baseQueryNode, {
    ...queryOptions,
    skip: queryOptions.skip && skip,
  });

  const data = queryResults.data;
  const previousData = queryResults.previousData;

  useEffect(() => {
    if (syncInternalCache) {
      cachedActions.updateCachedData(
        baseQueryNode,
        queryOptions.variables || {}
      );
    }
  }, [data]);

  const [mappedData, pageInfo] = useMemo(() => {
    const currentData = data ? data : previousData;

    let _pointerName = pointerName;
    if (!_pointerName) {
      _pointerName = getFirstDeep(currentData as TAny, 1).name;
    }

    if (Array.isArray(_pointerName)) {
      const responseData: IObject = {};
      const responsePageInfo: IObject = {};
      _pointerName.forEach((name) => {
        const data = getDataAndCount(currentData, name, true);
        responseData[name] = data[0];
        responsePageInfo[name] = data[1];
      });

      return [responseData, responsePageInfo];
    } else {
      return getDataAndCount(currentData, _pointerName);
    }
  }, [data]);

  const [createMutation, createMutationResult] = useMutation(
    createMutationNode || PLACEHOLDER_MUTATION,
    {
      ...createMutationOptions,
      update: createMutationCustomUpdate
        ? createMutationCustomUpdate
        : updateCache(baseCachePointers.node, baseCachePointers.variables),
    }
  );
  const [updateMutation, updateMutationResult] = useMutation(
    updateMutationNode || PLACEHOLDER_MUTATION,
    {
      ...updateMutationOptions,
      update: updateMutationCustomUpdate
        ? updateMutationCustomUpdate
        : updateCache(baseCachePointers.node, baseCachePointers.variables),
    }
  );
  const [removeMutation, removeMutationResult] = useMutation(
    removeMutationNode || PLACEHOLDER_MUTATION,
    {
      ...removeMutationOptions,
      update: removeMutationCustomUpdate
        ? removeMutationCustomUpdate
        : updateCache(baseCachePointers.node, baseCachePointers.variables),
    }
  );

  /* TODO: remove wait helper by finding a better solution */
  const refetch = async (variables?: Partial<OperationVariables>) => {
    skip = false;
    await wait();
    return queryResults.refetch(variables);
  };

  const fetchMore = async (
    fetchMoreOptions: FetchMoreQueryOptions<OperationVariables, string> &
      FetchMoreOptions<S, OperationVariables>
  ) => {
    skip = false;
    await wait();
    return queryResults.fetchMore(fetchMoreOptions);
  };

  return {
    query: {
      ...pick(queryResults, QUERY_RESULT),
      refetch,
      fetchMore,
      _data: data,
      data: mappedData as T[],
      pageInfo,
    },
    mutations: {
      get: {
        call: refetch,
      },
      create: {
        call: createMutation,
        ...createMutationResult,
      },
      update: {
        call: updateMutation,
        ...updateMutationResult,
      },
      remove: {
        call: removeMutation,
        ...removeMutationResult,
      },
    },
  };
};

export default useApolloCrud;
