import pick from 'lodash/pick';

import AssessmentHeader, { TAssessmentHeaderInfo } from './AssessmentHeader';
import AssessmentPersonalInformation from './steps/AssessmentPersonalInformation';

import { TAny } from 'shared/src/types';
import { useAssessment } from 'shared/src/store';

import LoadingIndicator from 'shared/src/components/web/LoadingIndicator';

import { ASSESSMENT_COMPONENTS } from 'constants/index';
import useModal, { ModalType } from 'hooks/useModal';

const defaultQuestion = {
  title: 'Personal information',
  subtitle: 'To get started, please tell us about yourself.',
  component: AssessmentPersonalInformation,
  highlight: {
    delimiter: '|',
  },
};

const Assessment = () => {
  const { updateStore } = useModal();
  const {
    step,
    save,
    plan,
    isSaving,
    questions,
    currentQuestion,
  } = useAssessment({
    components: ASSESSMENT_COMPONENTS,
    onComplete: async () => {
      try {
        updateStore({
          visible: false,
          data: { plan, autoload: true },
          type: ModalType.assessmentPlan,
        });
        await save();
      } catch (error) {
        const { message } = error as Error;

        updateStore({
          visible: false,
          data: { plan, autoload: false },
          type: ModalType.assessmentPlan,
        });

        updateStore({
          visible: true,
          data: { message },
          type: ModalType.errorModal,
        });
      }
    },
  });

  const question = currentQuestion ?? defaultQuestion;
  const AssessmentStep = question?.component as TAny;
  const headerProps = pick(question, [
    'title',
    'subtitle',
    'highlight',
    'description',
  ]) as TAssessmentHeaderInfo;

  return (
    <>
      <LoadingIndicator visible={isSaving} />
      <AssessmentHeader
        {...headerProps}
        step={step + 1}
        totalSteps={questions.length + 1}
      />
      <AssessmentStep {...question} />
    </>
  );
};

export default Assessment;
