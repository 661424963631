import omit from 'lodash/omit';
import { toast } from 'react-toastify';

import TabWrapper from 'components/TabWrapper';
import SearchInput from 'components/search/SearchInput';
import SearchResults from 'components/search/SearchResults';

import TrackerTitle from './TrackerTitle';

import useFilterList from 'hooks/useFilterList';
import useModal, { ModalType } from 'hooks/useModal';

import { useLogFood, useFavorites } from 'shared/src/store';

import { IFavoriteFood } from 'shared/src/types';

import { CUSTOM_LIMITS_FAVORITES } from 'constants/searchTabs';
import { TRACKER_TITLES, TABLE_THEAD_SEARCH } from 'constants/index';

const TrackerSearchFavorites = () => {
  const {
    data,
    pageInfo,
    removeById,
    onEndReached,
    isFetchingMore,
  } = useFavorites('all', CUSTOM_LIMITS_FAVORITES);
  const { setCachedFood } = useLogFood();
  const { show: showModal } = useModal();
  const { setFilter, filteredData } = useFilterList<IFavoriteFood>(data);

  const favorites = filteredData.map(
    ({ name, brandName, netCarbs, verboseName }) => ({
      label: verboseName || name,
      labelExtra: brandName,
      extra: `${(netCarbs ?? 0).toFixed(2)} g`,
    })
  );

  const handleClickItem = (index: number) => {
    /* TODO: check type id */
    setCachedFood({
      food: {
        ...omit(filteredData[index], ['id', 'objectId']),
        foodId: filteredData[index].objectId,
      },
    });

    showModal(ModalType.trackFood, { hideFavorite: true });
  };

  const handleClickRemove = (index: number) => {
    const food = filteredData[index];

    try {
      removeById(food.id, food.recipeId);
      toast.success('Favorite removed successfully');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <TabWrapper>
      <TrackerTitle values={TRACKER_TITLES.FOOD} />

      <SearchInput
        label="Search favorite..."
        onSearch={setFilter}
        sync
        type="searchFavorites"
      />
      <div className="h-400px">
        <SearchResults
          show={true}
          removeButton
          data={favorites}
          onClickMore={onEndReached}
          headers={TABLE_THEAD_SEARCH}
          onClickItem={handleClickItem}
          isFetchingMore={isFetchingMore}
          onClickRemove={handleClickRemove}
          showLoadMore={pageInfo.hasNextPage}
        />
      </div>
    </TabWrapper>
  );
};

export default TrackerSearchFavorites;
