import { gql } from '@apollo/client';

export const GET_SFMC_QUERY = gql`
  query sfmcSubscriber($event: String, $subscriberKey: String) {
    sfmcSubscriber(event: $event, subscriberKey: $subscriberKey) {
      success
      emailAddress
    }
  }
`;
