import { gql } from '@apollo/client';

export const RECIPE_DISH_TYPE_SCHEMA = `
  id
  name
`;

export const RECIPE_DISH_TYPES_SCHEMA = `
  recipeDishTypes {
    edges {
      node {
        ${RECIPE_DISH_TYPE_SCHEMA}
      }
    }
  }
`;

export const GET_RECIPE_DISH_TYPES_QUERY = gql`
  query getRecipeDishTypes {
    ${RECIPE_DISH_TYPES_SCHEMA}
  }
`;
