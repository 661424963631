import { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';

import useAuth from '../../hooks/useAuth';
import useApolloCrud from '../../hooks/useApolloCrudv2';

import {
  GET_USER_KEY_INGREDIENT_EXCLUSION,
  CREATE_USER_KEY_INGREDIENT_EXCLUSION,
  DELETE_USER_KEY_INGREDIENT_EXCLUSION,
} from '../../graphql/UserKeyIngredientExclusion';
import {
  IObject,
  TGraphQLList,
  TGraphQLResponse,
  IRecipeKeyIngredients,
  IUserKeyIngredientExclusion,
  IDietaryExclusionsSettingsSchema,
} from '../../types';
import { GET_RECIPE_KEY_INGREDIENTS_QUERY } from '../../graphql/recipeKeyIngredient';

type TQueryKeyIngredients = TGraphQLResponse<
  'recipeKeyIngredients',
  TGraphQLList<IRecipeKeyIngredients>
>;

type TQuery = TGraphQLResponse<
  'logWaters',
  TGraphQLList<IUserKeyIngredientExclusion>
>;

const getName = (name: string) => {
  return name.toLowerCase().replace(new RegExp(' ', 'g'), '').replace('/', '');
};

const useDietaryExclusions = (preloadQuery = false) => {
  const [isSaving, setIsSaving] = useState(false);
  const { user } = useAuth();

  const { data: keyIngredients } = useQuery<TQueryKeyIngredients>(
    GET_RECIPE_KEY_INGREDIENTS_QUERY
  );
  const { query, mutations } = useApolloCrud<
    TQuery,
    IUserKeyIngredientExclusion
  >({
    skipFirstQuery: !preloadQuery,
    queryNode: GET_USER_KEY_INGREDIENT_EXCLUSION,
    createMutationNode: CREATE_USER_KEY_INGREDIENT_EXCLUSION,
    removeMutationNode: DELETE_USER_KEY_INGREDIENT_EXCLUSION,
    pointerName: 'userKeyIngredientExclusions',
    queryOptions: {
      nextFetchPolicy: 'cache-first',
      fetchPolicy: 'cache-and-network',
      variables: {
        userId: user.objectId,
      },
    },
  });

  const dietaryExclusions =
    keyIngredients?.recipeKeyIngredients.edges.map(({ node }) => node) ?? [];

  const exclusions = useMemo(() => {
    const fields: IObject = {};
    query.data.forEach((e) => {
      const item = dietaryExclusions.filter(
        ({ legacyId }) => legacyId === e.exclusion
      )[0];

      fields[getName(item?.name ?? '')] = true;
    });

    return fields;
  }, [query.data, dietaryExclusions]);

  const save = async (values: IDietaryExclusionsSettingsSchema) => {
    setIsSaving(true);
    const toDelete: string[] = [];
    const toAdd: { exclusion: string; userId: string }[] = [];

    Object.keys(values).forEach((name: string) => {
      const value = values[name as keyof IDietaryExclusionsSettingsSchema];

      const item = dietaryExclusions.filter(
        ({ name: dename }) => getName(dename) === name
      )[0];

      const baseValue = query.data.filter(
        ({ exclusion }) => exclusion === item.legacyId
      )[0];

      if (!baseValue) {
        const exclusionId = item.legacyId;

        toAdd.push({
          exclusion: exclusionId,
          userId: user.objectId,
        });
      } else {
        if (!value) toDelete.push(baseValue.id);
      }
    });

    for (const item of toAdd) {
      await mutations.create.call({
        variables: {
          fields: item,
        },
      });
    }

    for (const id of toDelete) {
      await mutations.remove.call({ variables: { id } });
    }
    setIsSaving(false);
  };

  return {
    save,
    query,
    isSaving,
    mutations,
    exclusions,
  };
};

export default useDietaryExclusions;
