import { Link } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';

import TextHighlighter from 'components/TextHighlighter';
import { IQuestion } from 'shared/src/store/modules/useAssessment';

export type TAssessmentHeaderInfo = Pick<
  IQuestion,
  'title' | 'subtitle' | 'highlight'
>;
interface AssessmentHeaderProps extends TAssessmentHeaderInfo {
  step: number;
  totalSteps: number;
}
const AssessmentHeader = ({
  step,
  title,
  subtitle,
  highlight,
  totalSteps,
}: AssessmentHeaderProps) => (
  <div>
    <div className="mb-16">
      <div className="d-flex justify-content-between mb-4">
        <span>
          Already have an account?{' '}
          <Link to="/auth/login" className="text-decoration-underline">
            Log in here
          </Link>
        </span>
        <span className="text-primary">
          Plan assessment {step}/{totalSteps}
        </span>
      </div>
      <ProgressBar
        now={Math.round((step / totalSteps) * 100)}
        className="progress--small progress--white"
      />
    </div>

    <div className="mb-8">
      <TextHighlighter highlight={highlight}>{title}</TextHighlighter>
      <div>{subtitle}</div>
    </div>
  </div>
);

export default AssessmentHeader;
