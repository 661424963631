import { lazy, Suspense } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';

import useAuth from 'shared/src/hooks/useAuth';
import LoadingIndicator from 'shared/src/components/web/LoadingIndicator';

import Login from 'views/auth/Login';
import Logout from 'views/auth/Logout';
import Signup from 'views/auth/Signup';
import ForgotPassword from 'views/auth/ForgotPassword';

const AuthLayout = lazy(() => import('layouts/Auth'));

import AuthorizedRouter from './AuthorizedRouter';
import useCookieChecker from 'hooks/useCookieChecker';

const Router = () => {
  const { isLoggedIn } = useAuth();
  const isReady = useCookieChecker();

  if (!isReady) {
    return <LoadingIndicator visible animation={false} />;
  }

  if (!isLoggedIn) {
    return (
      <BrowserRouter>
        <Suspense fallback={<LoadingIndicator visible animation={false} />}>
          <AuthLayout>
            <Switch>
              <Route path="/auth/login" exact={true} component={Login} />
              <Route path="/auth/logout" exact={true} component={Logout} />
              <Route path="/auth/signup" exact={true} component={Signup} />
              <Route
                path="/auth/forgot-password"
                exact={true}
                component={ForgotPassword}
              />
              <Redirect to="/auth/login" />
            </Switch>
          </AuthLayout>
        </Suspense>
      </BrowserRouter>
    );
  }

  return <AuthorizedRouter />;
};

export default Router;
