import pick from 'lodash/pick';
import { useQuery } from '@apollo/client';
import startOfDay from 'date-fns/startOfDay';

import useAuth from '../../hooks/useAuth';

import {
  TAny,
  IObject,
  ILogStep,
  TGraphQLList,
  TGraphQLResponse,
} from '../../types';
import useApolloCrud from '../../hooks/useApolloCrudv2';
import useReportVariables from '../../hooks/useReportVariables';
import {
  CREATE_LOG_STEP,
  UPDATE_LOG_STEP,
  GET_LOG_STEPS_QUERY,
  GET_LOG_WHERE_QUERY,
} from '../../graphql/logStep';
import { GET_LOGS } from '../../graphql/log';
import QUERY_RESULT from '../../constants/queryResult';
import { updateCache } from '../../utils/GraphqlHelper';

type TQuery = TGraphQLResponse<'logSteps', TGraphQLList<ILogStep>>;

const today = startOfDay(new Date());

const useLogStep = (preloadQuery = false) => {
  const { user } = useAuth();
  const reportVariables = useReportVariables();

  const { query, mutations } = useApolloCrud<TQuery, ILogStep>({
    skipFirstQuery: !preloadQuery,
    queryNode: GET_LOG_STEPS_QUERY,
    createMutationNode: CREATE_LOG_STEP,
    updateMutationNode: UPDATE_LOG_STEP,
    pointerName: 'logSteps',
    queryOptions: {
      variables: {
        userId: user.objectId,
        date: today.toISOString(),
      },

      nextFetchPolicy: 'cache-first',
      fetchPolicy: 'cache-and-network',
    },
  });

  const yearHistorical = useQuery<TQuery>(GET_LOG_WHERE_QUERY, {
    variables: reportVariables,
  });

  const yearHistoricalData = yearHistorical?.data?.logSteps?.edges?.map(
    ({ node }) => node
  );

  const save = async (log: TAny, date: Date, steps: number) => {
    const action = log.id ? mutations.update.call : mutations.create.call;
    let variables: IObject = {
      id: log.id,
      userId: user.objectId,
      loggedAt: date.toISOString(),
      steps: +steps,
    };

    if (!log.id) {
      const fields = { ...variables };
      variables = { fields };
    }

    return action({
      variables,
      update: (query, document) => {
        updateCache(GET_LOGS, {
          userId: user.objectId,
          date: date.toISOString(),
        })(query, document);
        updateCache(GET_LOG_WHERE_QUERY, reportVariables)(query, document);
        updateCache(GET_LOG_STEPS_QUERY, {
          userId: user.objectId,
          date: date.toISOString(),
        })(query, document);
      },
      optimisticResponse: {
        [log.id ? 'updateLogStep' : 'createLogStep']: {
          logStep: {
            steps: +steps,
            userId: user.objectId,
            loggedAt: date.toISOString(),
            id: log.id || 'OptimisticId' + Math.random(),
          },
        },
      },
    });
  };

  return {
    save,
    query,
    mutations,
    yearHistorical: {
      ...pick(yearHistorical, QUERY_RESULT),
      _data: yearHistorical?.data,
      data: yearHistoricalData,
    },
  };
};

export default useLogStep;
