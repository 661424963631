import { useEffect, useMemo } from 'react';

import format from 'date-fns/format';

import Card from 'components/ui/Card';
import Table from 'components/ui/Table';
import CardTitle from 'components/ui/CardTitle';

import useModal, { ModalType } from 'hooks/useModal';

import { useLogMeasurement } from 'shared/src/store';

import MEASUREMENTS_FIELDS from 'constants/measurementsFields';

import RulerImg from 'assets/icons/ruler-blue.2x.svg';
import { TAny } from 'shared/src/types';
import { toast } from 'react-toastify';

interface Data {
  id: string;
  date: string;
  entry: string;
  measurement: string;
  smallEntry?: boolean;
}

const headers = [
  'date',
  'neck',
  'left arm',
  'right arm',
  'chest',
  'waist',
  'hips',
  'left thigh',
  'right thigh',
  'left calf',
  'right calf',
];

const MyWeightList = () => {
  const { show: showModal } = useModal();

  const {
    remove,
    historical: { get: getM, data: logsM },
  } = useLogMeasurement(true);

  useEffect(() => {
    getM();
  }, []);

  const data = useMemo(() => {
    const measurementLogs =
      logsM
        ?.sort(
          (a, b) =>
            new Date(b.loggedAt).getTime() - new Date(a.loggedAt).getTime()
        )
        .map((log) => {
          const fields: TAny = {};
          MEASUREMENTS_FIELDS.filter(({ name }) => name !== 'date').forEach(
            ({ name, label, unit }) => {
              const value = log[name as keyof typeof log] as number;

              fields[label] = `${(value ?? 0).toFixed(2)} ${unit ?? ''}`;
            }
          );

          return {
            date: format(new Date(log.loggedAt), 'MM/dd/yyyy'),
            ...fields,
          };
        }) ?? [];

    return measurementLogs;
  }, [logsM]);

  const handleItem = (item: Data) => {
    const dateItem = item.date;

    const log = logsM?.filter(
      ({ loggedAt }) => dateItem === format(new Date(loggedAt), 'MM/dd/yyyy')
    )[0];

    showModal(ModalType.trackMeasurements, { log });
  };

  const handleDelete = async (index: number) => {
    showModal(ModalType.Confirmation, {
      callback: async (status: boolean) => {
        if (!status) {
          return;
        }

        try {
          await remove(logsM![index].id);
          toast.success('Log removed successfully');
        } catch {
          toast.error('Ups, something went wrong');
        }
      },
    });
  };

  return (
    <Card>
      <CardTitle icon={RulerImg} title="My measurements" subtitle="" />

      <div>
        <Table<Data>
          data={data}
          headers={headers}
          onClickItem={handleItem}
          onClickDelete={handleDelete}
        />
      </div>
    </Card>
  );
};

export default MyWeightList;
