import { useEffect } from 'react';

import numeral from 'numeral';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import TrackerTitle from './tracker/tabs/TrackerTitle';

import Card, { ICardProps } from 'components/ui/Card';
import CardInner from './ui/CardInner';
import CardTextfield from 'components/ui/CardTextfield';
import CardTitle, { ICardTitleProp } from 'components/ui/CardTitle';

import useModal, { ModalType } from 'hooks/useModal';

import useDate from 'shared/src/hooks/useDate';

import useLogStep from 'shared/src/store/modules/useLogStep';

import sportIcon from 'assets/icons/sport.svg';

export interface IMYFitnessProps {
  variant?: 'regular' | 'full';
  cardProps?: Omit<ICardProps, 'children'>;
  cardTitleProps: ICardTitleProp;
}

const FitnessCard = ({
  cardProps,
  cardTitleProps,
  variant = 'regular',
}: IMYFitnessProps) => {
  const { date } = useDate();
  const { show: showModal } = useModal();

  const {
    query: { data, refetch },
  } = useLogStep(true);

  useEffect(() => {
    refetch({
      date,
    });
  }, [date]);

  const log = data?.[0] ?? {};
  const steps = log?.steps ?? 0;
  //const stepsAway = Math.max(0, 2000 - steps);

  const isFull = variant === 'full';
  const cardTextVariant = isFull ? 'light' : 'regular';
  const cardTextStyles = isFull ? '' : 'mb-8 justify-content-center';

  const handleEdit = () => {
    window.dataLayer.push({
      event: 'form_start',
      form_name: 'fitness-form',
      engagement_type: 'tracker usage',
      engagement_name: 'steps',
    });
    showModal(ModalType.trackSteps, { log });
  };

  return (
    <>
      <Card {...cardProps}>
        <CardTitle {...cardTitleProps} />
        <CardInner hide={!isFull}>
          {isFull && (
            <TrackerTitle
              values={{
                subtitle: '',
                title: 'How many steps?',
              }}
            />
          )}

          <div className="my-4 pb-2">
            <CardTextfield
              subtitle="steps taken"
              variant={cardTextVariant}
              containerStyles={cardTextStyles}
              title={numeral(steps).format('0,000')}
            />
          </div>
          {/*!!stepsAway && (
            <p className="my-4 pb-2">
              — You are{' '}
              <span className="primary">
                {numeral(stepsAway).format('0,000')}{' '}
              </span>
              steps from your daily goal. Almost there, keep going!.
            </p>
          )

          stepsAway && (
            <p className="my-4 pb-2">
              — Congratulations on reaching your daily steps goal.
            </p>
          )*/}

          {!isFull && (
            <Link
              className="btn btn-primary btn-block text-decoration-none mt-auto"
              to="/tracker/fitness">
              Track Fitness
            </Link>
          )}

          {isFull && (
            <Card size="minimal">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <img className="mr-2" src={sportIcon} />
                  <span className="text-muted normal">
                    Update total steps taken
                  </span>
                </div>
                <div className="col-auto">
                  <Button
                    className="small white px-2 mt-0 mt-xs-2"
                    onClick={handleEdit}>
                    {log?.id ? 'Update' : 'Log'} my steps
                  </Button>
                </div>
              </div>
            </Card>
          )}
        </CardInner>
      </Card>
    </>
  );
};

export default FitnessCard;
