import Card from 'components/ui/Card';
import { useState } from 'react';
import TrackerSearch from 'components/tracker/TrackerSearch';
import TrackerMeals from 'components/tracker/meals/TrackerMeals';
import DailyNutritionValues from 'components/DailyNutritionValues';

const TrackerFoods = () => {
  const [value, setValue] = useState(0);
  return (
    <>
      <div className="row">
        <div className="col-xl-8">
          <TrackerSearch setValue={setValue} values={value} />
        </div>
        <div className="col-xl-4">
          <Card>
            <DailyNutritionValues variant="portrait" />
          </Card>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <TrackerMeals values={value} setValue={setValue} />
        </div>
      </div>
    </>
  );
};

export default TrackerFoods;
