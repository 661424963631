import format from 'date-fns/format';
import Card from 'components/ui/Card';

import { useLogMeasurement } from 'shared/src/store';
import MEASUREMENTS_FIELDS from 'constants/measurementsFields';
import InlineValueItem from 'components/ui/InlineValueItem';

const MyMeasurementsLast = () => {
  const {
    lastMeasurements: { data: log },
  } = useLogMeasurement(true);

  return (
    <Card>
      <h4 className="text-gray-800 mb-4">Last Measurements</h4>
      <div className="h-400px" data-simplebar>
        <div className="pr-2">
          {MEASUREMENTS_FIELDS.filter(({ name }) => name !== 'date').map(
            ({ name, label, unit }) => {
              const value = log?.[name as keyof typeof log] as number;

              const logDate = log?.loggedAt
                ? format(new Date(log?.loggedAt), 'EEEE, MMM dd yyyy')
                : '';
              return (
                <InlineValueItem
                  key={label}
                  title={label}
                  subtitle={logDate}
                  value={`${(value ?? 0).toFixed(2)} ${unit ?? ''}`}
                />
              );
            }
          )}
        </div>
      </div>
    </Card>
  );
};

export default MyMeasurementsLast;
