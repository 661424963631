import MyWeightList from 'components/tracker/myWeight/MyWeightList';
import MyWeightLastWeightIn from 'components/tracker/myWeight/MyWeightLastWeightIn';
import MyWeightNewMeasurements from 'components/tracker/myWeight/MyWeightNewMeasurements';

const TrackerWeight = () => {
  return (
    <>
      <div className="row">
        <div className="col-xl-8">
          <MyWeightNewMeasurements />
        </div>
        <div className="col-xl-4">
          <MyWeightLastWeightIn />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <MyWeightList />
        </div>
      </div>
    </>
  );
};

export default TrackerWeight;
