import React, { useCallback, useEffect, useMemo } from 'react';

import globalHook, { Store } from 'use-global-hook';
import { DocumentNode, useLazyQuery, useMutation } from '@apollo/client';

import {
  LOGIN_MUTATION,
  SIGNUP_MUTATION,
  UPDATE_MUTATION,
  LOGOUT_MUTATION,
  IS_LOGGED_IN_QUERY,
  RESET_PASSWORD_MUTATION,
} from '../graphql/user';

import {
  IUser,
  IObject,
  TUserState,
  ISignupProps,
  ISettingsSchema,
  TUserAssociatedActions,
} from '../types';

import Storage from '../utils/Storage';

const initUser: IUser = {
  id: '',
  dob: '',
  city: '',
  email: '',
  state: '',
  address: '',
  zipcode: '',
  country: '',
  objectId: '',
  legacyId: '',
  lastName: '',
  fullName: '',
  username: '',
  firstName: '',
  createdAt: '',
  updatedAt: '',
  avatar: {
    name: '',
    url: '',
  },
  program: {
    id: '',
    name: '',
    goalLow: 0,
    orderId: '',
    phaseId: '',
    goalHigh: 0,
    objectId: '',
    learnMoreUrl: '',
    fullDescription: '',
  },
};

const setStorePointer = (propName: string) => (
  store: Store<TUserState, TUserAssociatedActions>,
  value: boolean | IUser
) => {
  const newPropValue: IObject = {};
  newPropValue[propName] = value;
  store.setState({ ...store.state, ...newPropValue });
};

const initialState: TUserState = {
  user: undefined,
  isLoggedIn: false,
  isLoadingLoginMutation: false,
  isLoadingUpdateMutation: false,
  isLoadingSignupMutation: false,
  isLoadingLoggedInMutation: true,
  isLoadingResetPasswordMutation: false,
};

const globalActions = {
  setUser: setStorePointer('user'),
  setIsLoggedIn: setStorePointer('isLoggedIn'),
  setIsLoadingLoginMutation: setStorePointer('isLoadingLoginMutation'),
  setIsLoadingUpdateMutation: setStorePointer('isLoadingUpdateMutation'),
  setIsLoadingSignupMutation: setStorePointer('isLoadingSignupMutation'),
  setIsLoadingLoggedInMutation: setStorePointer('isLoadingLoggedInMutation'),
  setIsLoadingResetPasswordMutation: setStorePointer(
    'isLoadingResetPasswordMutation'
  ),
};

const useGlobal = globalHook<TUserState, TUserAssociatedActions>(
  React,
  initialState,
  globalActions
);

const useAuth = (customLoginMutation?: DocumentNode) => {
  const [state, actions] = useGlobal();
  const {
    user,
    isLoggedIn,
    isLoadingLoginMutation,
    isLoadingUpdateMutation,
    isLoadingSignupMutation,
    isLoadingLoggedInMutation,
    isLoadingResetPasswordMutation,
  } = state;
  const {
    setUser,
    setIsLoggedIn,
    setIsLoadingLoginMutation,
    setIsLoadingUpdateMutation,
    setIsLoadingSignupMutation,
    setIsLoadingLoggedInMutation,
    setIsLoadingResetPasswordMutation,
  } = actions;

  const [loginMutation] = useMutation(customLoginMutation || LOGIN_MUTATION);
  const [logoutMutation] = useMutation(LOGOUT_MUTATION);
  const [signupMutation] = useMutation(SIGNUP_MUTATION);
  const [updateMutation] = useMutation(UPDATE_MUTATION);
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD_MUTATION);

  const getToken = useCallback(() => {
    return Storage.getItem('@user_token');
  }, []);

  const deleteToken = useCallback(() => {
    return Storage.removeItem('@user_token');
  }, []);

  const setToken = useCallback((sessionToken: string) => {
    return Storage.setItem('@user_token', sessionToken);
  }, []);

  const resetPassword = async (email: string) => {
    setIsLoadingResetPasswordMutation(true);
    try {
      await resetPasswordMutation({
        variables: {
          email,
        },
      });
    } catch (error) {
      const { message } = error as Error;
      throw new Error(message);
    } finally {
      setIsLoadingResetPasswordMutation(false);
    }
  };

  const forceIndicators = () => {
    setIsLoadingLoginMutation(false);
    setIsLoggedIn(true);
  };

  const login = async (
    values: { username: string; password: string },
    updateIndicators = true
  ) => {
    setIsLoadingLoginMutation(true);
    try {
      const { data: loginData } = await loginMutation({
        variables: { fields: { ...values } },
      });

      const viewer = loginData[Object.keys(loginData)[0]].viewer;
      await setToken(viewer.sessionToken);
      setUser(viewer.user as IUser);
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: 'form_complete',
          form_name: 'login-form',
          engagement_type: 'auth',
          engagement_name: 'login',
          user_id: viewer.user.objectId,
        });
      }
      if (updateIndicators) {
        forceIndicators();
      }
    } catch (error) {
      const { message } = error as Error;
      setIsLoggedIn(false);
      setIsLoadingLoginMutation(false);
      throw new Error(message);
    }
  };

  const logout = async () => {
    try {
      await logoutMutation();
      cleanUserAuth();
    } catch (error) {
      const { message } = error as Error;
      throw new Error(message);
    }
  };

  const signup = async (values: ISignupProps) => {
    let response;
    setIsLoadingSignupMutation(true);
    try {
      const {
        data: {
          createUser: { user },
        },
      } = await signupMutation({
        variables: {
          input: { fields: { ...values } },
        },
      });
      response = user;
      setIsLoadingSignupMutation(false);
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: 'form_complete',
          form_name: 'signup-form',
          engagement_type: 'sign up',
          engagement_name: 'account',
          user_id: user.objectId,
        });
      }
      return response;
    } catch (error) {
      const { message } = error as Error;
      setIsLoadingSignupMutation(false);
      throw new Error(message);
    }
  };

  const update = async (values: ISettingsSchema) => {
    setIsLoadingUpdateMutation(true);
    try {
      await updateMutation({
        variables: { ...values },
      });
      setUser({ ...(user as IUser), ...values });
    } catch (error) {
      const { message } = error as Error;
      throw new Error(message);
    } finally {
      setIsLoadingUpdateMutation(false);
    }
  };

  const cleanUserAuth = useCallback(async () => {
    setUser(null);
    setIsLoggedIn(false);
    await deleteToken();
  }, [deleteToken, setUser, setIsLoggedIn]);

  const [isLoggedInMutation] = useLazyQuery(IS_LOGGED_IN_QUERY, {
    onCompleted: useCallback(
      async ({ viewer }) => {
        try {
          setUser(viewer.user);
          setIsLoadingLoggedInMutation(false);
          setIsLoggedIn(true);
        } catch {
          await cleanUserAuth();
          setIsLoadingLoggedInMutation(false);
        }
      },
      [cleanUserAuth, setIsLoadingLoggedInMutation, setIsLoggedIn, setUser]
    ),
    onError: useCallback(async () => {
      await cleanUserAuth();
      setIsLoadingLoggedInMutation(false);
    }, [cleanUserAuth, setIsLoadingLoggedInMutation]),
  });

  useEffect(() => {
    isLoggedInMutation();
  }, [isLoggedInMutation]);

  const mappedUser: IUser = useMemo(() => {
    const fuser = user || {};
    return {
      ...initUser,
      ...fuser,
    };
  }, [user]);

  return {
    // State
    _user: user,
    user: mappedUser,
    isLoggedIn,
    isLoadingLoginMutation,
    isLoadingUpdateMutation,
    isLoadingSignupMutation,
    isLoadingLoggedInMutation,
    isLoadingResetPasswordMutation,

    // Methods
    login,
    logout,
    signup,
    update,
    setUser,
    setToken,
    getToken,
    deleteToken,
    resetPassword,
    cleanUserAuth,
    forceIndicators,
  };
};

export default useAuth;
