import { useEffect, useState } from 'react';

import { ApolloCache, FetchResult } from '@apollo/client';

import useAuth from '../../hooks/useAuth';
import useApolloCrud from '../../hooks/useApolloCrudv2';
import { updateCache } from '../../utils/GraphqlHelper';
import {
  GET_USER_FOOD_FAVORITES,
  CREATE_USER_FOOD_FAVORITE,
  DELETE_USER_FOOD_FAVORITE,
} from '../../graphql/userFoodFavorite';
import {
  TAny,
  TGraphQLList,
  IFavoriteFood,
  TGraphQLResponse,
} from '../../types';

export type TTypes = 'all' | 'food' | 'recipe' | 'custom-recipe';

const OPITIMISTIC_FAVORITE = {
  fiber: 0,
  name: '',
  protein: 0,
  userId: '',
  totalFat: 0,
  netCarbs: 0,
  calories: 0,
  foodId: null,
  objectId: '',
  recipeId: '',
  disabled: false,
  servingUnit: '',
  userRecipeId: '',
  servingSize: null,
  id: 'VXNlckZvb2RGYXZvcml0ZTp3a1BIVjRqT1c5OPTIMISTICID',
};

type TQuery = TGraphQLResponse<
  'userFoodFavorites',
  TGraphQLList<IFavoriteFood>
>;

const getFilter = (type: TTypes, lastRecipeId?: string) => {
  let typeToGet = {};

  switch (type) {
    case 'recipe':
      typeToGet = { recipeId: { greaterThan: '1' } };
      break;
    case 'food':
      typeToGet = { foodId: { greaterThan: '1' } };
      break;
    case 'custom-recipe':
      typeToGet = { userRecipeId: { greaterThan: '1' } };
      break;
  }

  if (lastRecipeId) {
    typeToGet = { recipeId: { equalTo: lastRecipeId } };
  }

  return typeToGet;
};

const useFavorites = (preloadCollection?: TTypes, limit = 14) => {
  const { user } = useAuth();
  const [lastRecipeId, setLastRecipeId] = useState<string>();
  const [lastSelection, setLastSelection] = useState<TTypes>('all');
  const [isFetchingMoreFavs, setisFetchingMoreFavs] = useState(false);

  const { query, mutations } = useApolloCrud<TQuery, IFavoriteFood>({
    skipFirstQuery: true,
    pointerName: 'userFoodFavorites',
    queryNode: GET_USER_FOOD_FAVORITES,
    createMutationNode: CREATE_USER_FOOD_FAVORITE,
    removeMutationNode: DELETE_USER_FOOD_FAVORITE,
    queryOptions: {
      variables: {
        first: limit,
        order: ['name_ASC'],
        where: {
          recipeId: { equalTo: '' },
          userId: { equalTo: user.objectId },
        },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
    createMutationCustomUpdate: (
      cache: ApolloCache<TAny>,
      { data }: FetchResult<TAny, Record<string, TAny>, Record<string, TAny>>
    ) => {
      const typeToGet = getFilter(lastSelection, lastRecipeId);

      const recipeId = data?.createUserFoodFavorite?.userFoodFavorite?.recipeId;
      if (recipeId) {
        updateCache(GET_USER_FOOD_FAVORITES, {
          order: ['name_ASC'],
          where: {
            recipeId: { greaterThan: '1' },
            userId: { equalTo: user.objectId },
          },
        })(cache, { data });
      }

      updateCache(GET_USER_FOOD_FAVORITES, {
        order: ['name_ASC'],
        where: {
          ...typeToGet,
          userId: { equalTo: user.objectId },
        },
      })(cache, {
        data,
      });
    },
    removeMutationCustomUpdate: (
      cache: ApolloCache<TAny>,
      { data }: FetchResult<TAny, Record<string, TAny>, Record<string, TAny>>
    ) => {
      const typeToGet = getFilter(lastSelection, lastRecipeId);

      const recipeId = data?.deleteUserFoodFavorite?.userFoodFavorite?.recipeId;
      if (recipeId) {
        updateCache(GET_USER_FOOD_FAVORITES, {
          order: ['name_ASC'],
          where: {
            recipeId: { greaterThan: '1' },
            userId: { equalTo: user.objectId },
          },
        })(cache, { data });
      }

      updateCache(GET_USER_FOOD_FAVORITES, {
        order: ['name_ASC'],
        where: {
          ...typeToGet,
          userId: { equalTo: user.objectId },
        },
      })(cache, {
        data,
      });
    },
  });

  useEffect(() => {
    if (preloadCollection) {
      setLastSelection(preloadCollection);
      search(undefined, preloadCollection);
    }
  }, []);

  const favorite = query?.data?.[0];

  const onEndReached = async () => {
    if (isFetchingMoreFavs || !query.pageInfo.hasNextPage) {
      return;
    }

    setisFetchingMoreFavs(true);

    query.fetchMore &&
      (await query.fetchMore({
        variables: {
          after: query.pageInfo.endCursor,
        },
      }));

    setisFetchingMoreFavs(false);
  };

  const getByRecipeId = async (recipeId: string) => {
    setLastRecipeId(recipeId);
    return mutations.get.call({
      where: {
        recipeId: { equalTo: recipeId },
        userId: { equalTo: user.objectId },
      },
    });
  };

  const search = async (term?: string, type: TTypes = 'all', first = limit) => {
    const typeToGet = getFilter(type);
    setLastSelection(type);

    let termWhere = {};
    if (term && typeof term === 'string') {
      if (term.trim() !== '') {
        termWhere = {
          name: { text: { search: { term } } },
        };
      }
    }

    return mutations.get.call({
      first,
      order: ['name_ASC'],
      where: {
        ...typeToGet,
        ...termWhere,
        userId: { equalTo: user.objectId },
      },
    });
  };

  const toggle = async (fields: Partial<IFavoriteFood>) => {
    const favoriteId = favorite?.id;
    const recipeId = favorite?.recipeId;

    if (mutations.create.loading) {
      return;
    }

    if (favoriteId) {
      return removeById(favoriteId, recipeId);
    }

    return mutations.create.call({
      variables: {
        fields: { ...fields, userId: user.objectId },
      },
      optimisticResponse: {
        createUserFoodFavorite: {
          userFoodFavorite: { ...OPITIMISTIC_FAVORITE },
        },
      },
    });
  };

  const removeById = async (id: string, recipeId?: string) => {
    if (mutations.remove.loading) {
      return;
    }

    return mutations.remove.call({
      variables: {
        id,
      },
      optimisticResponse: {
        deleteUserFoodFavorite: {
          userFoodFavorite: {
            id,
            recipeId: recipeId ?? null,
          },
        },
      },
    });
  };

  return {
    ...query,
    search,
    toggle,
    favorite,
    removeById,
    onEndReached,
    getByRecipeId,
    mutations: {
      ...mutations,
    },
    isFetchingMore: isFetchingMoreFavs,
  };
};

export default useFavorites;
