import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import CustomRecipeIndex from 'views/app/customRecipe/CustomRecipeIndex';
import CustomRecipeForm from 'views/app/customRecipe/CustomRecipeForm';
const CustomRecipeRouter = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          exact={true}
          path={`${path}/index`}
          component={CustomRecipeIndex}
        />
        <Route
          exact={true}
          path={`${path}/form`}
          component={CustomRecipeForm}
        />
        <Route
          path={`${path}/:id/edit`}
          exact={true}
          component={CustomRecipeForm}
        />
        <Redirect to={`${path}/index`} />
      </Switch>
    </>
  );
};

export default CustomRecipeRouter;
