import { useState } from 'react';

import pick from 'lodash/pick';
import startOfDay from 'date-fns/startOfDay';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';

import useAuth from '../../hooks/useAuth';

import {
  TAny,
  IObject,
  ILogWeight,
  TGraphQLList,
  TGraphQLResponse,
  IMeasurements,
} from '../../types';
import useDate from '../../hooks/useDate';
import useApolloCrud from '../../hooks/useApolloCrudv2';
import useReportVariables from '../../hooks/useReportVariables';
import {
  CREATE_LOG_WEIGHT,
  DELETE_LOG_WEIGHT,
  UPDATE_LOG_WEIGHT,
  GET_LOG_WEIGHTS_QUERY,
  GET_ALL_LOG_WEIGHTS_QUERY,
  GET_LAST_LOG_WEIGHTS_QUERY,
  GET_FIRST_LOG_WEIGHTS_QUERY,
  GET_LOG_WEIGHTS_WHERE_QUERY,
  GET_LOG_WEIGHTS_WHERE_QUERY_ORDERED,
} from '../../graphql/logWeight';
import { kgToLb } from '../../utils/Conversions';
import { GET_LOGS } from '../../graphql/log';
import { updateCache } from '../../utils/GraphqlHelper';

import QUERY_RESULT from '../../constants/queryResult';

type TQuery = TGraphQLResponse<'logWeights', TGraphQLList<ILogWeight>>;

const today = startOfDay(new Date());

const useLogWieght = (preloadQuery = false) => {
  const { date: currentDate } = useDate();
  const { user } = useAuth();
  const client = useApolloClient();
  const reportVariables = useReportVariables();
  const fullVariables = {
    where: {
      userId: {
        equalTo: user.objectId,
      },
    },
  };

  const [queryVariables, setQueryVariables] = useState({
    userId: user.objectId,
    date: today.toISOString(),
  });

  const lastWeightQuery = useQuery<
    TGraphQLResponse<'logWeights', TGraphQLList<ILogWeight>>
  >(GET_LAST_LOG_WEIGHTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: user.objectId,
    },
  });

  const firstWeightQuery = useQuery<
    TGraphQLResponse<'logWeights', TGraphQLList<ILogWeight>>
  >(GET_FIRST_LOG_WEIGHTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: user.objectId,
    },
  });

  const yearHistorical = useQuery<TQuery>(GET_LOG_WEIGHTS_WHERE_QUERY, {
    variables: reportVariables,
  });

  const fullHistory = useQuery<TQuery>(GET_LOG_WEIGHTS_WHERE_QUERY_ORDERED, {
    variables: fullVariables,
  });

  const yearHistoricalData = yearHistorical?.data?.logWeights?.edges?.map(
    ({ node }) => node
  );

  const fullHistoryData = fullHistory?.data?.logWeights?.edges?.map(
    ({ node }) => node
  );

  const logLastWeight = (lastWeightQuery.data?.logWeights &&
    lastWeightQuery.data.logWeights.edges.map(({ node }) => node)[0]) || {
    id: '',
    weight: 0,
    userId: user.objectId,
    loggedAt: new Date().toDateString(),
  };

  const logFirstWeight = (firstWeightQuery.data?.logWeights &&
    firstWeightQuery.data.logWeights.edges.map(({ node }) => node)[0]) || {
    id: '',
    weight: 0,
    userId: user.objectId,
    loggedAt: new Date().toDateString(),
  };

  const { query, mutations } = useApolloCrud<TQuery, ILogWeight>({
    skipFirstQuery: !preloadQuery,
    queryNode: GET_LOG_WEIGHTS_QUERY,
    createMutationNode: CREATE_LOG_WEIGHT,
    removeMutationNode: DELETE_LOG_WEIGHT,
    updateMutationNode: UPDATE_LOG_WEIGHT,
    pointerName: 'logWeights',
    queryOptions: {
      variables: queryVariables,

      nextFetchPolicy: 'cache-first',
      fetchPolicy: 'cache-and-network',
    },
  });

  const [getHistorical, historicalQuery] = useLazyQuery<
    TGraphQLResponse<'logWeights', TGraphQLList<ILogWeight>>
  >(GET_ALL_LOG_WEIGHTS_QUERY, {
    variables: {
      first: 10000,
      userId: user.objectId,
    },
  });

  const historicalLogs = historicalQuery.data?.logWeights?.edges?.map(
    ({ node }) => node
  );

  const save = async (
    log: TAny | null,
    date: Date,
    weight: number,
    weightUnit = 'kg',
    extra?: IMeasurements
  ) => {
    let value = weight;
    if (weightUnit === 'kg') {
      value = kgToLb(value);
    }

    if (!log) {
      const { data } = await client.query({
        query: GET_LOG_WEIGHTS_QUERY,
        variables: { userId: user.objectId, date: date.toISOString() },
      });

      const existingLog = data.logWeights?.edges?.[0];
      if (existingLog) {
        log = existingLog.node;
      } else {
        log = {};
      }
    }

    const action = log.id ? mutations.update.call : mutations.create.call;
    let variables: IObject = {
      id: log.id,
      userId: user.objectId,
      loggedAt: date.toISOString(),
      weight: +value,
      units: 'pounds',
      ...(extra && extra),
    };

    if (!log.id) {
      const fields = { ...variables };
      variables = { fields };
    }

    return action({
      variables,
      refetchQueries: [
        {
          query: GET_LAST_LOG_WEIGHTS_QUERY,
          variables: {
            userId: user.objectId,
          },
        },
      ],
      update: (query, document) => {
        updateCache(GET_LOGS, {
          userId: user.objectId,
          date: date.toISOString(),
        })(query, document);
        updateCache(GET_LOG_WEIGHTS_WHERE_QUERY, reportVariables)(
          query,
          document
        );
        updateCache(GET_LOG_WEIGHTS_WHERE_QUERY_ORDERED, fullVariables)(
          query,
          document
        );
        updateCache(GET_ALL_LOG_WEIGHTS_QUERY, {
          first: 10000,
          userId: user.objectId,
        })(query, document);
      },
      optimisticResponse: {
        [log.id ? 'updateLogWeight' : 'createLogWeight']: {
          logWeight: {
            weight: +value,
            units: 'pounds',
            userId: user.objectId,
            loggedAt: date.toISOString(),
            id: log.id || 'OptimisticId' + Math.random(),
          },
        },
      },
    });
  };

  const remove = (id: string) => {
    mutations.remove.call({
      variables: {
        id,
      },
      update: (query, document) => {
        updateCache(GET_LOGS, {
          userId: user.objectId,
          date: currentDate.toISOString(),
        })(query, document);
        updateCache(GET_LOG_WEIGHTS_WHERE_QUERY, reportVariables)(
          query,
          document
        );
        updateCache(GET_LOG_WEIGHTS_WHERE_QUERY_ORDERED, fullVariables)(
          query,
          document
        );
        updateCache(GET_ALL_LOG_WEIGHTS_QUERY, {
          first: 10000,
          userId: user.objectId,
        })(query, document);
      },
      refetchQueries: ['getLastLogWeights'],
    });
  };

  return {
    save,
    query,
    remove,
    mutations,
    setQueryVariables,
    lastWeight: {
      ...pick(lastWeightQuery, QUERY_RESULT),
      _data: lastWeightQuery.data,
      data: logLastWeight,
    },
    firstWeight: {
      ...pick(firstWeightQuery, QUERY_RESULT),
      _data: firstWeightQuery.data,
      data: logFirstWeight,
    },
    historical: {
      ...pick(historicalQuery, QUERY_RESULT),
      get: getHistorical,
      _data: historicalQuery.data,
      data: historicalLogs,
    },
    yearHistorical: {
      ...pick(yearHistorical, QUERY_RESULT),
      _data: yearHistorical?.data,
      data: yearHistoricalData,
    },
    fullHistory: {
      ...pick(fullHistory, QUERY_RESULT),
      _data: fullHistory?.data,
      data: fullHistoryData,
    },
  };
};

export default useLogWieght;
