import FitnessCard from 'components/FitnessCard';

import SportImg from 'assets/icons/sport-blue.svg';

const TrackerFitness = () => (
  <div className="row">
    <div className="col-12 col-xl-8">
      <FitnessCard
        variant="full"
        cardTitleProps={{
          icon: SportImg,
          title: 'My Fitness',
          subtitle: 'Track how many steps you take daily.',
        }}
      />
    </div>
  </div>
);

export default TrackerFitness;
