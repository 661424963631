import merge from 'lodash/merge';
import { JSONSchemaType } from 'ajv';
import cloneDeep from 'lodash/cloneDeep';

import { IObject, ISettingsSchema } from '../types';

import ERRORS from '../constants/errors';

export const SString = {
  title: 'String',
  type: 'object',
  properties: {
    stringField: {
      type: 'string',
    },
  },
  errorMessage: {
    required: {
      stringField: ERRORS.required,
    },
  },
  required: ['stringField'],
};

export const SNumeric = {
  title: 'Numeric',
  type: 'object',
  properties: {
    numericField: {
      type: 'number',
    },
  },
  errorMessage: {
    required: {
      numericField: ERRORS.required,
    },
  },
  required: ['numericField'],
};

export const SForgotPassword = {
  title: 'Forgot password',
  type: 'object',
  properties: {
    email: {
      type: 'string',
      format: 'email',
      minLength: 4,
    },
  },
  errorMessage: {
    required: {
      email: ERRORS.required,
    },
  },
  required: ['email'],
};

export const SLogin = {
  title: 'Login form',
  type: 'object',
  properties: {
    username: {
      label: 'Email or Username',
      type: 'string',
      minLength: 4,
    },
    password: {
      type: 'string',
      uniforms: { type: 'password' },
      minLength: 4,
      maxLength: 24,
    },
  },
  errorMessage: {
    required: {
      username: ERRORS.required,
      password: ERRORS.required,
    },
  },
  required: ['username', 'password'],
};

export const SSignup = {
  title: 'Sign-up form',
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
      minLength: 1,
    },
    lastName: {
      type: 'string',
      minLength: 1,
    },
    username: {
      type: 'string',
      label: 'Username',
      minLength: 4,
    },
    email: {
      type: 'string',
      label: 'Email',
      format: 'email',
      minLength: 4,
    },
    password: {
      type: 'string',
      uniforms: { type: 'password', secureTextEntry: true },
      minLength: 6,
      maxLength: 12,
    },
    repeatPassword: {
      type: 'string',
      uniforms: { type: 'password', secureTextEntry: true },
      const: {
        $data: '1/password',
      },
    },
  },
  required: [
    'email',
    'lastName',
    'username',
    'password',
    'firstName',
    'repeatPassword',
  ],
};

export const SSettings: JSONSchemaType<ISettingsSchema> = {
  title: 'Settings form',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      uniforms: { hidden: true },
    },
    firstName: {
      type: 'string',
      minLength: 1,
    },
    lastName: {
      type: 'string',
      minLength: 1,
    },
    username: {
      type: 'string',
      label: 'Username',
      minLength: 4,
      uniforms: { disabled: true },
    },
  },
  required: ['firstName', 'lastName', 'username'],
};

export const SUserEdit = {
  title: 'User edit form',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      uniforms: { hidden: true },
    },
    firstName: {
      type: 'string',
      label: 'First Name',
      minLength: 1,
    },
    lastName: {
      type: 'string',
      label: 'Last Name',
      minLength: 1,
    },
    email: {
      type: 'string',
      minLength: 4,
      disabled: true,
    },
    address: {
      type: 'string',
    },
    city: {
      type: 'string',
    },
    zipcode: {
      type: 'string',
      label: 'Postal Code',
      minLength: 4,
    },
    country: {
      type: 'string',
    },
  },
  required: ['firstName', 'lastName', 'address', 'city', 'zipcode', 'country'],
};

export const SDietaryExclusionsSettings = {
  title: 'Dietary Exclusions Settings form',
  type: 'object',
  properties: {
    dairy: {
      type: 'boolean',
    },
    vegetable: {
      type: 'boolean',
    },
    meat: {
      type: 'boolean',
    },
    poultry: {
      type: 'boolean',
    },
    seafood: {
      type: 'boolean',
      label: 'Sea Food',
    },
    egg: {
      type: 'boolean',
    },
    fruit: {
      type: 'boolean',
    },
    soytofu: {
      type: 'boolean',
      label: 'Soy',
    },
    nuts: {
      type: 'boolean',
    },
    shellfish: {
      type: 'boolean',
    },
    wheatgluten: {
      type: 'boolean',
      label: 'Wheat Gluten',
    },
  },
  required: [],
};

export const getSchema = (
  schema: IObject,
  properties: IObject,
  nameMapper: {
    from: string;
    to: string;
  }[] = [],
  required?: string[]
) => {
  const _schema: IObject = cloneDeep(schema);
  try {
    const _properties = _schema.properties;
    const _errorMessage = _schema.errorMessage;

    _schema.required = _schema.required ?? [];

    nameMapper.forEach(({ to, from }) => {
      const index = _schema.required.indexOf(from);
      _schema.required.splice(index, 1, to);

      _properties[to] = _properties[from];
      delete _properties[from];

      if (_errorMessage?.required?.length) {
        _errorMessage.required[to] = _errorMessage.required[from];
        delete _errorMessage.required[from];
      }
    });
    _schema.properties = merge(_properties, properties);
    if (required) {
      _schema.required = [
        ...(_schema.required && _schema.required),
        ...required,
      ];
    }
  } catch {
    //something went wrong.
  }

  return _schema;
};
