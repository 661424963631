import MyFood from 'components/dashboard/MyFood';
import MyWater from 'components/dashboard/MyWater';
import MyWeight from 'components/dashboard/MyWeight';
import MyFitness from 'components/dashboard/MyFitness';

const Dashboard = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <MyFood />
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4 mb-8">
          <MyWeight />
        </div>
        <div className="col-xl-4 mb-8">
          <MyFitness />
        </div>
        <div className="col-xl-4 mb-8">
          <MyWater />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
