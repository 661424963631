import React, { useState, useCallback } from 'react';

import BModal from 'react-bootstrap/Modal';

import useModal, { ModalType } from 'hooks/useModal';

import { TAny, IObject } from 'shared/src/types';

export interface IModalChildrenProps {
  data: TAny;
  visible: boolean;
  hide: () => void;
  setTitle: (value: string) => void;
  setSubtitle: (value: string) => void;
  show: (type: ModalType, data?: IObject | undefined) => void;
}

interface IModalProps {
  title?: string;
  type: ModalType;
  subtitle?: string;
  centered?: boolean;
  sublink?: {
    text: string;
    link: string;
    prefix: string;
  };
  children(props: IModalChildrenProps): React.ReactElement;
}

const Modal = ({
  type,
  title,
  sublink,
  subtitle,
  children,
  centered,
}: IModalProps) => {
  const [_title, _setTitle] = useState(title);
  const [_subtitle, _setSubtitle] = useState(subtitle);

  const { show, hide, ...store } = useModal();
  const { data, visible } = (store as TAny)[type];

  const handleHide = () => {
    hide(type);
  };

  const setTitle = useCallback((value: string) => {
    _setTitle(value);
  }, []);

  const setSubtitle = useCallback((value: string) => {
    _setSubtitle(value);
  }, []);

  return (
    <BModal
      show={visible}
      animation={true}
      onHide={handleHide}
      centered={centered}>
      {(_title || _subtitle) && (
        <BModal.Header closeButton bsPrefix="modal-header align-items-start">
          <div>
            {_title && (
              <BModal.Title as="span" bsPrefix="h1 d-block mb-1">
                {_title}
              </BModal.Title>
            )}
            {_subtitle && <div>{_subtitle}</div>}
            {sublink && (
              <small>
                {sublink.prefix}
                <a
                  href={sublink.link}
                  target="_blank"
                  className="text-white"
                  rel="noreferrer">
                  {sublink.text}
                </a>
              </small>
            )}
          </div>
        </BModal.Header>
      )}
      <BModal.Body>
        {children({
          data,
          show,
          visible,
          setTitle,
          setSubtitle,
          hide: handleHide,
        })}
      </BModal.Body>
    </BModal>
  );
};

export default Modal;
