import SearchResultsItem from 'components/search/SearchResultsItem';

import { TABLE_THEAD_RECIPE_DETAIL } from 'constants/index';

interface RecipeDetailIngredientsProps {
  ingredients: {
    label: string;
    extra: string;
  }[];
}

const RecipeDetailIngredients = ({
  ingredients,
}: RecipeDetailIngredientsProps) => (
  <div className="h-400px" data-simplebar>
    <div className="sticky pt-4 px-8 zindex-1">
      <div className={`d-flex flex-row justify-content-between`}>
        {TABLE_THEAD_RECIPE_DETAIL.map((value: string, index: number) => (
          <span className="text-uppercase" key={`search-result-item-${index}`}>
            {value}
          </span>
        ))}
      </div>
    </div>
    <div className="my-4">
      {ingredients.map((item, index) => (
        <SearchResultsItem key={index} data={item} index={index} />
      ))}
    </div>
  </div>
);

export default RecipeDetailIngredients;
