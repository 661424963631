import { useMemo, useState } from 'react';

import useAuth from '../../hooks/useAuth';
import useAssessment from '../../hooks/useAssessment';
import useApolloCrud from '../../hooks/useApolloCrudv2';

import { TGraphQLList, IUserAssessment, TGraphQLResponse } from '../../types';

import { UPDATE_MUTATION } from '../../graphql/user';
import {
  GET_USER_ASSESSMENT_QUERY,
  GET_USER_ASSESSMENTS_QUERY,
  UPDATE_USER_ASSESSMENT_MUTATION,
} from '../../graphql/assessment';

type TQuery = TGraphQLResponse<
  'userAssessments',
  TGraphQLList<IUserAssessment>
>;

const WEIGHT_ASSESSMENT_ID = '8';

const useWeightLossGoal = (preloadQuery = false) => {
  const [isSaving, setIsSaving] = useState(false);

  const { user } = useAuth();
  const { assessments } = useAssessment();

  const { query, mutations } = useApolloCrud<TQuery, IUserAssessment>({
    skipFirstQuery: !preloadQuery,
    queryNode: GET_USER_ASSESSMENT_QUERY,
    createMutationNode: UPDATE_MUTATION,
    updateMutationNode: UPDATE_USER_ASSESSMENT_MUTATION,
    pointerName: 'userAssessments',
    queryOptions: {
      nextFetchPolicy: 'cache-first',
      fetchPolicy: 'cache-and-network',
      variables: {
        userId: user.objectId,
        assessmentId: WEIGHT_ASSESSMENT_ID,
      },
    },
  });

  const userAssessmentId = useMemo(() => query.data?.[0]?.id, [query.data]);
  const userAssessmentValue = useMemo(() => query.data?.[0]?.value, [
    query.data,
  ]);

  const weightAssessment = assessments.filter(
    ({ node }: { node: { objectId: string } }) =>
      node.objectId === WEIGHT_ASSESSMENT_ID
  )[0];

  const save = async (value: string) => {
    setIsSaving(true);
    if (!userAssessmentId) {
      await mutations.create.call({
        variables: {
          id: user.id,
          assessments: {
            createAndAdd: {
              value: String(value),
              userId: user.objectId,
              user: { link: user.id },
              assessmentId: weightAssessment.node.objectId,
              assessment: { link: weightAssessment.node.id },
            },
          },
        },
        refetchQueries: [
          {
            query: GET_USER_ASSESSMENTS_QUERY,
            variables: { userId: user.objectId },
          },
        ],
      });
    } else {
      await mutations.update.call({
        variables: {
          id: userAssessmentId,
          fields: {
            value: value,
          },
        },
        refetchQueries: [
          {
            query: GET_USER_ASSESSMENTS_QUERY,
            variables: { userId: user.objectId },
          },
        ],
      });
    }
    setIsSaving(false);
  };

  return {
    save,
    query,
    isSaving,
    mutations,
    weightAssessment,
    userAssessmentId,
    userAssessmentValue,
  };
};

export default useWeightLossGoal;
