import format from 'date-fns/format';

import { useLogWeight, useWeightLossGoal } from 'shared/src/store';
import useUserAssessment from 'shared/src/hooks/useUserAssessment';

const useWeight = () => {
  const { baseWeight } = useUserAssessment();
  const { userAssessmentValue } = useWeightLossGoal(true);
  const {
    lastWeight: { data: log, loading: isLoading },
    firstWeight: { data: logFirst, loading: isLoadingFirst },
  } = useLogWeight(true);

  const lastWeightInValue = (log.weight ?? 0).toFixed(2);
  const firstWeightInValue = (logFirst.weight ?? 0).toFixed(2);

  const lastWeightInDate =
    log.loggedAt && format(new Date(log.loggedAt), 'EEEE, MMM dd yyyy');
  const totalPoundsLost =
    log.weight && !isLoading ? baseWeight - log.weight : 0;
  const hasGained = totalPoundsLost < 0;
  const gainOrLoseText = hasGained
    ? 'Total Pounds Gained'
    : 'Total Pounds Lost';

  const goalValue = baseWeight - (parseFloat(userAssessmentValue) ?? 0);
  let fromGoal;
  if (log.id === '') {
    fromGoal = (baseWeight - goalValue).toFixed(2);
  } else {
    fromGoal = ((log.weight ?? 0) - goalValue).toFixed(2);
  }
  const goalText =
    parseFloat(fromGoal) > 0
      ? `— You are ${fromGoal} pounds from your goal.`
      : '— You have reached your goals';

  return {
    fromGoal,
    goalText,
    goalValue,
    baseWeight,
    gainOrLoseText,
    lastWeightInDate,
    lastWeightInValue,
    totalPoundsLost: Math.abs(totalPoundsLost ?? 0).toFixed(2),
  };
};

export default useWeight;
