import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

import TabWrapper from 'components/TabWrapper';
import SearchInput from 'components/search/SearchInput';
import SearchResults from 'components/search/SearchResults';
import SearchFilters from 'components/search/SearchFilters';

import TrackerTitle from './TrackerTitle';

import { TAny, IRecipe, IUserRecipe, IObject } from 'shared/src/types';
import { useLogFood, useRecipe, useUserRecipe } from 'shared/src/store';

import useFilterList from 'hooks/useFilterList';
import useModal, { ModalType } from 'hooks/useModal';
import useOutsideAlerter from 'hooks/useOutsideAlerter';

import PUBLISH_FILTER from 'shared/src/constants/publishFilter';

import SEARCH_FILTERS from 'constants/searchFilters';
import { CUSTOM_LIMITS } from 'constants/searchTabs';
import { TRACKER_TITLES, TABLE_THEAD_SEARCH } from 'constants/index';

const TrackerSearchRecipes = () => {
  const { hash } = useLocation();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  const [filter, setFilter] = useState(1);

  const { show: showModal } = useModal();

  const { setCachedFood } = useLogFood();

  const resultsRef = useRef<HTMLDivElement | null>(null);
  const {
    onEndReached: _onEndReachedRecipes,
    isFetchingMore: isFetchingMoreRecipes,
    search: { get, loading, pageInfo: pageInfoRecipes, data: recipesData },
    exclusions,
  } = useRecipe();

  const onEndReachedRecipes = () => {
    _onEndReachedRecipes('search');
  };

  const recipesQuery = {
    data: recipesData,
    pageInfo: pageInfoRecipes,
    onEndReached: onEndReachedRecipes,
    isFetchingMore: isFetchingMoreRecipes,
  };

  const userRecipesQuery = useUserRecipe(true, CUSTOM_LIMITS);

  const { setFilter: setToFilter, filteredData } = useFilterList<IUserRecipe>(
    userRecipesQuery.data
  );
  const isUserRecipe = filter === 2;
  useOutsideAlerter(resultsRef, () => !isUserRecipe && setShow(false));

  useEffect(() => {
    setShow(filter !== 1);
  }, [filter]);

  const { data, pageInfo, onEndReached, isFetchingMore } = useMemo(() => {
    return {
      ...pick(isUserRecipe ? userRecipesQuery : recipesQuery, [
        'data',
        'pageInfo',
        'onEndReached',
        'isFetchingMore',
      ]),
      ...(isUserRecipe && {
        data: filteredData,
      }),
    } as {
      pageInfo: TAny;
      isFetchingMore: boolean;
      onEndReached: () => void;
      data: (IRecipe | IUserRecipe)[];
    };
  }, [filteredData, isUserRecipe, recipesQuery, userRecipesQuery]);

  useEffect(() => {
    if (hash.includes('recipes') && firstRun) {
      setFilter(2);
      const [section, objectId] = hash.split('-');
      let index = userRecipesQuery.data.map((e) => e.id).indexOf(objectId);
      if (index === -1) {
        index = userRecipesQuery.data.map((e) => e.objectId).indexOf(objectId);
      }
      const recipe = userRecipesQuery.data[index];
      if (index !== -1) {
        setCachedFood({
          food: {
            ...omit(recipe, ['id', 'objectId']),
            [isUserRecipe ? 'userRecipeId' : 'recipeId']: objectId,
          },
        });
        showModal(ModalType.trackFood, {
          ...(!isUserRecipe && {
            recipe,
          }),
        });
      }
    }
  }, [filter, firstRun, isUserRecipe, hash, userRecipesQuery.data]);

  const recipes = (data as IRecipe[]).map(({ name, netCarbs }) => ({
    label: name,
    extra: `${(netCarbs ?? 0).toFixed(2)} g`,
  }));

  const userRecipes = (filteredData as IUserRecipe[]).map(
    ({ name, netCarbs }) => ({
      label: name,
      extra: `${(netCarbs ?? 0).toFixed(2)} g`,
    })
  );

  const handleSearch = (q: string) => {
    setShow(true);
    setToFilter(q);
    let keyIngredients: IObject = {
      keyIngredients: {
        have: {
          legacyId: { notIn: exclusions },
        },
      },
    };

    if (!exclusions.length) {
      keyIngredients = {};
    }
    get({
      variables: {
        detailed: false,
        first: CUSTOM_LIMITS,
        where: {
          ...PUBLISH_FILTER,
          ...keyIngredients,
          name: { text: { search: { term: q } } },
        },
      },
    });
  };

  const handleClickEdit = (index: number) => {
    const food = filteredData[index];
    history.push('/custom-recipes/' + food.id + '/edit');
  };

  const handleClickRemove = (index: number) => {
    const food = filteredData[index];

    showModal(ModalType.Confirmation, {
      callback: async (status: boolean) => {
        if (!status) {
          return;
        }

        try {
          await userRecipesQuery.removeById(food.id);
          toast.success('Log removed successfully');
        } catch {
          toast.error('Ups, something went wrong');
        }
      },
    });
  };

  const handleChangeFilters = (filter: string) => {
    setFilter(+filter);
    setFirstRun(false);
  };

  const handleClickItem = (index: number) => {
    !isUserRecipe && setShow(false);
    const recipe = isUserRecipe ? filteredData[index] : data[index];

    setCachedFood({
      food: {
        ...omit(recipe, ['id', 'objectId']),
        [isUserRecipe ? 'userRecipeId' : 'recipeId']: recipe.objectId,
      },
    });
    showModal(ModalType.trackFood, {
      ...(!isUserRecipe && {
        recipe,
      }),
    });
  };

  return (
    <TabWrapper>
      <TrackerTitle values={TRACKER_TITLES.FOOD} />

      <SearchFilters
        values={SEARCH_FILTERS.RECIPES}
        onChange={handleChangeFilters}
      />

      <SearchInput
        sync={isUserRecipe}
        onSearch={handleSearch}
        label="Search recipe..."
        type="searchRecipes"
      />
      <div className={`${isUserRecipe ? 'h-400px' : ''}`}>
        <SearchResults
          show={show}
          data={isUserRecipe ? userRecipes : recipes}
          loading={loading}
          onClickMore={onEndReached}
          headers={TABLE_THEAD_SEARCH}
          onClickItem={isUserRecipe ? handleClickItem : handleClickItem}
          isFetchingMore={isFetchingMore}
          showLoadMore={pageInfo?.hasNextPage}
          editButton={isUserRecipe ? true : false}
          removeButton={isUserRecipe ? true : false}
          trackButton={isUserRecipe ? true : false}
          onClickRemove={handleClickRemove}
          onClickEdit={handleClickEdit}
          onClickTrack={handleClickItem}
          onRef={(ref) => (resultsRef.current = ref)}
        />
      </div>
    </TabWrapper>
  );
};

export default TrackerSearchRecipes;
