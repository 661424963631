import EditSvg from 'assets/icons/edit.svg';
import TrashcanSvg from 'assets/icons/trashcan.svg';
import TrackSvg from 'assets/icons/check.svg';

import LogoImg from 'shared/src/assets/logo.png';

export type SearchResultsItemType = {
  label: string;
  extra: string;
  icon?: boolean;
  labelExtra?: string;
};

interface SearchResultsItemProps {
  index: number;
  editButton?: boolean;
  removeButton?: boolean;
  trackButton?: boolean;
  data: SearchResultsItemType;
  onClickItem?: (index: number) => void;
  onClickEdit?: (index: number) => void;
  onClickRemove?: (index: number) => void;
  onClickTrack?: (index: number) => void;
}

const SearchResultsItem = ({
  index,
  editButton,
  onClickItem,
  onClickEdit,
  removeButton,
  onClickRemove,
  trackButton,
  onClickTrack,
  data: { icon, label, labelExtra, extra },
}: SearchResultsItemProps) => {
  const handleClickIcon = (action?: (index: number) => void) => (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.stopPropagation();
    action && action(index);
  };

  const handleClickItem = () => {
    onClickItem && onClickItem(index);
  };

  return (
    <a className="px-4 d-block pointer" onClick={handleClickItem}>
      <div
        className={`py-4 px-4 d-flex justify-content-between rounded position-relative d-block-hover ${
          index % 2 ? 'bg-white' : 'bg-light'
        }`}>
        <div className="d-flex flex-fill justify-content-between">
          <div className="d-none position-absolute bg-warning left-0 top-0 w-10px h-100" />
          <div>
            {label}{' '}
            <small className="text-muted font-italic">{labelExtra}</small>
            {icon && (
              <img
                src={LogoImg}
                alt="Atkins Logo"
                className="max-h-20px ml-2"
              />
            )}
          </div>
          <div className="font-weight-boldest">{extra}</div>
        </div>
        {(editButton || removeButton) && (
          <div className="align-items-center ">
            {editButton && (
              <span
                className="cursor-pointer ml-8"
                onClick={handleClickIcon(onClickEdit)}>
                <img src={EditSvg} alt="Edit" />
              </span>
            )}
            {trackButton && (
              <span
                className="cursor-pointer ml-8"
                onClick={handleClickIcon(onClickTrack)}>
                <img src={TrackSvg} alt="Track" />
              </span>
            )}
            {removeButton && (
              <span
                className="cursor-pointer ml-8"
                onClick={handleClickIcon(onClickRemove)}>
                <img src={TrashcanSvg} alt="Remove" />
              </span>
            )}
          </div>
        )}
      </div>
    </a>
  );
};

export default SearchResultsItem;
