import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

import ProfileNavbar from 'components/profile/ProfileNavbar';

import ProfileChangePassword from 'views/app/profile/ProfileChangePassword';
import ProfileStartingWeight from 'views/app/profile/ProfileStartingWeight';
import ProfileDietaryExclusions from 'views/app/profile/ProfileDietaryExclusions';
import ProfilePersonalInformation from 'views/app/profile/ProfilePersonalInformation';

const ProfileRouter = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <ProfileNavbar />

      <Switch>
        <Route
          exact={true}
          path={`${path}/personal-information`}
          component={ProfilePersonalInformation}
        />
        <Route
          exact={true}
          path={`${path}/dietary-exclusions`}
          component={ProfileDietaryExclusions}
        />
        <Route
          exact={true}
          path={`${path}/change-password`}
          component={ProfileChangePassword}
        />
        <Route
          exact={true}
          path={`${path}/weight-settings`}
          component={ProfileStartingWeight}
        />

        <Redirect to={`${path}/personal-information`} />
      </Switch>
    </>
  );
};

export default ProfileRouter;
