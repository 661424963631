import TrackerSearchFoods from 'components/tracker/tabs/TrackerSearchFoods';
import TrackerSearchRecipes from 'components/tracker/tabs/TrackerSearchRecipes';
import TrackerSearchGrouping from 'components/tracker/tabs/TrackerSearchGrouping';
import TrackerSearchFavorites from 'components/tracker/tabs/TrackerSearchFavorites';
import TrackerSearchCustomFoods from 'components/tracker/tabs/TrackerSearchCustomFoods';

export default [
  {
    key: 'foods',
    component: TrackerSearchFoods,
  },
  {
    key: 'custom-foods',
    component: TrackerSearchCustomFoods,
  },
  {
    key: 'grouping',
    component: TrackerSearchGrouping,
  },
  {
    key: 'favorites',
    component: TrackerSearchFavorites,
  },
  {
    key: 'recipes',
    component: TrackerSearchRecipes,
  },
];
