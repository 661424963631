import { toast } from 'react-toastify';
import { AutoForm, AutoFields } from 'uniforms-bootstrap4';

import Modal, { IModalChildrenProps } from 'components/ui/Modal';

import { SStepTrack } from 'forms/schemas';

import { ModalType } from 'hooks/useModal';

import { useLogStep } from 'shared/src/store';
import useForm from 'shared/src/hooks/useForm';
import useDate from 'shared/src/hooks/useDate';

import Submit from 'shared/src/forms/fields/web/Submit';

const TrackStepsContent = ({ data, hide }: IModalChildrenProps) => {
  const { date } = useDate();
  const { save } = useLogStep();

  const { log } = data ?? {};

  const { schema } = useForm(SStepTrack, {
    date: date.toISOString(),
    ...(log && {
      steps: log.steps,
      date: log.loggedAt ?? log.cratedAt ?? date.toISOString(),
    }),
  });

  const handleSubmit = ({ date, steps }: { date: string; steps: number }) => {
    try {
      save(log, new Date(date), steps);
      window.dataLayer.push({
        event: 'form_complete',
        form_name: 'fitness-form',
        engagement_type: 'tracker usage',
        engagement_name: 'steps',
      });
      toast.success('Steps logged successfully');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    } finally {
      hide();
    }
  };

  return (
    <div className="form--dark form--size-lg form--grid-small">
      <AutoForm schema={schema} showInlineError onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-md-6">
            <AutoFields />
          </div>
        </div>
        <Submit text="Save" textWorking="Saving" />
      </AutoForm>
    </div>
  );
};

const TrackSteps = () => (
  <Modal
    type={ModalType.trackSteps}
    title="Track Steps"
    subtitle="Enter your steps and track the progress towards your goals.">
    {(props: IModalChildrenProps) => <TrackStepsContent {...props} />}
  </Modal>
);

export default TrackSteps;
