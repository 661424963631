import { gql } from '@apollo/client';

export const RECIPE_COURSE_SCHEMA = `
  id
  name
`;

export const RECIPE_COURSES_SCHEMA = `
  recipeCourses {
    edges {
      node {
        ${RECIPE_COURSE_SCHEMA}
      }
    }
  }
`;

export const GET_RECIPE_COURSES_QUERY = gql`
  query getRecipeCourses {
    ${RECIPE_COURSES_SCHEMA}
  }
`;
