import { useEffect } from 'react';

import { toast } from 'react-toastify';

import Card from 'components/ui/Card';
import CardTitle from 'components/ui/CardTitle';

import TrackerMealsItem from './TrackerMealsItem';

import useDate from 'shared/src/hooks/useDate';
import useModal, { ModalType } from 'hooks/useModal';

import { useLogFood } from 'shared/src/store';

import { MEALS } from 'shared/src/constants';

import folkIcon from 'assets/icons/folk.svg';

import { MEALS_HEADERS } from 'constants/index';

import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

interface ITrackerMeals {
  values: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

const TrackerMeals = ({ values, setValue }: ITrackerMeals) => {
  const { date } = useDate();
  const {
    remove,
    setCachedFood,
    query: { data, refetch },
  } = useLogFood(true);

  const { show } = useModal();

  useEffect(() => {
    const dateStart = startOfDay(date);
    const dateEnd = endOfDay(date);
    refetch({
      dateStart,
      dateEnd,
    });
  }, [date, values]);

  const handleEdit = (logId: string) => {
    const log = data.filter(({ id }) => id === logId)[0];
    setCachedFood({
      meal: log.meal,
      date: new Date(log.loggedAt),
      food: {
        ...log,
        name: log.foodName,
      },
    });
    show(ModalType.trackFood, { hideFavorite: true });
  };

  const handleRemove = (id: string) => {
    try {
      remove(id);
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    } finally {
      setValue(values + 1);
    }
  };

  return (
    <Card>
      <CardTitle
        icon={folkIcon}
        title="My food"
        subtitle="Meals, net carbs, calories, fat, protein and fiber."
      />

      <div>
        {MEALS.map(({ name }) => (
          <div key={`tracker-meal-${name}`}>
            <div className="h1">{name}</div>
            <div className="grid-40 d-none d-md-grid">
              <span className="text-uppercase pl-4">food</span>
              <div
                className={`d-flex flex-row justify-content-between mr-4 mb-4`}>
                {MEALS_HEADERS.map(({ label }, index) => (
                  <span
                    className="text-uppercase text-center w-20"
                    key={`search-result-item-${index}`}>
                    {label}
                  </span>
                ))}
                <span className="w-10"></span>
                <span className="w-10"></span>
              </div>
            </div>
            <div>
              {data
                .filter(({ meal }) => meal.toString() === name)
                .map((meal, index) => (
                  <TrackerMealsItem
                    data={meal}
                    index={index}
                    onEdit={handleEdit}
                    onRemove={handleRemove}
                    key={`tracker-meal-${name}-item-${meal.id}`}
                  />
                ))}
            </div>

            <hr className="my-8" />
          </div>
        ))}
      </div>
    </Card>
  );
};

export default TrackerMeals;
