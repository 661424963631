import { useEffect } from 'react';

import Button from 'react-bootstrap/Button';

import Modal, { IModalChildrenProps } from 'components/ui/Modal';

import useModal, { ModalType } from 'hooks/useModal';
import usePrograms from 'shared/src/hooks/usePrograms';

const AssessmentPlanContent = ({ hide }: IModalChildrenProps) => {
  const { userProgram, userPlan, userPhase, userPlanRanges } = usePrograms();

  return (
    <>
      <div className="text-primary h3">The best for you</div>
      <div className="h1">
        {userPlan} <span>{userPhase}</span>
      </div>
      {userPlanRanges && (
        <div className="d-inline-block rounded bg-light text-primary p-8 mt-8 h1">
          {userPlanRanges}
        </div>
      )}
      {userProgram.fullDescription && (
        <p className="pr-6 mt-8">{userProgram.fullDescription}</p>
      )}

      {userProgram.learnMoreUrl && (
        <div>
          <a
            href={userProgram.learnMoreUrl}
            target="_blank"
            rel="noreferrer"
            className="text-decoration-underline">
            Learn more
          </a>
        </div>
      )}

      <Button onClick={hide} className="px-16 mt-16">
        Continue to Atkins dashboard
      </Button>
    </>
  );
};

const AssessmentPlan = () => {
  const {
    show,
    assessmentPlan: { data: { autoload = false } = {} } = {},
  } = useModal();

  useEffect(() => {
    autoload && show(ModalType.assessmentPlan, { autoload: false });
  }, [autoload]);

  return (
    <Modal type={ModalType.assessmentPlan} title="Welcome to Atkins">
      {(props: IModalChildrenProps) => <AssessmentPlanContent {...props} />}
    </Modal>
  );
};

export default AssessmentPlan;
