import { ModalType } from 'hooks/useModal';

import Modal, { IModalChildrenProps } from 'components/ui/Modal';

const ErrorModalContent = ({ data }: IModalChildrenProps) => (
  <div>
    <div className="bg-danger p-12 rounded text-white h3">
      {data?.message ?? ''}
    </div>
  </div>
);

const ErrorModal = () => (
  <Modal type={ModalType.errorModal} title="Error" centered>
    {(props: IModalChildrenProps) => <ErrorModalContent {...props} />}
  </Modal>
);

export default ErrorModal;
