import { useEffect, useState } from 'react';

import useAuth from 'shared/src/hooks/useAuth';

import { getCookie, eraseCookie, setCookie } from 'helpers/cookies';

const useCookieChecker = () => {
  const [isCookieChecked, setIsCookieChecked] = useState(false);
  const {
    getToken,
    setToken,
    isLoggedIn,
    isLoadingLoggedInMutation: isLoading,
  } = useAuth();
  useEffect(() => {
    (async () => {
      if (!isLoading) {
        if (!isLoggedIn) {
          const stoken = getCookie('atkins_session');
          if (stoken && stoken !== '') {
            setCookie('atkins_session_cached', stoken, 120, 'app.atkins.com');
            eraseCookie('atkins_session');
            eraseCookie('atkins_session', '.app.atkins.com');

            setTimeout(async () => {
              await setToken(stoken);
              window.location.href = window.location.origin;
            }, 1000);
          } else {
            setIsCookieChecked(true);
          }
        } else {
          const stoken = getCookie('atkins_session');
          if (stoken) {
            setCookie('atkins_session_cached', stoken, 120, 'app.atkins.com');
          }
          eraseCookie('atkins_session');
          eraseCookie('atkins_session', '.app.atkins.com');
          setIsCookieChecked(true);
        }
      }
    })();
  }, [isLoggedIn, isLoading]);

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        const token = await getToken();
        if (token) {
          setCookie('web_user_token', token, 120);
          setCookie('web_user_token', token, 120, '.app.atkins.com');
        }
      }
    })();
  }, [isLoggedIn]);

  return !isLoading && isCookieChecked;
};

export default useCookieChecker;
