import { useEffect, useState } from 'react';

import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { toast } from 'react-toastify';
import { AutoForm, AutoField, AutoFields } from 'uniforms-bootstrap4';

import ProTip from 'components/ProTip';
import Modal, { IModalChildrenProps } from 'components/ui/Modal';

import Submit from 'shared/src/forms/fields/web/Submit';

import { SCustomFood, SQuickEntry } from 'forms/schemas';

import { ModalType } from 'hooks/useModal';

import useAuth from 'shared/src/hooks/useAuth';
import useDate from 'shared/src/hooks/useDate';
import useForm from 'shared/src/hooks/useForm';

import { useFavorites, useLogFood, useUserFood } from 'shared/src/store';
import { TAny, ICustomFoodSchema, IQuickEntrySchema } from 'shared/src/types';

import NUTRITIONAL_VALUES from 'shared/src/constants/nutritionalValues';

const NewEntryContent = ({ data, hide, setTitle }: IModalChildrenProps) => {
  const [isSaving, setIsSaving] = useState(false);

  const { date } = useDate();
  const { user } = useAuth();

  const { food, isQuick = false } = data ?? {};

  const formSchema = isQuick ? SQuickEntry : SCustomFood;
  const { schema } = useForm(formSchema, {
    ...(food ?? {}),
    date: date.toISOString(),
  });

  const {
    mutations: {
      create: { call: saveFavorite },
    },
  } = useFavorites();
  const { save: saveLog } = useLogFood();
  const { save: saveUserFood } = useUserFood();

  useEffect(() => {
    setTitle(title);
  }, []);

  let title = 'Edit Custom Food';
  let submitText = 'Update food';
  let nameAndDateFields = ['name', 'date'];
  const tipText = isQuick
    ? 'One time entry unless added to favourites.'
    : 'Create a custom food entry';

  if (!food) {
    title = isQuick ? 'Quick Entry' : 'New Entry';
    submitText = 'Add to food tracker';
    nameAndDateFields = ['name'];
  }

  const handleSubmit = async (
    data: Partial<ICustomFoodSchema | IQuickEntrySchema>
  ) => {
    const { date, meal } = data;
    const newEntry = {
      ...omit(data, 'date', 'meal', 'favorite'),
      servingSize: 1,
      userId: user.objectId,
      servingUnit: 'servings',
    };

    try {
      setIsSaving(true);

      let userFoodId;
      if (!isQuick) {
        const { objectId } = await saveUserFood({
          ...omit(newEntry, ['fat']),
          ...(food && { id: food.id }),
          totalFat: newEntry.fat,
        });
        userFoodId = objectId;
      }

      if (!food) {
        const newEntryWithId = {
          ...newEntry,
          ...(userFoodId && {
            userFoodId,
          }),
        };
        saveLog(
          new Date(date as string),
          meal as string,
          1,
          newEntryWithId as TAny
        );

        if ((data as ICustomFoodSchema).favorite) {
          saveFavorite({
            variables: {
              fields: pick(newEntryWithId, [
                ...NUTRITIONAL_VALUES.SHORT_SERVING,
                'name',
                'userId',
                'userFoodId',
              ]),
            },
          });
        }
      }
      window.dataLayer.push({
        event: 'form_complete',
        form_name: 'food-form',
        engagement_type: 'tracker usage',
        engagement_name: 'food',
      });

      toast.success(isQuick ? 'Quick entry added' : 'Custom food added');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    } finally {
      setIsSaving(false);
      hide();
    }
  };

  return (
    <div className="form--dark form--size-lg">
      <ProTip backgroundColor="bg-gray-100" title="" text={tipText} />
      <AutoForm schema={schema} showInlineError onSubmit={handleSubmit}>
        <AutoFields fields={nameAndDateFields} />

        {!food && (
          <div className="row">
            <div className="col-12 col-md-4">
              <AutoField name="meal" />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12 col-md-6 form--grid">
            <AutoFields omitFields={['name', 'date', 'meal', 'favorite']} />
          </div>
        </div>

        {!food && <AutoField name="favorite" />}

        <Submit working={isSaving} text={submitText} textWorking="Saving" />
      </AutoForm>
    </div>
  );
};

const NewEntry = () => (
  <Modal type={ModalType.newEntry}>
    {(props: IModalChildrenProps) => <NewEntryContent {...props} />}
  </Modal>
);

export default NewEntry;
