import { useEffect, useRef, useState, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import { DeepPartial } from 'uniforms';
import { AutoForm, AutoField, AutoFields } from 'uniforms-bootstrap4';
import FoodImg from 'assets/recipeFormBg.png';
import Card from 'components/ui/Card';
import CardTitle from 'components/ui/CardTitle';
import CardInner from 'components/ui/CardInner';
import AsideMenu from 'components/customRecipe/AsideMenu';
import { slugify } from 'shared/src/utils/String';
import { IRecipeForm } from 'types';
import cloneDeep from 'lodash/cloneDeep';
import SearchInput from 'components/search/SearchInput';
import useForm from 'shared/src/hooks/useForm';
import pick from 'lodash/pick';
import { ELEMENTS } from 'hooks/useServing';
import { convertToGraphqlModel } from 'shared/src/utils/GraphqlHelper';
import { useParams } from 'react-router-dom';

import { SCustomRecipeForm } from 'forms/schemas';

import { IRecipe, TAny } from 'shared/src/types';

import useApolloCrud from 'shared/src/hooks/useApolloCrud';

import {
  GET_USER_RECIPES,
  CREATE_USER_RECIPE,
  UPDATE_USER_RECIPE,
  DELETE_USER_RECIPE,
} from 'shared/src/graphql/userRecipe';
import useAuth from 'shared/src/hooks/useAuth';

type TQuery = {
  recipes: { edges: { node: IRecipe }[] };
};

const CustomRecipeForm = () => {
  const formRef = useRef<TAny>(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const isEdit = !!id;
  const { user } = useAuth();
  const [addToTracker, setAddToTracker] = useState(false);
  const [isNewFormat, setIsNewFormat] = useState(false);
  const [displayCss, setDisplayCss] = useState('');
  /*
  useEffect(() => {
    formRef?.current?.reset();
  }, [startingWeight.query.data, weightLossGoal.query.data, loggedAt]);
  */

  const handleDelete = async () => {
    try {
      setIsLoadingDelete(false);
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  const {
    query: { data, loading, refetch },
    mutations: {
      create: { call: create },
      update: { call: update },
    },
  } = useApolloCrud<TQuery, IRecipe>({
    queryNode: GET_USER_RECIPES,
    queryOptions: {
      fetchPolicy: 'network-only',
      variables: {
        first: 1,
        where: { id: { equalTo: id }, userId: { equalTo: user.objectId } },
      },
    },
    cachePointers: {
      internal: true,
    },
    skipFirstQuery: !isEdit,
    createMutationNode: CREATE_USER_RECIPE,
    updateMutationNode: UPDATE_USER_RECIPE,
  });

  const recipe: Partial<IRecipe> = useMemo(() => {
    if (isEmpty(data)) {
      return {};
    }
    if (data[0].createdAt) {
      const year = new Date(data[0].createdAt);
      if (year.getFullYear() >= 2022) {
        setIsNewFormat(true);
      } else {
        setDisplayCss('old-recipe');
      }
    }
    return data[0];
  }, [data, isNewFormat]);

  const { schema } = useForm(SCustomRecipeForm, recipe);

  const handleSubmit = async (_data: DeepPartial<IRecipeForm>) => {
    setIsLoading(true);
    const data = cloneDeep(_data);
    data.foods = (data.foods ?? []).map((food: TAny) => {
      const { conversion } = food;
      const toRet = {
        ...pick(food, ['order', 'servingUnit']),
        fat: food.fat || food.totalFat || 0,
        name:
          food.food?.verboseName ??
          food.name ??
          food.foodName ??
          food.food.name ??
          food.food.foodName,
        brandName: food.brandName ?? food.food?.brandName ?? '',
        servingSize: +food.servingSize ?? 1,
        food: { link: food.food?.id ?? '' },
      };
      ELEMENTS.forEach((key) => {
        const name = key === 'totalFat' ? 'fat' : key;
        toRet[name as keyof typeof toRet] =
          ((conversion[name as keyof typeof conversion] ?? 0) *
            +food.servingSize) /
          +conversion.servingSize;
      });

      return toRet;
    });

    data.recipes = ((data.recipes as TAny) ?? []).map(
      ({ food, order, servingSize }: TAny) => ({
        order,
        quantity: servingSize,
        recipeId: food.objectId,
        includedRecipe: {
          ...food,
          fat: food.totalFat || food.fat || 0,
        },
      })
    );

    try {
      let cdata = await convertToGraphqlModel(data, recipe, ['foods']);
      if ((!cdata.image && !data.image) || cdata?.image?.remove) {
        cdata.image = {
          file: null,
        };
      }

      cdata.userId = user.objectId;

      if (!isEmpty(cdata.servingTable)) {
        const servingTable = { ...cdata.servingTable };
        cdata = { ...cdata, ...servingTable };
      }

      delete cdata.servingTable;

      cdata.totalFat = cdata.totalFat || cdata.fat || 0;
      delete cdata.fat;

      if (isEdit) {
        await update({ variables: { fields: cdata, id } });
        if (addToTracker) {
          window.location.href = `/tracker/food#recipes-${id}`;
          return;
        }
      } else {
        const {
          data: {
            createUserRecipe: {
              userRecipe: { objectId },
            },
          },
        } = await create({ variables: { fields: cdata } });

        if (addToTracker) {
          window.location.href = `/tracker/food#recipes-${objectId}`;
        } else {
          window.location.href = `/custom-recipes/${objectId}/edit`;
        }

        return;
      }

      await refetch();

      toast.success(`Recipe ${isEdit ? 'updated' : 'saved'} successfuly`);
    } catch (e) {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <AutoForm
        ref={formRef}
        schema={schema}
        showInlineError
        onSubmit={handleSubmit}
        onChange={(key: any, value: any) => {
          const model = formRef?.current?.state?.model;
          if (
            !recipe?.objectId &&
            key === 'name' &&
            (isEmpty(model?.recipeUrl) ||
              slugify(model?.name) === model?.recipeUrl)
          ) {
            //formRef.current.onChange('recipeUrl', slugify(value));
          }
        }}>
        <div className="row custom-recipe-builder">
          <div className="col-12 col-md-8">
            <Card>
              <h3 className="mb-8 aside fw-700">Recipe Name</h3>
              <CardInner backgroundImage={FoodImg}>
                <div className="row">
                  <div className="col-12">
                    <AutoField name="name" label={false} />
                  </div>
                </div>
              </CardInner>
            </Card>
            <div className="row">
              <div className="col-12">
                <Card>
                  <h3 className="mb-8 aside fw-700">Ingredients</h3>
                  <CardInner styles="mt-4">
                    <div className="row">
                      <div className={`col-12 foods-list ${displayCss}`}>
                        <div className={`hidden mb-4 div-label ${displayCss}`}>
                          Ingredients in recipes created before August of 2022
                          can be viewed, but cannot be edited.
                        </div>
                        <AutoField
                          name="foods"
                          disabled={isEdit && !isNewFormat}
                          label={false}
                        />
                      </div>
                    </div>
                  </CardInner>
                  <h3 className="mt-8 mb-8 aside fw-700">Directions</h3>
                  <CardInner styles="mt-4">
                    <div className="row">
                      <div className="col-12 directions-label">
                        <AutoField name="directions" label={false} />
                      </div>
                    </div>
                  </CardInner>
                  <CardInner styles="mt-4">
                    <div className="row">
                      <div className="col-12">
                        <AutoField name="image" />
                      </div>
                    </div>
                  </CardInner>
                </Card>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <Card>
              <div className="col">
                <h3 className="mb-3 aside fw-600">Recipe Nutritionals</h3>
              </div>
              <AsideMenu
                submit
                setAddToTracker={setAddToTracker}
                addToTracker={addToTracker}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
                onDelete={handleDelete}
                recipeId={recipe.objectId}
              />
            </Card>
          </div>
        </div>
      </AutoForm>
    </div>
  );
};

export default CustomRecipeForm;
