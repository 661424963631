import { useMemo, useRef } from 'react';

import omit from 'lodash/omit';
import { toast } from 'react-toastify';
import { AutoForm, AutoFields } from 'uniforms-bootstrap4';

import { ModalType } from 'hooks/useModal';

import { SBasicTrack } from 'forms/schemas';

import useForm from 'shared/src/hooks/useForm';

import Submit from 'shared/src/forms/fields/web/Submit';

import { useLogFood } from 'shared/src/store';
import { TAny, IGrouping } from 'shared/src/types';

import Modal, { IModalChildrenProps } from 'components/ui/Modal';

const TrackGroupingContent = ({ data, hide }: IModalChildrenProps) => {
  const formRef = useRef<TAny>(null);

  const { schema } = useForm(SBasicTrack);
  const { savePreCalculated } = useLogFood();

  const grouping = data?.grouping as IGrouping;
  const foods = grouping?.foods?.edges.map(({ node }) => node) ?? [];

  const convertedValuesByUnit = useMemo(() => {
    const values = {
      fat: 0,
      protein: 0,
      calories: 0,
      netCarbs: 0,
    };
    foods.forEach((food) => {
      values.fat += food.fat ?? 0;
      values.protein += food.protein ?? 0;
      values.calories += food.calories ?? 0;
      values.netCarbs += food.netCarbs ?? 0;
    });

    return values;
  }, [foods]);

  const handleSubmit = ({
    meal,
    date,
  }: Partial<{ meal: string; date: string }>) => {
    try {
      for (const food of foods) {
        savePreCalculated(
          new Date(date!),
          meal!,
          omit({ ...food, name: food.foodName }, ['id'])
        );
      }

      toast.success('Group logged');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    } finally {
      hide();
    }
  };

  return (
    <div className="form--dark form--size-lg form--grid-small">
      <div>
        <span className="h3">{grouping?.groupName ?? ''}</span>

        <div className="mb-3">
          <div>
            Fat:{' '}
            <span className="text-primary">
              {(convertedValuesByUnit.fat ?? 0).toFixed(2)}g
            </span>
          </div>
          <div>
            Protein:{' '}
            <span className="text-primary">
              {(convertedValuesByUnit.protein ?? 0).toFixed(2)}g
            </span>
          </div>
          <div>
            Calories:{' '}
            <span className="text-primary">
              {(convertedValuesByUnit.calories ?? 0).toFixed(2)}
            </span>
          </div>
          <div>
            Net Carbs:{' '}
            <span className="text-primary">
              {(convertedValuesByUnit.netCarbs ?? 0).toFixed(2)}g
            </span>
          </div>
        </div>
      </div>

      {!!foods?.length && (
        <>
          <div className="h4 mb-2">Foods</div>
          <div className="max-h-400px" data-simplebar>
            {foods.map(({ id, foodName }, index) => (
              <div
                key={`grouping-food-${id}-${index}`}
                className="p-4 bg-gray-100 rounded mb-4">
                <span>{foodName}</span>
              </div>
            ))}
          </div>
        </>
      )}

      <hr className="my-8" />
      <AutoForm
        ref={formRef}
        schema={schema}
        showInlineError
        onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-md-6">
            <AutoFields />
          </div>
        </div>

        <Submit text="Log grouping" textWorking="Saving" />
      </AutoForm>
    </div>
  );
};

const TrackGrouping = () => (
  <Modal type={ModalType.trackGrouping} title="Track grouping">
    {(props: IModalChildrenProps) => <TrackGroupingContent {...props} />}
  </Modal>
);

export default TrackGrouping;
