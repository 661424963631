import { toast } from 'react-toastify';

import TrackerTitle from './TrackerTitle';
import TabWrapper from 'components/TabWrapper';
import SearchInput from 'components/search/SearchInput';
import SearchResults from 'components/search/SearchResults';

import useFilterList from 'hooks/useFilterList';
import useModal, { ModalType } from 'hooks/useModal';

import { IGrouping } from 'shared/src/types';
import { useFoodGrouping } from 'shared/src/store';

import { CUSTOM_LIMITS } from 'constants/searchTabs';
import { TABLE_THEAD_SEARCH, TRACKER_TITLES } from 'constants/index';

interface IGroupingNamed extends IGrouping {
  name: string;
}

const TrackerSearchGrouping = () => {
  const { show: showModal } = useModal();
  const {
    data,
    pageInfo,
    removeById,
    onEndReached,
    isFetchingMore,
  } = useFoodGrouping(true, CUSTOM_LIMITS);
  const { setFilter, filteredData } = useFilterList<IGroupingNamed>(
    data.map((node) => ({ ...node, name: node.groupName }))
  );

  const groupings = filteredData.map(({ id, name, foods }) => {
    const totalNetCarbs =
      foods?.edges?.reduce(
        (accumulative, { node }) => accumulative + (node.netCarbs ?? 0),
        0
      ) ?? 0;
    return {
      id,
      label: name,
      extra: `${(totalNetCarbs ?? 0).toFixed(2)} g`,
    };
  });

  const handleClickItem = (index: number) => {
    const groupingId = filteredData[index]?.id;
    const grouping = data.filter(({ id }) => id === groupingId)[0];

    showModal(ModalType.trackGrouping, { grouping });
  };

  const handleClickRemove = (index: number) => {
    const grouping = filteredData[index];

    try {
      removeById(grouping.id);
      toast.success('Grouping removed successfully');
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <TabWrapper>
      <TrackerTitle values={TRACKER_TITLES.FOOD} />

      <SearchInput
        label="Search grouping..."
        onSearch={setFilter}
        type="searchGroupings"
      />
      <div className="h-400px">
        <SearchResults
          show={true}
          removeButton
          data={groupings}
          onClickMore={onEndReached}
          headers={TABLE_THEAD_SEARCH}
          onClickItem={handleClickItem}
          isFetchingMore={isFetchingMore}
          onClickRemove={handleClickRemove}
          showLoadMore={pageInfo.hasNextPage}
        />
      </div>
    </TabWrapper>
  );
};

export default TrackerSearchGrouping;
