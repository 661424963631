import FitnessCard from 'components/FitnessCard';

import SportImg from 'assets/icons/sport-blue.x1.svg';

const MyFitness = () => {
  return (
    <FitnessCard
      cardProps={{
        styles: 'h-100',
        innerStyles: 'd-flex flex-column',
      }}
      cardTitleProps={{
        size: 'small',
        icon: SportImg,
        title: 'My Fitness',
        subtitle: 'Exercises and burned calories today.',
      }}
    />
  );
};

export default MyFitness;
