import React from 'react';

interface CounterButtonProps {
  style?: string;
  variant?: 'plus' | 'minus';
  cups?: number;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  value?: number;
  icon?: string;
}

type Button = ButtonProps &
  React.HTMLProps<HTMLAnchorElement> &
  React.HTMLAttributes<HTMLAnchorElement>;
function Button({ icon, ...rest }: ButtonProps) {
  return <a {...rest}> {icon} </a>;
}

const CounterButton = ({
  onClick,
  style = '',
  variant = 'plus',
  cups = 0,
}: CounterButtonProps) => {
  const icon = variant === 'plus' ? '+' : '-';
  return (
    <Button
      value={cups}
      icon={icon}
      engagement-type="tracker usage"
      engagement-name="water"
      engagement-score="2"
      className={`btn btn-icon btn-light btn-hover-primary btn-sm ${style}`}
      onClick={onClick}
    />
  );
};

export default CounterButton;
