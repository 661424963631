import { gql } from '@apollo/client';

export const ASSESSMENT_SCHEMA = `
  id
  name
  type
  title
  order
  objectId
  description
  orderMobile
  options {
    edges {
      node {
        value
        label
        order
      }
    }
  }
`;

export const USER_ASSESSMENT_SCHEMA = `
  id
  value
  objectId
  loggedAt
  createdAt
  assessmentId
`;

export const ASSESSMENTS_SCHEMA = `
  assessments {
    edges {
      node {
        ${ASSESSMENT_SCHEMA}
      }
    }
  }
`;

export const GET_ASSESSMENTS_QUERY = gql`
  query getAssessments {
    ${ASSESSMENTS_SCHEMA}
  }
`;

export const GET_USER_ASSESSMENT_QUERY = gql`
  query getAssessment($userId: String!, $assessmentId: String!) {
    userAssessments(
      where: {
        userId: { equalTo: $userId }
        assessmentId: { equalTo: $assessmentId }
      }
    ) {
      edges {
        node {
          ${USER_ASSESSMENT_SCHEMA}
        }
      }
    }
  }
`;

export const GET_USER_ASSESSMENTS_QUERY = gql`
  query getAssessments($userId: String!) {
    userAssessments(where: { userId: { equalTo: $userId } }) {
      edges {
        node {
          ${USER_ASSESSMENT_SCHEMA}
        }
      }
    }
  }
`;

export const CREATE_USER_ASSESSMENT_MUTATION = gql`
  mutation createUserAssessment($fields: CreateUserAssessmentFieldsInput!) {
    createUserAssessment(input: { fields: $fields }) {
      userAssessment {
        ${USER_ASSESSMENT_SCHEMA}
      }
    }
  }
`;

export const UPDATE_USER_ASSESSMENT_MUTATION = gql`
  mutation updateUserAssessment($id: ID!, $fields: UpdateUserAssessmentFieldsInput!) {
    updateUserAssessment(input: { id: $id, fields: $fields }) {
      userAssessment {
        ${USER_ASSESSMENT_SCHEMA}
      }
    }
  }
`;
