import { gql } from '@apollo/client';

export const LOG_MEASUREMENT_SCHEMA = `
  id
  hips
  neck
  chest
  waist
  userId
  leftArm
  rightArm
  leftCalf
  objectId
  loggedAt
  leftThigh
  rightCalf
  rightThigh
`;

export const LOG_ALL_MEASUREMENTS_SCHEMA = `
  logMeasurements(first: $first, where: { userId: { equalTo: $userId }}) {
    edges {
      node {
        ${LOG_MEASUREMENT_SCHEMA}
      }
    }
  }
`;

export const GET_ALL_LOG_MEASUREMENTS_QUERY = gql`
  query getLogMeasurements($userId: String!, $first: Int!) {
    ${LOG_ALL_MEASUREMENTS_SCHEMA}
  }
`;

export const LOG_MEASUREMENTS_SCHEMA = `
  logMeasurements(where: { userId: { equalTo: $userId }, loggedAt: {
    equalTo: $date
  }}) {
    edges {
      node {
        ${LOG_MEASUREMENT_SCHEMA}
      }
    }
  }
`;

export const LAST_LOG_MEASUREMENTS_SCHEMA = `
  logMeasurements(
    first: 1
    order: [loggedAt_DESC]
    where: { userId: { equalTo: $userId } }
  ) {
    edges {
      node {
        ${LOG_MEASUREMENT_SCHEMA}
      }
    }
  }
`;

export const LOG_MEASUREMENTS_WHERE_SCHEMA = `
  logMeasurements(where: $where) {
    edges {
      node {
        ${LOG_MEASUREMENT_SCHEMA}
      }
    }
  }
`;

export const GET_LOG_MEASUREMENTS_QUERY = gql`
  query getLogMeasurements($userId: String!, $date: Date!) {
    ${LOG_MEASUREMENTS_SCHEMA}
  }
`;

export const GET_LAST_LOG_MEASUREMENTS_QUERY = gql`
  query getLastLogMeasurements($userId: String!) {
    ${LAST_LOG_MEASUREMENTS_SCHEMA}
  }
`;

export const GET_LOG_MEASUREMENTS_WHERE_QUERY = gql`
  query getLogMeasurements($where: LogMeasurementWhereInput!) {
    ${LOG_MEASUREMENTS_WHERE_SCHEMA}
  }
`;

export const CREATE_LOG_MEASUREMENT = gql`
  mutation createLogMeasurement($fields: CreateLogMeasurementFieldsInput!) {
    createLogMeasurement(input: { fields: $fields }) {
      logMeasurement {
        ${LOG_MEASUREMENT_SCHEMA}
      }
    }
  }
`;

export const DELETE_LOG_MEASUREMENT = gql`
  mutation($id: ID!) {
    deleteLogMeasurement(input: { id: $id }) {
      logMeasurement {
        id
      }
    }
  }
`;

export const UPDATE_LOG_MEASUREMENT = gql`
  mutation updateLogMeasurement($id: ID!, $fields: UpdateLogMeasurementFieldsInput!) {
    updateLogMeasurement(input: { id: $id, fields: $fields }) {
      logMeasurement {
        ${LOG_MEASUREMENT_SCHEMA}
      }
    }
  }
`;
