import { useEffect, useMemo, useRef } from 'react';

import { AutoForm } from 'uniforms';
import { AutoField } from 'uniforms-bootstrap4';

import AssessmentFooter from '../AssessmentFooter';

import { SHeight } from 'forms/schemas';

import useForm from 'shared/src/hooks/useForm';
import { useAssessment } from 'shared/src/store';

import { TAny } from 'shared/src/types';
import { cmToFeetInches, feetInchesToCm } from 'shared/src/utils/Conversions';

const AssessmentHeight = () => {
  const formRef = useRef<TAny>(null);
  const { update, next, back, height } = useAssessment();

  const initValues = useMemo(() => {
    const { feet, inches } = cmToFeetInches(+(height ?? 0));
    const _feet = Math.round(feet);
    return {
      feet: _feet < 1 ? 1 : _feet,
      inches: Math.round(inches),
    };
  }, [height]);

  const { schema } = useForm(SHeight, initValues);

  useEffect(() => {
    formRef?.current?.reset();
  }, [initValues]);

  const handleSubmit = async ({
    feet,
    inches,
  }: Partial<{ feet: number; inches: number }>) => {
    const cm = feetInchesToCm(feet!, inches!);
    update('height', cm);
    window.dataLayer.push({
      event: 'form_progress',
      form_name: 'signup-form ',
      step: '5',
      engagement_type: 'sign up',
      engagement_name: 'account',
    });
    next();
  };

  const handleNext = () => {
    formRef?.current?.submit();
  };

  return (
    <div>
      <AutoForm ref={formRef} schema={schema} onSubmit={handleSubmit}>
        <div className="d-flex form--primary form--centered form--size-jumbo form--warning-off">
          <div className="max-w-140px mr-8">
            <AutoField name="feet" />
          </div>
          <div className="max-w-180px">
            <AutoField name="inches" />
          </div>
        </div>
      </AutoForm>
      <AssessmentFooter onClickBack={back} onClickNext={handleNext} />
    </div>
  );
};

export default AssessmentHeight;
