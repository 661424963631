import { gql } from '@apollo/client';

export const RECIPE_STYLE_SCHEMA = `
  id
  name
  objectId
`;

export const RECIPE_STYLES_SCHEMA = `
  recipeStyles {
    edges {
      node {
        ${RECIPE_STYLE_SCHEMA}
      }
    }
  }
`;

export const GET_RECIPE_STYLES_QUERY = gql`
  query getRecipeStyles {
    ${RECIPE_STYLES_SCHEMA}
  }
`;
