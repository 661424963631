import { layoutGenerator } from 'react-break';

import { ITrackedFood } from 'shared/src/types';

import { MEALS_HEADERS } from 'constants/index';

import EditImg from 'assets/icons/edit.svg';
import TrashImg from 'assets/icons/trashcan.svg';

const layout = layoutGenerator({
  mobile: 0,
  phablet: 550,
  tablet: 768,
  desktop: 992,
});

const OnMobile = layout.isAtMost('phablet');
const OnAtLeastTablet = layout.isAtLeast('tablet');

interface TrackerMealsItemProps {
  index?: number;
  data: ITrackedFood;
  onEdit: (id: string) => void;
  onRemove: (id: string) => void;
}

const TrackerMealsItem = ({
  onEdit,
  onRemove,
  index = 0,
  data: {
    id,
    foodName,
    brandName,
    verboseName,
    servingSize,
    servingUnit,
    ...data
  },
}: TrackerMealsItemProps) => (
  <>
    <OnMobile>
      <div className={`p-4 ${index % 2 ? '' : 'bg-light'}`}>
        <span>
          {verboseName || foodName}{' '}
          <small className="text-muted font-italic">{brandName}</small>
        </span>
        <div className="d-flex justify-content-between">
          <div
            className="d-flex justify-content-between flex-column"
            style={{ flex: 1 }}>
            {MEALS_HEADERS.map(({ value }) => (
              <span key={`track-meal-header-${value}`} className="text-left">
                <strong>{`${value}: `}</strong>
                {value === 'portion'
                  ? `${servingSize} ${servingUnit ?? 'servings'}`
                  : ((data?.[value as keyof typeof data] ??
                      0) as number).toFixed(2)}
              </span>
            ))}
          </div>

          <div>
            <a className="w-10 text-right mr-2" onClick={() => onEdit(id)}>
              <img src={EditImg} />
            </a>

            <a className="w-10 text-right" onClick={() => onRemove(id)}>
              <img src={TrashImg} />
            </a>
          </div>
        </div>
      </div>
    </OnMobile>
    <OnAtLeastTablet>
      <div className={`p-4 grid-40 ${index % 2 ? '' : 'bg-light'}`}>
        <span>
          {verboseName || foodName}{' '}
          <small className="text-muted font-italic">{brandName}</small>
        </span>
        <div className="d-flex justify-content-between">
          {MEALS_HEADERS.map(({ value }) => (
            <span
              key={`track-meal-header-${value}`}
              className="w-20 text-center">
              {value === 'portion'
                ? `${servingSize} ${servingUnit ?? 'servings'}`
                : ((data?.[value as keyof typeof data] ?? 0) as number).toFixed(
                    2
                  )}
            </span>
          ))}

          <a className="w-10 text-right" onClick={() => onEdit(id)}>
            <img src={EditImg} />
          </a>

          <a className="w-10 text-right" onClick={() => onRemove(id)}>
            <img src={TrashImg} />
          </a>
        </div>
      </div>
    </OnAtLeastTablet>
  </>
);

export default TrackerMealsItem;
