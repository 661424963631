import Card from 'components/ui/Card';
import CardTitle from 'components/ui/CardTitle';

import DailyNutritionValues from 'components/DailyNutritionValues';

import folkIcon from 'assets/icons/folk.svg';

const MyFood = () => {
  return (
    <Card>
      <CardTitle
        title="My food"
        icon={folkIcon}
        subtitle="Meals, net carbs, calories, fat, protein and fiber."
        button={{
          link: '/tracker',
          text: 'Track Food',
        }}
      />
      <DailyNutritionValues />
    </Card>
  );
};

export default MyFood;
