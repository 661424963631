import { ReactNode } from 'react';
import { AutoFields, AutoField } from 'uniforms-bootstrap4';
import { useField } from 'uniforms';
import Button from 'shared/src/components/web/Button';
//import DeleteConfirmation from 'components/DeleteConfirmation';
import Submit from 'forms/fields/Submit';
const InlineField = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="inline-field list-group list-group-custom">{children}</div>
  );
};

interface IAsideMenuProps {
  submit?: boolean;
  setAddToTracker?: React.Dispatch<React.SetStateAction<boolean>>;
  addToTracker?: boolean;
  recipeId?: string;
  isLoading?: boolean;
  onDelete?: () => void;
  handleSubmit?: any;
}

const AsideMenu = ({
  submit,
  setAddToTracker,
  addToTracker,
  onDelete,
  recipeId,
  isLoading,
  handleSubmit,
}: IAsideMenuProps) => {
  const [{ value: servings }] = useField('servings', {});
  const handleOnChange = () => {
    setAddToTracker?.(!addToTracker);
  };
  return (
    <div className="col">
      <div className="aside-menu">
        <div className="aside-menu">
          <AutoField name="servings" />
          <label className="">Nutrition information is per serving</label>
          <AutoField name="servingTable" servingsInfo={servings} />
          <div className="mt-5">
            {submit && <Submit working={isLoading} text="Save recipe" />}
          </div>
          <div className="mt-5">
            <input type="checkbox" onChange={handleOnChange} /> Add To Tracker
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsideMenu;
