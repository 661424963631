import { TAny } from 'shared/src/types';

import TrashImg from 'assets/icons/trashcan.svg';

interface TableRowProps<T> {
  data: T;
  index: number;
  onClickItem?: (item: T) => void;
  onClickDelete?: (index: number) => void;
}

const TableRow = <T,>({
  data,
  index,
  onClickItem,
  onClickDelete,
}: TableRowProps<T>) => (
  <tr className={`${index % 2 ? '' : 'bg-light'}`}>
    {Object.keys(data).map((key, index) => {
      let textClass = 'text-left';
      if (index > 0) {
        textClass =
          index === Object.keys(data).length - 1 ? 'text-right' : 'text-center';
      }
      return (
        <td
          key={`table-row-${key}`}
          onClick={() => onClickItem && onClickItem(data)}>
          <div
            className={
              (data as TAny).smallEntry && key === 'entry'
                ? 'text-right h5'
                : textClass
            }>
            {data[key as keyof T]}
          </div>
        </td>
      );
    })}

    <td>
      <a
        className="d-flex justify-content-center"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickDelete && onClickDelete(index);
        }}>
        <img src={TrashImg} />
      </a>
    </td>
  </tr>
);

export default TableRow;
