import RecipeDetailDetails from 'components/recipeDetail/tabs/RecipeDetailDetails';
import RecipeDetailDirections from 'components/recipeDetail/tabs/RecipeDetailDirections';
import RecipeDetailIngredients from 'components/recipeDetail/tabs/RecipeDetailIngredients';

export default [
  {
    key: 'details',
    component: RecipeDetailDetails,
  },
  {
    key: 'ingredients',
    component: RecipeDetailIngredients,
  },
  {
    key: 'directions',
    component: RecipeDetailDirections,
  },
];
