import React, { useEffect, useMemo, useState } from 'react';

import get from 'lodash/get';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import globalHook, { Store } from 'use-global-hook';
import { useQuery, useApolloClient } from '@apollo/client';

import UNITS_OF_MASS from '../../constants/unitsOfMass';
import UNITS_OF_LENGTH from '../../constants/unitsOfLength';
import { GET_ASSESSMENTS_QUERY } from '../../graphql/assessment';
import { kgToLb, lbToKg, cmToFeetInches } from '../../utils/Conversions';
import {
  TAny,
  IObject,
  IAssessment,
  TGraphQLList,
  ISignupProps,
  TGraphQLResponse,
} from '../../types';
import useAuth from '../../hooks/useAuth';
import { UPDATE_MUTATION } from '../../graphql/user';
import { GET_PROGRAMS_QUERY } from '../../graphql/program';
import pick from 'lodash/pick';

type TState = {
  age?: string;
  step?: number;
  weight?: string;
  gender?: string;
  height?: string;
  finished?: false;
  diabetes?: string;
  pregnant?: string;
  userData?: IObject;
  weightUnit?: string;
  heightUnit?: string;
  desiredWeightLost?: string;
  programPreference?: string;
  waistCircumference?: string;
  desiredWeightLostUnit?: string;
};

export type TAssessmentsKeys =
  | 'age'
  | 'step'
  | 'weight'
  | 'gender'
  | 'height'
  | 'diabetes'
  | 'pregnant'
  | 'userData'
  | 'finished'
  | 'weightUnit'
  | 'heightUnit'
  | 'desiredWeightLost'
  | 'programPreference'
  | 'waistCircumference'
  | 'desiredWeightLostUnit';

type TAssociatedActions = {
  reset: () => void;
  update: (
    key: TAssessmentsKeys,
    value: string | number | boolean | IObject
  ) => void;
};

export interface IAssessmentOption {
  label: string;
  value: string;
  order: number;
}

export interface IAssessmentComponentProps {
  initValue: string;
  finished?: boolean;
  initValueUnit?: string;
  options?: IAssessmentOption[];
  setInvalid?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: (key: TAssessmentsKeys, value: string | IObject) => void;
}

export interface IQuestion {
  name: string;
  title: string;
  subtitle: string;
  options: IAssessmentOption[];
  initValue?: string | number | IObject;
  component?: (props: TAny) => JSX.Element;
  initValueUnit?: string | number | IObject;
  highlight: {
    style: string;
    delimiter: string;
  };
}

const updateState = (
  store: Store<TState, TAssociatedActions>,
  key: TAssessmentsKeys,
  value: string | IObject
) => {
  store.setState({ ...store.state, ...{ [key]: value } });
};

const resetState = (store: Store<TState, TAssociatedActions>) => {
  store.setState(initialState);
};

const initialState: TState = {
  gender: '1',
  diabetes: '6',
  pregnant: '4',
  age: undefined,
  step: undefined,
  weight: undefined,
  height: undefined,
  userData: undefined,
  programPreference: '7',
  desiredWeightLost: undefined,
  waistCircumference: undefined,
  weightUnit: UNITS_OF_MASS[0].value,
  heightUnit: UNITS_OF_LENGTH[0].value,
  desiredWeightLostUnit: UNITS_OF_MASS[0].value,
};

const globalActions = {
  reset: resetState,
  update: updateState,
};

const useGlobal = globalHook<TState, TAssociatedActions>(
  React,
  initialState,
  globalActions
);

type TComponents = {
  [key: string]: (props: IQuestion) => JSX.Element;
};

type TQueryAssesments = TGraphQLResponse<
  'assessments',
  TGraphQLList<IAssessment>
>;

type TQueryPrograms = TGraphQLResponse<
  'programs',
  TGraphQLList<{
    id: string;
    name: string;
  }>
>;

interface IuseAssessmentProps {
  components?: TComponents;
  onComplete?: () => void;
}

const useAssessment = ({
  components,
  onComplete,
}: IuseAssessmentProps = {}) => {
  const [isSaving, setIsSaving] = useState(false);

  const [state, actions] = useGlobal();
  const { data } = useQuery<TQueryAssesments>(GET_ASSESSMENTS_QUERY);

  const client = useApolloClient();
  const { login, signup, forceIndicators, setUser } = useAuth();

  const assessments = data?.assessments?.edges ?? [];

  useEffect(() => {
    if (state.finished) {
      actions.update('finished', false);
      onComplete?.();
    }
  }, [state.finished]);

  const plan = useMemo(() => {
    const height = Number(state.height || 0) / 100;

    const weight =
      state.weightUnit === 'kg'
        ? Number(state.weight)
        : lbToKg(Number(state.weight));

    const isPregnant = state.pregnant === '3';
    const hasDiabetes = state.diabetes === '5';

    const bmi = weight / (height * height);
    const dontKnowWaist = Number(state.waistCircumference) === 0;

    // If the user is diebetic always recommend Atkins 20
    let recommendedPlan;
    let qualifyForAtkins40 = false;
    // Calculate user's BMI

    /*const heightInInches = Number(feet) * 12 + Number(inches);
    let bmi = (weight / Math.pow(heightInInches, 2)) * 703;
    bmi = Math.round(bmi * 10) / 10;*/

    // If no to diabetic / pre-diabetic and pregnant / nursing – waist size will determine program recommendation
    if (state.gender === '2') {
      if (dontKnowWaist) {
        if (Number(bmi) >= 30) {
          qualifyForAtkins40 = false;
        } else {
          if (hasDiabetes) {
            qualifyForAtkins40 = false;
          } else {
            qualifyForAtkins40 = true;
          }
        }
      } else {
        if (Number(state.waistCircumference) >= 40) {
          qualifyForAtkins40 = false;
        } else {
          if (hasDiabetes) {
            qualifyForAtkins40 = false;
          } else {
            qualifyForAtkins40 = true;
          }
        }
      }
    } else {
      if (dontKnowWaist || Number(state.waistCircumference) === 0) {
        if (Number(bmi) >= 30) {
          qualifyForAtkins40 = false;
        } else {
          if (hasDiabetes) {
            qualifyForAtkins40 = false;
          } else {
            qualifyForAtkins40 = true;
          }
        }
      } else {
        if (Number(state.waistCircumference) >= 35) {
          qualifyForAtkins40 = false;
        } else {
          if (hasDiabetes) {
            qualifyForAtkins40 = false;
          } else {
            qualifyForAtkins40 = true;
          }
        }
      }
    }

    if (state.programPreference === '7') {
      // User has chosen Atkins 40, if they qualify based on the prior questions, recommend this plan
      // If they do not qualify, recommend the Atkins 20 with a disclaimer
      if (qualifyForAtkins40) {
        recommendedPlan = 'atkins 40';
      } else {
        recommendedPlan = 'atkins 20';
      }
    } else {
      // User has chosen Atkins 20
      // If they have less than 20lbs to loose and they still qualify for Atkins 40
      // then recommend the Atkins 40 plan.
      if (Number(state.desiredWeightLost) <= 20 && qualifyForAtkins40) {
        recommendedPlan = 'atkins 40';
      } else {
        // User has chosen Atkins 20
        // The only type of user that doesn't qualify at this point
        // is a pregnant user and they have already been redirected
        recommendedPlan = 'atkins 20';
      }
    }

    if (isPregnant) {
      recommendedPlan = 'atkins 100';
    }

    return recommendedPlan;
  }, [state]);

  const getAssessmentData = (userId: string, userObjectId: string) => {
    const assessmentsRef = [
      { name: 'age', value: state.age },
      { name: 'height', value: state.height },
      { name: 'weight', value: state.weight },
      { name: 'gender', value: state.gender },
      { name: 'diabetes', value: state.diabetes },
      { name: 'pregnant', value: state.pregnant },
      { name: 'desiredWeightLost', value: state.desiredWeightLost },
      { name: 'programPreference', value: state.programPreference },
      { name: 'waistCircumference', value: state.waistCircumference },
    ];

    const toSave = [];
    for (const a of assessmentsRef) {
      const assessment = assessments.filter(
        ({ node: { name } }: { node: { name: string } }) => name === a.name
      )[0];

      let value = a.value || 0;
      const unit = get(state, `${a.name}Unit`);
      if (unit) {
        if (unit === 'kg') {
          value = kgToLb(+value).toString();
        }
      }

      if (a.name === 'height') {
        const { feet, inches } = cmToFeetInches(+value);
        value = feet * 12 + inches;
      }

      toSave.push({
        value: String(value),
        userId: userObjectId,
        user: { link: userId },
        assessmentId: assessment.node.objectId,
        assessment: { link: assessment.node.id },
      });
    }

    return {
      assessments: { createAndAdd: toSave },
    };
  };

  const questions = useMemo(() => {
    const { gender, pregnant } = state;
    const massessments = assessments?.map(({ node }) => node);
    let list = orderBy(
      massessments.filter(({ order }: IAssessment) => !!order),
      ['order'],
      ['asc']
    );

    if (gender !== '1') {
      list = list.filter(({ name }) => name !== 'pregnant');
      if (pregnant !== '4') {
        actions.update('pregnant', '4');
      }
    }

    return list.map(({ name, title, options, description }) => {
      const moptions = options?.edges.map(({ node }) => node) ?? [];
      const optionsList = orderBy(
        moptions,
        ['order'],
        ['asc']
      ) as IAssessmentOption[];

      return {
        name,
        options: optionsList,
        subtitle: description,
        component: get(components, name),
        title: title.replace(name, `|${name}|`),
        initValue: state[name as keyof typeof state],
        initValueUnit: state[`${name}Unit` as keyof typeof state],
        highlight: {
          delimiter: '|',
          style: 'text-primary-500',
        },
      };
    });
  }, [assessments, state]);

  const save = async () => {
    setIsSaving(true);

    try {
      const { id, objectId } = await signup(
        omit(state.userData, 'repeatPassword') as ISignupProps
      );

      await login(
        pick(state.userData, ['username', 'password']) as ISignupProps,
        false
      );

      const {
        data: {
          programs: { edges },
        },
      } = await client.query<TQueryPrograms>({
        query: GET_PROGRAMS_QUERY,
      });

      const programs = edges.map(({ node }) => node) ?? [];
      let selectedProgram = programs.filter(
        ({ name }) => name.toLowerCase().indexOf(plan) !== -1
      )[0];

      if (plan === 'atkins 20') {
        selectedProgram = programs.filter(
          ({ name }) => name.toLowerCase().indexOf('20g') !== -1
        )[0];
      }

      const variables = {
        id,
        program: { link: selectedProgram.id },
        ...getAssessmentData(id, objectId),
      };

      const {
        data: {
          updateUser: { user },
        },
      } = await client.mutate({
        mutation: UPDATE_MUTATION,
        variables,
      });

      setIsSaving(false);
      setUser(user);
      actions.reset();
      forceIndicators();
    } catch (error) {
      const { message } = error as Error;
      setIsSaving(false);
      throw new Error(message);
    }
  };

  const step = state.step ?? 0;
  const currentQuestion = questions?.[step - 1];

  const back = () => {
    step > 0 && actions.update('step', step - 1);
    actions.update('finished', false);
  };

  const next = () => {
    let finished = true;
    if (step < questions.length) {
      finished = false;
      actions.update('step', step + 1);
    }

    actions.update('finished', finished);
  };

  return {
    ...state,
    ...actions,

    plan,
    questions,
    assessments,
    currentQuestion,
    getAssessmentData,

    isSaving,
    back,
    next,
    step,
    save,
  };
};

export default useAssessment;
