import React, { useMemo, useState } from 'react';

import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { useQuery } from '@apollo/client';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import globalHook, { Store } from 'use-global-hook';

import useFavorites from './useFavorites';

import useAuth from '../../hooks/useAuth';

import {
  TAny,
  IObject,
  TGraphQLList,
  ITrackedFood,
  TGraphQLResponse,
} from '../../types';
import { getFraction } from '../../utils/Number';
import { updateCache } from '../../utils/GraphqlHelper';
import {
  CREATE_USER_FOOD_TRACKED,
  UPDATE_USER_FOOD_TRACKED,
  DELETE_USER_FOOD_TRACKED,
  GET_USER_FOOD_TRACKEDS_QUERY,
  GET_USER_FOOD_TRACKEDS_QUERY_DAY,
  GET_USER_FOOD_TRACKEDS_WHERE_QUERY,
  GET_USER_FOOD_TRACKEDS_WHERE_QUERY_ORDERED,
} from '../../graphql/userFoodTracked';
import useDate from '../../hooks/useDate';
import useApolloCrud from '../../hooks/useApolloCrudv2';
import useReportVariables from '../../hooks/useReportVariables';

import { MEALS_PLAIN } from '../../constants/meals';
import QUERY_RESULT from '../../constants/queryResult';
import NUTRITIONAL_VALUES from '../../constants/nutritionalValues';

export type TFood = {
  date: Date;
  fat: number;
  name: string;
  meal: string;
  fiber: number;
  foodId: string;
  protein: number;
  netCarbs: number;
  objectId: string;
  recipeId: string;
  calories: number;
  brandName: string;
  verboseName: string;
  servingSize: number;
  servingUnit: string;
  userRecipeId: string;
  isAtkinsRecipe: string;
  originalServingSize: number;
  isUserDefinedRecipe: string;
};

export type TFoodState = {
  food?: TFood;
};

export type TFoodAssociatedActions = {
  setFood: (value: TFood | null) => void;
};

const setFood = (
  store: Store<TFoodState, TFoodAssociatedActions>,
  food: TFood
) => {
  store.setState({ ...store.state, food });
};

const initialState: TFoodState = {
  food: undefined,
};

const globalActions = {
  setFood,
};

const useGlobal = globalHook<TFoodState, TFoodAssociatedActions>(
  React,
  initialState,
  globalActions
);

const normalizeFood = (date: Date, meal: string, food: IObject) => ({
  meal,
  date,
  id: food.id,
  foodId: food.foodId,
  name: food.name ?? '',
  fiber: food.fiber ?? 0,
  isRecipe: food.isRecipe,
  recipeId: food.recipeId,
  protein: food.protein ?? 0,
  netCarbs: food.netCarbs ?? 0,
  calories: food.calories ?? 0,
  objectId: food.objectId ?? '',
  userFoodId: food.userFoodId,
  brandName: food.brandName,
  verboseName: food.verboseName,
  userRecipeId: food.userRecipeId,
  servingSize: food.servingSize ?? 1,
  fat: food.fat ?? food.totalFat ?? 0,
  servingUnit: food.servingUnit || 'serving(s)',
  originalServingSize: food.originalServingSize,
  isUserDefinedRecipe: food.userRecipeId ? 'True' : 'False',
  isAtkinsRecipe: food.isAtkinsAcceptable ? 'True' : 'False',
  servingConversions: food.servingConversions,
  ...(food.food && {
    servingConversions: food.food.servingConversions,
  }),
});

type TQuery = TGraphQLResponse<'userFoodFavorites', TGraphQLList<ITrackedFood>>;
type TQueryTrakced = TGraphQLResponse<
  'userFoodTrackeds',
  TGraphQLList<ITrackedFood>
>;

const today = startOfDay(new Date());
const todayEnd = endOfDay(new Date());
const useLogFood = (preloadQuery = false) => {
  const { user } = useAuth();
  const [state, actions] = useGlobal();
  const { date: currentDate } = useDate();
  const reportVariables = useReportVariables();

  const [queryVariables, setQueryVariables] = useState({
    userId: user.objectId,
    date: today.toISOString(),
  });

  const [queryVariablesDay, setQueryVariablesDay] = useState({
    userId: user.objectId,
    dateStart: today.toISOString(),
    dateEnd: todayEnd.toISOString(),
  });

  const {
    mutations: {
      create: { call: saveFavorite },
    },
  } = useFavorites();

  const { query, mutations } = useApolloCrud<TQuery, ITrackedFood>({
    skipFirstQuery: !preloadQuery,
    queryNode: GET_USER_FOOD_TRACKEDS_QUERY_DAY,
    createMutationNode: CREATE_USER_FOOD_TRACKED,
    updateMutationNode: UPDATE_USER_FOOD_TRACKED,
    removeMutationNode: DELETE_USER_FOOD_TRACKED,
    pointerName: 'userFoodTrackeds',
    queryOptions: {
      variables: queryVariablesDay,
      nextFetchPolicy: 'cache-and-network',
      fetchPolicy: 'cache-and-network',
    },
  });

  const yearHistorical = useQuery<TQueryTrakced>(
    GET_USER_FOOD_TRACKEDS_WHERE_QUERY,
    {
      variables: reportVariables,
    }
  );

  const fullHistory = useQuery<TQueryTrakced>(
    GET_USER_FOOD_TRACKEDS_WHERE_QUERY_ORDERED,
    {
      variables: reportVariables,
    }
  );

  const fullHistoryData = fullHistory?.data?.userFoodTrackeds?.edges?.map(
    ({ node }) => node
  );

  const yearHistoricalData = yearHistorical?.data?.userFoodTrackeds?.edges?.map(
    ({ node }) => node
  );

  const [food, servingLabel]: TAny = useMemo(() => {
    const food: TAny = state.food;
    if (!food) {
      return [{}, null];
    }

    const originalServingSize = Number(
      food.originalServingSize || food.servingSize || 1
    );
    const { denominator, numerator } = getFraction(originalServingSize);
    let servingLabel: string | number | null =
      denominator === 1 ? originalServingSize : numerator + '/' + denominator;

    if (food.id) {
      if (!food.originalServingSize) {
        servingLabel = null;
      }

      return [
        {
          ...food,
          fat: food.fat || 0,
          fiber: food.fiber || 0,
          protein: food.protein || 0,
          netCarbs: food.netCarbs || 0,
          calories: food.calories || 0,
          servingSize: +(food.servingSize || 1),
          servingUnit: food.servingUnit || 'serving(s)',
        },
        servingLabel,
      ];
    }
    return [food, servingLabel];
  }, [state.food]);

  const setFood = ({
    date = currentDate,
    meal = MEALS_PLAIN[0],
    food,
  }: {
    date?: Date;
    meal?: string;
    food: IObject;
  }) => {
    const norma = normalizeFood(date, meal, food);
    actions.setFood(norma);
  };

  const savePreCalculated = async (
    date: Date,
    meal: string,
    data: TAny,
    favorite = false
  ) => {
    data = normalizeFood(date, meal, data);
    const mustLink = !data.id && data.foodId;
    const newLog = {
      meal,
      fat: data.fat ?? 0,
      foodId: data.foodId,
      foodName: data.name,
      userId: user.objectId,
      fiber: data.fiber ?? 0,
      recipeId: data.recipeId,
      brandName: data.brandName,
      protein: data.protein ?? 0,
      loggedAt: date.toISOString(),
      netCarbs: data.netCarbs ?? 0,
      calories: data.calories ?? 0,
      verboseName: data.verboseName,
      servingSize: +(data.servingSize || 1),
      servingUnit: data.servingUnit || 'serving(s)',
      userFoodId: data.userFoodId,
      userRecipeId: data.userRecipeId,
      isAtkinsRecipe: data.isAtkinsAcceptable ?? 'False',
      isUserDefinedRecipe: data.isUserDefinedRecipe ?? 'False',
      originalServingSize: +(data.originalServingSize
        ? data.originalServingSize
        : data.servingSize || 1),
      ...(mustLink && {
        food: {
          link: data.foodId,
        },
      }),
    };

    if (!favorite) {
      return beforeSave(data, newLog, date);
    }
    const { data: savedData } = await beforeSave(data, newLog, date);

    const actionName = Object.keys(savedData)[0];
    const userFoodTracked = savedData[actionName].userFoodTracked;

    return saveFavorite({
      variables: {
        fields: {
          ...pick(userFoodTracked, [
            ...NUTRITIONAL_VALUES.SHORT_SERVING,
            'name',
            'foodId',
            'recipeId',
            'userFoodId',
            'userRecipeId',
          ]),
          userId: user.objectId,
          ...(userFoodTracked.foodId && {
            food: {
              link: userFoodTracked.foodId,
            },
          }),
          ...(userFoodTracked.foodName && {
            name: userFoodTracked.foodName,
          }),
          ...(userFoodTracked.brandName && {
            brandName: userFoodTracked.brandName,
          }),
          ...(userFoodTracked.verboseName && {
            verboseName: userFoodTracked.verboseName,
          }),
        },
      },
    });
  };

  const save = (date: Date, meal: string, servings: number, dfood?: TFood) => {
    let cachedFood = food;

    if (dfood) {
      cachedFood = normalizeFood(date, meal, dfood);
    }

    if (!cachedFood) {
      throw new Error('food not selected');
    }

    const mustLink = !cachedFood.id && cachedFood.foodId;
    const newLog = {
      meal,
      userId: user.objectId,
      servingSize: servings || 1,
      foodId: cachedFood.foodId,
      foodName: cachedFood.name,
      brandName: cachedFood.brandName,
      verboseName: cachedFood.verboseName,
      loggedAt: date.toISOString(),
      recipeId: cachedFood.recipeId,
      fat: cachedFood.fat ?? 0,
      userRecipeId: cachedFood.userRecipeId,
      fiber: cachedFood.fiber ?? 0,
      protein: cachedFood.protein ?? 0,
      netCarbs: cachedFood.netCarbs ?? 0,
      calories: cachedFood.calories ?? 0,
      servingUnit: cachedFood.servingUnit || 'serving(s)',
      isAtkinsRecipe: cachedFood.isAtkinsAcceptable ?? 'False',
      isUserDefinedRecipe: cachedFood.isUserDefinedRecipe ?? 'False',
      originalServingSize: +(cachedFood.originalServingSize
        ? cachedFood.originalServingSize
        : cachedFood.servingSize || 1),
      ...(mustLink && {
        food: {
          link: cachedFood.foodId,
        },
      }),
    };

    return beforeSave(cachedFood, newLog, date);
  };

  const beforeSave = (food: TAny, newLog: TAny, date: Date) => {
    const action = food.id ? mutations.update.call : mutations.create.call;

    const variables = {
      id: food.id,
      fields: newLog,
    };

    return action({
      variables,
      update: (query, document) => {
        updateCache(GET_USER_FOOD_TRACKEDS_QUERY, {
          userId: user.objectId,
          date: date.toISOString(),
        })(query, document);
        updateCache(
          GET_USER_FOOD_TRACKEDS_WHERE_QUERY_ORDERED,
          reportVariables
        )(query, document);
        if (food.id && food.date.toISOString() !== date.toISOString()) {
          const toDelete = {
            data: {
              deleteUserFoodTracked: {
                userFoodTracked: {
                  id: food.id,
                },
              },
            },
          };
          updateCache(GET_USER_FOOD_TRACKEDS_QUERY, {
            userId: user.objectId,
            date: food.date.toISOString(),
          })(query, toDelete);
        }
      },
      optimisticResponse: {
        [food.id ? 'updateUserFoodTracked' : 'createUserFoodTracked']: {
          userFoodTracked: {
            food: null,
            foodId: null,
            recipeId: null,
            userRecipeId: null,
            ...omit(newLog, 'recipeId', 'foodId', 'userRecipeId', 'food'),
            id: food.id ? food.id : 'trackedFoodOptimistic-' + Math.random(),
          },
        },
      },
    });
  };

  const remove = (id: string) => {
    mutations.remove.call({
      variables: {
        id,
      },
      update: (query, document) => {
        updateCache(GET_USER_FOOD_TRACKEDS_QUERY, {
          userId: user.objectId,
          date: currentDate.toISOString(),
        })(query, document);
        updateCache(
          GET_USER_FOOD_TRACKEDS_WHERE_QUERY_ORDERED,
          reportVariables
        )(query, document);
        updateCache(GET_USER_FOOD_TRACKEDS_QUERY_DAY, {
          userId: user.objectId,
          dateStart: startOfDay(currentDate),
          dateEnd: endOfDay(currentDate),
        })(query, document);
      },
    });
  };

  return {
    save,
    query,
    remove,
    mutations,
    savePreCalculated,
    setQueryVariables,
    setCachedFood: setFood,
    cachedData: { food, servingLabel },
    yearHistorical: {
      ...pick(yearHistorical, QUERY_RESULT),
      _data: yearHistorical?.data,
      data: yearHistoricalData,
    },
    fullHistory: {
      ...pick(fullHistory, QUERY_RESULT),
      _data: fullHistory?.data,
      data: fullHistoryData,
    },
  };
};

export default useLogFood;
