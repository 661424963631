interface IStorage {
  getItem(key: string): string | null | Promise<string | null>;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

let _localStorage: IStorage;
try {
  _localStorage = localStorage || window.localStorage;
} catch {
  import('@react-native-async-storage/async-storage').then((library) => {
    _localStorage = library.default;
  });
}

const getItem = async (key: string) => {
  if (!_localStorage) return;
  return await _localStorage.getItem(key);
};

const setItem = async (key: string, value: string) => {
  if (!_localStorage) return;
  return await _localStorage.setItem(key, value);
};

const removeItem = async (key: string) => {
  if (!_localStorage) return;
  return await _localStorage.removeItem(key);
};

const Storage = {
  getItem,
  setItem,
  removeItem,
};

export default Storage;
