import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

import TrackerNavbar from 'components/tracker/TrackerNavbar';

import TrackerFoods from 'views/app/tracker/TrackerFoods';
import TrackerWater from 'views/app/tracker/TrackerWater';
import TrackerWeight from 'views/app/tracker/TrackerWeight';
import TrackerFitness from 'views/app/tracker/TrackerFitness';
import TrackerMeasurements from 'views/app/tracker/TrackerMeasurements';

const TrackerRouter = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <div className="row">
        <div className="col-auto">
          <TrackerNavbar />
        </div>
      </div>

      <Switch>
        <Route exact={true} path={`${path}/food`} component={TrackerFoods} />
        <Route exact={true} path={`${path}/water`} component={TrackerWater} />
        <Route exact={true} path={`${path}/weight`} component={TrackerWeight} />
        <Route
          exact={true}
          path={`${path}/measurements`}
          component={TrackerMeasurements}
        />

        <Route
          exact={true}
          path={`${path}/fitness`}
          component={TrackerFitness}
        />
        <Redirect to={`${path}/food`} />
      </Switch>
    </>
  );
};

export default TrackerRouter;
