import { gql } from '@apollo/client';

export const RECIPE_SPECIALITY_SCHEMA = `
  id
  name
`;

export const RECIPE_SPECIALITIES_SCHEMA = `
  recipeSpecialities {
    edges {
      node {
        ${RECIPE_SPECIALITY_SCHEMA}
      }
    }
  }
`;

export const GET_RECIPE_SPECIALITIES_QUERY = gql`
  query getRecipeSpecialities {
    ${RECIPE_SPECIALITIES_SCHEMA}
  }
`;
