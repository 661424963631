import Router from 'routers/Router';
import { ToastContainer } from 'react-toastify';
import ApolloProvider from 'shared/src/components/AuthorizedApolloProvider';

import 'simplebar';
import 'theme/index.scss';
import 'simplebar/dist/simplebar.css';

const App = () => (
  <ApolloProvider>
    <Router />
    <ToastContainer />
  </ApolloProvider>
);

export default App;
