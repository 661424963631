import { useEffect, useState } from 'react';

import numeral from 'numeral';
import { toast } from 'react-toastify';

import CounterButton from 'components/ui/CounterButton';

import useDate from 'shared/src/hooks/useDate';
import { useLogWater } from 'shared/src/store';

import CupsOfWater from 'assets/cups-of-water.png';

interface WaterCupCounterProps {
  style?: string;
  variant?: 'regular' | 'light';
}

const WaterCupCounter = ({
  style = '',
  variant = 'regular',
}: WaterCupCounterProps) => {
  const [cups, setCups] = useState(0);

  const { date } = useDate();
  const {
    save,
    mutations: { get },
    query: { data, loading },
  } = useLogWater(true);

  useEffect(() => {
    get.call({
      date,
    });
  }, [date]);

  const bgColor = variant === 'light' ? 'bg-white' : '';
  const formatedCups = numeral(cups).format('00');

  const logWater = data?.[0] ?? {
    cups: 0,
  };

  useEffect(() => {
    setCups(+logWater.cups);
  }, [logWater.cups]);

  const handlePressCups = (n: number) => () => {
    if (loading) return;

    const nextValue = cups + n;
    if (nextValue < 0 || nextValue > 99) {
      return;
    }
    try {
      save(logWater, nextValue, date);
      window.dataLayer.push({
        event: 'track',
        value: nextValue,
        engagement_type: 'tracker usage',
        engagement_name: 'water',
        engagement_score: '2',
      });
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <div className={`d-flex align-items-center ${style}`}>
      <CounterButton
        variant="minus"
        onClick={handlePressCups(-1)}
        style={bgColor}
        cups={cups}
      />
      <div className="border border-ligth rounded-circle text-primary text-center mx-8">
        <div
          className={`d-flex flex-column justify-content-center w-145px h-145px rounded-circle bg-white`}
          style={{
            backgroundImage: `url(${CupsOfWater})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
          }}>
          <div className="font-weight-boldest line-height-sm text-48px">
            {formatedCups}
          </div>
          <span>cups of water</span>
        </div>
      </div>
      <CounterButton onClick={handlePressCups(1)} style={bgColor} cups={cups} />
    </div>
  );
};

export default WaterCupCounter;
