import React from 'react';

import { Spinner } from 'react-bootstrap';

interface ListStatusProps {
  loading?: boolean;
  children: React.ReactNode;
}

const ListStatus = ({ loading, children }: ListStatusProps) => {
  const count = React.Children.count(children);
  if (loading) {
    return (
      <div className="mt-40 d-flex justify-content-center">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  if (!count) {
    return (
      <div className="text-center mt-40 font-size-xxs text-primary">
        <strong>No results found</strong>
      </div>
    );
  }

  return <>{children}</>;
};

export default ListStatus;
