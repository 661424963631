import { gql } from '@apollo/client';

import { PROGRAM_SCHEMA } from './program';

export const USER_SCHEMA = `
  id
  dob
  city
  email
  state
  address
  zipcode
  country
  objectId
  lastName
  fullName
  username
  firstName
  createdAt
  updatedAt
  avatar {
    name
    url
  }
  program {
    ${PROGRAM_SCHEMA}
  }
`;

export const IS_LOGGED_IN_QUERY = gql`
  query viewer {
    viewer {
      sessionToken
      user {
        ${USER_SCHEMA}
      }
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        objectId
      }
    }
  }
`;

export const UPDATE_MUTATION = gql`
  mutation updateUser(
    $id: ID!
    $city: String
    $zipcode: String
    $address: String
    $country: String
    $lastName: String
    $firstName: String
    $program: ProgramPointerInput
    $assessments: UserAssessmentRelationInput
  ) {
    updateUser(
      input: {
        id: $id
        fields: {
          city: $city
          zipcode: $zipcode
          address: $address
          country: $country
          program: $program
          lastName: $lastName
          firstName: $firstName
          assessments: $assessments
        }
      }
    ) {
      user {
        ${USER_SCHEMA}
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation logIn($fields: LogInInput!) {
    logIn(input: $fields) {
      viewer {
        sessionToken
        user {
          ${USER_SCHEMA}
        } 
      }
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation logOut {
    logOut(input: { clientMutationId: "logOut" }) {
      clientMutationId
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($email: String!) {
    resetPassword(input: { email: $email }) {
      ok
    }
  }
`;
