import { IRecipeFoodServingConversion } from 'components/ingredients/Ingredients';
import { IFood } from 'shared/src/types';

export type TValues = {
  fiber: number;
  protein: number;
  netCarbs: number;
  calories: number;
  totalFat: number;
  totalCarbs: number;
};

export type TValueKey = keyof TValues;

export const ELEMENTS = [
  'netCarbs',
  'protein',
  'totalFat',
  'fiber',
  'calories',
];

const DEFAULT_VALUES = {
  fiber: 0,
  protein: 0,
  netCarbs: 0,
  calories: 0,
  totalFat: 0,
  totalCarbs: 0,
};

const useServing = (
  ingredients: IRecipeFoodServingConversion[],
  servings: number
) => {
  const totals = ingredients.reduce<TValues>(
    (accumulator, currentValue) => {
      const food = currentValue.conversion ?? currentValue.food;
      const toRet = { ...DEFAULT_VALUES };

      if (!food) {
        return accumulator;
      }

      [...ELEMENTS].forEach((element) => {
        let value = +(food[element as keyof IFood] ?? 0) * servings;

        if (element === 'totalFat') {
          value = +(food.fat ?? food.totalFat ?? 0) * servings;
        }

        value =
          (+currentValue.servingSize * value) / +currentValue.baseServingSize;
        toRet[element as TValueKey] =
          (accumulator[element as TValueKey] || 0) + value;
      });

      return toRet;
    },
    { ...DEFAULT_VALUES }
  );

  return totals;
};

export default useServing;
