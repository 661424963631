import { useRef, useState } from 'react';

import omit from 'lodash/omit';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import TrackerTitle from './TrackerTitle';

import ProTip from 'components/ProTip';
import TabWrapper from 'components/TabWrapper';
import SearchInput from 'components/search/SearchInput';
import SearchFilters from 'components/search/SearchFilters';
import SearchResults from 'components/search/SearchResults';

import useModal, { ModalType } from 'hooks/useModal';
import useOutsideAlerter from 'hooks/useOutsideAlerter';

import { useFood, useLogFood } from 'shared/src/store';

import { CUSTOM_LIMITS } from 'constants/searchTabs';
import PUBLISH_FILTER from 'shared/src/constants/publishFilter';
import {
  SEARCH_FILTERS,
  TRACKER_TITLES,
  TABLE_THEAD_SEARCH,
} from 'constants/index';

interface ITrackerSearchFoods {
  setValue: React.Dispatch<React.SetStateAction<number>>;
  values: number;
}

const TrackerSearchFoods = ({ setValue, values }: ITrackerSearchFoods) => {
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState(1);

  const resultsRef = useRef<HTMLDivElement | null>(null);

  const { show: showModal } = useModal();
  const { setCachedFood } = useLogFood();
  const {
    get,
    data,
    loading,
    pageInfo,
    onEndReached,
    isFetchingMore,
  } = useFood();
  useOutsideAlerter(resultsRef, () => setShow(false));

  const foods = data.map(
    ({ brandName, verboseName, name, netCarbs, isAtkinsAcceptable }) => ({
      labelExtra: brandName,
      icon: isAtkinsAcceptable,
      label: verboseName || name,
      extra: `${(netCarbs ?? 0).toFixed(2)} g`,
    })
  );

  const handleSearch = (q: string) => {
    setShow(true);
    get({
      variables: {
        detailed: false,
        first: CUSTOM_LIMITS,
        where: {
          ...PUBLISH_FILTER,
          name: { text: { search: { term: q } } },
          ...(filter === 2 && {
            isAtkinsAcceptable: { equalTo: true },
          }),
        },
      },
    });
  };

  const handleChangeFilters = (filter: string) => {
    setFilter(+filter);
  };

  const handleClickItem = (index: number) => {
    setShow(false);
    setCachedFood({
      food: {
        ...omit(data[index], ['id', 'objectId']),
        foodId: data[index].objectId,
      },
    });
    showModal(ModalType.trackFood, {
      callback: () => setValue(values + 1),
    });
  };

  const handleAddNew = (index: number) => {
    window.dataLayer.push({
      event: 'form_start',
      form_name: 'food-form',
      engagement_type: 'tracker usage',
      engagement_name: 'food',
    });
    showModal(ModalType.newEntry, { isQuick: !index });
  };

  return (
    <TabWrapper styles="rounded-top-right rounded-bottom">
      <TrackerTitle values={TRACKER_TITLES.FOOD} />

      <div className="w-75">
        <ProTip text="Is better to search for the ingredients of a turkey sandwich than searching for “turkey sandwich swiss on wheat”." />
      </div>

      <div className="row align-items-center">
        <div className="col-sm">
          <SearchFilters
            values={SEARCH_FILTERS.FOODS}
            onChange={handleChangeFilters}
          />
        </div>
        <div className="col-auto">
          <DropdownButton title="Add New" className="mb-8" variant="dark">
            <Dropdown.Item onClick={() => handleAddNew(0)}>
              Quick Entry
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleAddNew(1)}>
              Custom Food
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>

      <SearchInput onSearch={handleSearch} type="regularFoods" />
      <SearchResults
        show={show}
        data={foods}
        loading={loading}
        onClickMore={onEndReached}
        headers={TABLE_THEAD_SEARCH}
        onClickItem={handleClickItem}
        isFetchingMore={isFetchingMore}
        showLoadMore={pageInfo.hasNextPage}
        onRef={(ref) => (resultsRef.current = ref)}
      />
    </TabWrapper>
  );
};

export default TrackerSearchFoods;
