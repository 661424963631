import MyMeasurementsLast from 'components/tracker/measurements/MyMeasurementsLast';
import MyMeasurementsList from 'components/tracker/measurements/MyMeasurementsList';
import MyWeightNewMeasurements from 'components/tracker/measurements/MyWeightNewMeasurements';

const TrackerWeight = () => {
  return (
    <>
      <div className="row">
        <div className="col-xl-8">
          <MyWeightNewMeasurements />
        </div>
        <div className="col-xl-4">
          <MyMeasurementsLast />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <MyMeasurementsList />
        </div>
      </div>
    </>
  );
};

export default TrackerWeight;
