import { gql } from '@apollo/client';

import { IObject } from '../types';

export const SERVING_CONVERSIONS_SCHEMA = `
  servingConversions {
    edges {
      node {
        id
        iron
        fiber
        isBase
        foodId
        sodium
        protein
        objectId
        totalFat
        calories
        netCarbs
        totalCarbs
        gramWeight
        servingSize
        servingUnit
        vitaminA
        vitaminC
        potassium
        cholesterol
        saturatedFat
        sugarAlcohols
        monounsaturatedFat
        polyunsaturatedFat
      }
    }
  }
`;

export const FOOD_SCHEMA = `
  id
  name
  fiber
  boost
  protein
  netCarbs
  objectId
  totalFat
  calories
  brandName
  servingSize
  servingUnit
  verboseName
  isAtkinsAcceptable
  iron @include(if: $detailed)
  sodium @include(if: $detailed)
  upcCode @include(if: $detailed)
  calcium @include(if: $detailed)
  vitaminA @include(if: $detailed)
  vitaminC @include(if: $detailed)
  glycerin @include(if: $detailed)
  potassium @include(if: $detailed)
  totalCarbs @include(if: $detailed)
  totalSugars @include(if: $detailed)
  cholesterol @include(if: $detailed)
  saturatedFat @include(if: $detailed)
  sugarAlcohols @include(if: $detailed)
  monounsaturatedFat @include(if: $detailed)
  polyunsaturatedFat @include(if: $detailed)
  phase @include(if: $detailed) {
    id
    name
  }
  category @include(if: $detailed) {
    id
    name
  }
  publishingStatus @include(if: $detailed) {
    id
    name
  }
  ${SERVING_CONVERSIONS_SCHEMA}
`;

const BASE_QUERY = `
  query getFoods(
    $skip: Int
    $first: Int
    $after: String
    $order: [FoodOrder!]
    $where: FoodWhereInput
    $detailed: Boolean = true
  ) {
    atlasFoods(
      skip: $skip
      first: $first
      after: $after
      order: $order
      where: $where
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      count
      edges {
        node {
          ${FOOD_SCHEMA}
        }
      }
    }
  }
`;

export const GET_ATLAS_FOODS_QUERY = gql`
  ${BASE_QUERY}
`;

export const GET_ATLAS_FOODS_COUNT_QUERY = gql`
  ${BASE_QUERY.replace('atlasFoods', 'atlasFoodsCount')}
`;

export const GET_FOODS_QUERY = gql`
  query getFoods(
    $skip: Int
    $first: Int
    $after: String
    $order: [FoodOrder!]
    $where: FoodWhereInput
    $detailed: Boolean = true
  ) {
    foods(
      skip: $skip
      first: $first
      after: $after
      order: $order
      where: $where
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      count
      edges {
        node {
          ${FOOD_SCHEMA}
        }
      }
    }
  }
`;

export const GET_FOOD_QUERY = gql`
  query getFoods(
    $id: ID!
    $detailed: Boolean = true
  ) {
    foods(
      where: {
      	id: { equalTo: $id }
    }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      count
      edges {
        node {
          ${FOOD_SCHEMA}
        }
      }
    }
  }
`;

export const CREATE_FOOD_MUTATION = gql`
  mutation createFood(
    $fields: CreateFoodFieldsInput!
    $detailed: Boolean = true
  ) {
    createFood(input: { fields: $fields }) {
      food {
        ${FOOD_SCHEMA}
      }
    }
  }
`;

export const UPDATE_FOOD_MUTATION = gql`
  mutation updateFood(
    $id: ID!
    $fields: UpdateFoodFieldsInput!
    $detailed: Boolean = true
  ) {
    updateFood(
      input: { id: $id, fields: $fields }
    ) {
      food {
        ${FOOD_SCHEMA}
      }
    }
  }
`;

const haveIn = [
  { s: 'phase', p: 'phases' },
  { s: 'category', p: 'foodCategories' },
];

export const customWhereGenerator = (vars: IObject) => {
  const { q } = vars;
  const toRet: IObject = {};

  if (q) {
    toRet.name = { text: { search: { term: q } } };
  }

  haveIn.forEach(({ s, p }) => {
    if (vars[p] && vars[p].length) {
      toRet[s] = { have: { id: { in: vars[p] } } };
    }
  });

  return { where: toRet };
};
