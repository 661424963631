interface TrackerTitleProps {
  values: { title: string; subtitle: string };
}

const TrackerTitle = ({ values: { title, subtitle } }: TrackerTitleProps) => (
  <div>
    <h2>{title}</h2>
    <h3 className="h4 font-weight-bold text-dark-50 mb-5">{subtitle}</h3>
  </div>
);

export default TrackerTitle;
