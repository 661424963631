export default [
  {
    name: 'date',
    label: 'Date',
  },
  {
    unit: 'in',
    name: 'neck',
    label: 'Neck',
  },
  {
    unit: 'in',
    name: 'leftArm',
    label: 'Left Arm',
  },
  {
    unit: 'in',
    name: 'rightArm',
    label: 'Right Arm',
  },
  {
    unit: 'in',
    name: 'chest',
    label: 'Chest',
  },
  {
    unit: 'in',
    name: 'waist',
    label: 'Waist',
  },
  {
    unit: 'in',
    name: 'hips',
    label: 'Hips',
  },
  {
    unit: 'in',
    name: 'leftThigh',
    label: 'Left Thigh',
  },
  {
    unit: 'in',
    name: 'rightThigh',
    label: 'Right Thigh',
  },
  {
    unit: 'in',
    name: 'leftCalf',
    label: 'Left Calf',
  },
  {
    unit: 'in',
    name: 'rightCalf',
    label: 'Right Calf',
  },
];
