const KGLB_RATIO = 0.45359237;

export const feetInchesToCm = (feet: number, inches: number) => {
  return feet * 30.48 + inches * 2.54;
};

export const cmToFeetInches = (cm: number) => {
  const length = cm / 2.54;
  const feet = Math.floor(length / 12);
  const inches = length - 12 * feet;
  return { feet, inches };
};

export const kgToLb = (kg: number) => {
  return kg / KGLB_RATIO;
};

export const lbToKg = (lb: number) => {
  return lb * KGLB_RATIO;
};
