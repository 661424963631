import { gql } from '@apollo/client';

export const PUBLISHING_STATUS_SCHEMA = `
  id
  name
  objectId
`;

export const PUBLISHING_STATUSES_SCHEMA = `
  publishingStatuses {
    edges {
      node {
        ${PUBLISHING_STATUS_SCHEMA}
      }
    }
  }
`;

export const GET_PUBLISHING_STATUSES_QUERY = gql`
  query getPublishingStatuses {
    ${PUBLISHING_STATUSES_SCHEMA}
  }
`;
