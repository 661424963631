import Modal from 'components/ui/Modal';

import { ModalType } from 'hooks/useModal';

import ArrowImg from 'assets/icons/arrow-long-down.svg';

const AtkinsNetCarbsContent = () => (
  <div className="d-flex flex-column justify-content-center align-items-center">
    <div>
      Total Carbohydrates — Fiber — Sugar Alcohols/Glycerin (if applicable)
    </div>
    <div className="my-8">
      <img src={ArrowImg} alt="Arrow Down Icon" />
    </div>
    <div className="h1 text-primary">Atkins Net Carbs</div>
  </div>
);

const AtkinsNetCarbs = () => (
  <Modal
    type={ModalType.atkinsNetCarbs}
    title="How Are Atkins Net Carbs Calculated?">
    {() => <AtkinsNetCarbsContent />}
  </Modal>
);

export default AtkinsNetCarbs;
