interface IInlineValueItemProps {
  title: string;
  value: string;
  subtitle: string;
  containerStyles?: string;
}

const InlineValueItem = ({
  value,
  title,
  subtitle,
  containerStyles = '',
}: IInlineValueItemProps) => (
  <div className={containerStyles}>
    <div className="d-flex align-items-sm-center mb-7">
      <div className="d-flex align-items-center flex-row-fluid flex-wrap">
        <div className="flex-grow-1 me-2">
          <h4 className="text-gray-800">{title}</h4>
          <div className="h5 font-weight-bold text-muted">{subtitle}</div>
        </div>
        <span className="badge badge-light min-w-80px h3 font-weight-boldest my-2">
          {value}
        </span>
      </div>
    </div>
  </div>
);

export default InlineValueItem;
