import { useState } from 'react';

import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import { useMutation } from '@apollo/client';

import Modal, { IModalChildrenProps } from 'components/ui/Modal';

import { ModalType } from 'hooks/useModal';
import { UPDATE_MUTATION } from 'shared/src/graphql/user';

import useAuth from 'shared/src/hooks/useAuth';
import usePrograms from 'shared/src/hooks/usePrograms';

import { TAny } from 'shared/src/types';

const ManagePlansContent = ({ hide }: IModalChildrenProps) => {
  const { user, setUser } = useAuth();
  const { programs, groupedPrograms } = usePrograms();
  const [mutateUpdateUser, { loading }] = useMutation(UPDATE_MUTATION);

  const [selected, setSelected] = useState(user.program.id);

  const handleOnChangeCheckbox = (id: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.stopPropagation();
    setSelected(id);
  };

  const handlePressSave = async () => {
    try {
      const selectedProgram = programs.filter(({ id }) => id === selected)[0];

      setUser({
        ...user,
        program: selectedProgram as TAny,
      });

      await mutateUpdateUser({
        variables: {
          id: user.id,
          program: { link: selected },
        },
      });

      toast.success('Plan Saved');
      hide();
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <>
      <div className="mx-4">
        {groupedPrograms.map(({ name, children }, index) => (
          <div key={`atkins-program-${index}`}>
            <span className="h1">{name}</span>
            {children.map((item, cindex) => (
              <ul
                key={`atkins-program-item-${item.id}`}
                className="list-unstyled mt-4">
                <li
                  className={`p-4 mx-4 rounded d-flex align-items-center ${
                    cindex % 2 ? '' : 'bg-gray-100'
                  }`}>
                  <input
                    type="checkbox"
                    className="mr-4"
                    checked={item.id === selected}
                    onChange={handleOnChangeCheckbox(item.id)}
                  />
                  <label>
                    <div className="h3">{item.phase?.name}</div>
                    <div>
                      {`${item.goalHigh - 2}g NC (Range of ${item.goalLow}-${
                        item.goalHigh
                      } g NC)`}
                    </div>
                  </label>
                </li>
              </ul>
            ))}

            <hr className="my-8" />
          </div>
        ))}
      </div>
      <Button
        type="submit"
        variant="primary"
        disabled={loading}
        onClick={handlePressSave}>
        Save your phase
      </Button>
      <Button variant="control" onClick={hide}>
        Go back to my profile
      </Button>
    </>
  );
};

const ManagePlans = () => (
  <Modal
    type={ModalType.managePlans}
    title="Atkins Plan"
    subtitle="Choose your plan"
    sublink={{
      prefix:
        'For a more detailed explanation about changing phases please click ',
      text: 'here',
      link: 'https://www.atkins.com/how-it-works/atkins-20',
    }}>
    {(props: IModalChildrenProps) => <ManagePlansContent {...props} />}
  </Modal>
);

export default ManagePlans;
