import React from 'react';

import startOfDay from 'date-fns/startOfDay';
import globalHook, { Store } from 'use-global-hook';

const today = startOfDay(new Date());

export type TDateState = {
  date: Date;
};

export type TDateAssociatedActions = {
  setDate: (value: Date) => void;
};

const setDate = (
  store: Store<TDateState, TDateAssociatedActions>,
  date: Date
) => {
  store.setState({ ...store.state, date });
};

const initialState: TDateState = {
  date: today,
};

const globalActions = {
  setDate,
};

const useGlobal = globalHook<TDateState, TDateAssociatedActions>(
  React,
  initialState,
  globalActions
);

const useDate = () => {
  const [state, actions] = useGlobal();

  const setDate = (newDate: Date) => {
    actions.setDate(startOfDay(newDate));
  };

  return { date: state.date, setDate };
};

export default useDate;
