interface ICardInnerProps {
  hide?: boolean;
  styles?: string;
  backgroundImage?: string;
  children: React.ReactNode;
}

const CardInner = ({
  hide,
  children,
  backgroundImage,
  styles = 'rounded-lg',
}: ICardInnerProps) => {
  if (hide) {
    return <>{children}</>;
  }

  return (
    <div
      className={`bg-light p-8 bg-image-card ${styles}`}
      style={{
        ...(backgroundImage && {
          backgroundImage: `url(${backgroundImage})`,
        }),
      }}>
      {children}
    </div>
  );
};

export default CardInner;
