import { gql } from '@apollo/client';

import { LOG_STEPS_SCHEMA } from './logStep';
import { LOG_WATERS_SCHEMA } from './logWater';
import { LOG_WEIGHTS_SCHEMA } from './logWeight';
import { USER_FOOD_TRACKEDS_SCHEMA } from './userFoodTracked';

export const GET_LOGS = gql`
  query getLogs($userId: String!, $date: Date!) {
    ${LOG_STEPS_SCHEMA}
    ${LOG_WATERS_SCHEMA}
    ${LOG_WEIGHTS_SCHEMA}
    ${USER_FOOD_TRACKEDS_SCHEMA}
  }
`;
