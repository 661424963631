import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { AutoForm, AutoFields } from 'uniforms-bootstrap4';

import Header from 'components/ui/Header';

import Submit from 'shared/src/forms/fields/web/Submit';

import useAuth from 'shared/src/hooks/useAuth';
import useForm from 'shared/src/hooks/useForm';

import { SForgotPassword } from 'shared/src/forms/schemas';

const ForgotPassword = () => {
  const {
    resetPassword,
    isLoadingResetPasswordMutation: isWorking,
  } = useAuth();
  const { schema } = useForm(SForgotPassword);

  const handleOnSubmit = async ({ email }: { email: string }) => {
    try {
      await resetPassword(email);
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  };

  return (
    <div className="col-md-6">
      <Header
        title="Forgot password"
        subtitle="Enter your email to reset your password."
      />
      <div className="text-left theme-form form--size-lg">
        <AutoForm showInlineError schema={schema} onSubmit={handleOnSubmit}>
          <AutoFields />
          <Submit working={isWorking} text="Send email" textWorking="Sending" />
          <Link
            to="/auth/login"
            className="d-block btn-link btn-lg text-capitalize mt-4 px-0">
            Go Back
          </Link>
        </AutoForm>
      </div>
    </div>
  );
};

export default ForgotPassword;
