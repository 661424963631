import { lazy, Suspense } from 'react';

import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';

import Tracker from './TrackerRouter';
import Profile from './ProfileRouter';
import CustomRecipe from './CustomRecipeRouter';

import Dashboard from 'views/app/Dashboard';

import LoadingIndicator from 'shared/src/components/web/LoadingIndicator';

const MainLayout = lazy(() => import('layouts/Main'));

import useApiConstants from 'shared/src/hooks/useApiConstants';

const AuthorizedRouter = () => {
  const { loading } = useApiConstants();

  if (loading) {
    return <LoadingIndicator visible animation={false} />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingIndicator visible animation={false} />}>
        <MainLayout>
          <Switch>
            <Route path="/tracker" component={Tracker} />
            <Route path="/profile" component={Profile} />
            <Route path="/dashboard" exact={true} component={Dashboard} />
            <Route path="/custom-recipes" component={CustomRecipe} />
            <Redirect to="/dashboard" />
          </Switch>
        </MainLayout>
      </Suspense>
    </BrowserRouter>
  );
};

export default AuthorizedRouter;
