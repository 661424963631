import Button from 'react-bootstrap/Button';

import ArrowImg from 'assets/icons/arrow-left.svg';
interface AssessmentFooterProps {
  onClickNext: () => void;
  onClickBack: () => void;
}
const AssessmentFooter = ({
  onClickNext,
  onClickBack,
}: AssessmentFooterProps) => (
  <div className="mt-16">
    <a onClick={onClickBack}>
      <img src={ArrowImg} alt="Arrow Left Icon" />
      <span className="ml-4">Go Back</span>
    </a>
    <Button onClick={onClickNext} className="ml-16 px-16">
      Continue
    </Button>
  </div>
);

export default AssessmentFooter;
